import { callApiToServer } from "../callApi";

export async function editUser(authtoken, userObject, userId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      userId: userId,
      nric_fin: userObject.nric_fin,
      partial_nric_fin: userObject.partial_nric_fin,
      principal_name: userObject.principal_name,
      sex: userObject.sex,
      race: userObject.race,
      dob: userObject.dob,
      nationality: userObject.nationality,
      mobile_number: userObject.mobile_number,
      email: userObject.email,
      address: userObject.address,
      type_of_hdb: userObject.type_of_hdb,
      type_of_housing: userObject.type_of_housing,
      maritial_status: userObject.maritial_status,
      child_births: userObject.child_births,
      household_size: userObject.household_size,
    });

    let endUrl = "v1/auth/edit-admin-user";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
