import { callApiToServer } from "../callApi";

export async function callLogin(email, password) {
  try {
    let body = JSON.stringify({
      email: email,
      password: password,
    });

    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let responseData = await callApiToServer(
      body,
      header,
      "POST",
      "v1/admin-login"
    );

    return responseData;
  } catch (error) {
    throw error;
  }
}

export async function callSubLogin(email, password) {
  try {
    let body = JSON.stringify({
      email: email,
      password: password,
    });

    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let responseData = await callApiToServer(
      body,
      header,
      "POST",
      "v1/admin-sub-user-login"
    );

    return responseData;
  } catch (error) {
    throw error;
  }
}
