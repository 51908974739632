import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllVendorLinkup } from "../../Graphs/VendorLinkup/listVendorLinkup";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteVendorLinkup } from "../../Graphs/VendorLinkup/deleteVendorLinkup";
import { editVendorLinkup } from "../../Graphs/VendorLinkup/editVendorLinkup";
import { searchVendorLinkup } from "../../Graphs/VendorLinkup/searchVendorLinkup";
import { EditModal } from "./EditModal";

var moment = require("moment");

class VendorLinkupRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedVendorLinkup = this.getLimitedVendorLinkup.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      linkupSelectedData: null,
    });
  }

  modalRef;

  async getLimitedVendorLinkup(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchVendorLinkupData(start, end);
        } else {
          //call normal user Data
          this.listVendorLinkupData(start, end);
        }
      }
    );
  }

  listSearchVendorLinkupData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVendorLinkupData,
        setListVendorLinkupDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let linkupData = await searchVendorLinkup(
        authToken,
        searchText,
        start,
        end
      );

      if (linkupData.status === 200) {
        //set linkup Data
        await setListVendorLinkupData(linkupData.data);
        await setListVendorLinkupDataTotal(linkupData.total);

        this.setState({
          isLoading: false,
          data: linkupData.data,
        });
      } else if (linkupData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search linkup Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listVendorLinkupData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVendorLinkupData,
        setListVendorLinkupDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let linkupData = await getAllVendorLinkup(authToken, start, end);

      if (linkupData.status === 200) {
        //set user Data
        await setListVendorLinkupData(linkupData.data);
        await setListVendorLinkupDataTotal(linkupData.total);

        this.setState({
          isLoading: false,
          data: linkupData.data,
        });
      } else if (linkupData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list linkup Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        linkupSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      linkupSelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 80,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Linkup Corppass Id",
      width: 150,
      key: "Linkup Corppass Id",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Linkup Corppass Id">
                <div>
                  {item.linkup_corppass_id ? item.linkup_corppass_id : "-"}
                </div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Corppass Id",
      width: 150,
      key: "Corppass Id",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Corppass Id">
                <div>{item.corppass_id ? item.corppass_id : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left" }}>
              <Tooltip placement="bottom" title="Edit">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{ float: "left", marginLeft: "10px" }}
            >
              <Tooltip placement="bottom" title="Delete">
                <Popconfirm
                  onConfirm={this.deleteVendorLinkup.bind(this, item)}
                  title="Are you sure you want to delete this linkup?"
                >
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  async deleteVendorLinkup(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteVendorLinkup(authToken, item.id);

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete Linkup",
          description: "Successfully deleted",
        });

        if (searchText != null && searchText !== "") {
          this.listSearchVendorLinkupData(startIndex, limitNumber);
          return;
        }
        this.listVendorLinkupData(startIndex, limitNumber);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Linkup",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (linkupObject, linkupId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editVendorLinkup(
        authToken,
        linkupObject,
        linkupId
      );
      if (statusData.status === 200) {
        notification["success"]({
          message: "Edit Linkup Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchVendorLinkupData(startIndex, limitNumber);
          return;
        }
        this.listVendorLinkupData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Edit Linkup",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedVendorLinkup(pagination)
    );
  }

  render() {
    const { isLoading, data } = this.state;
    const { listVendorLinkupData, listVendorLinkupDataTotal, searchLoader } =
      this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listVendorLinkupData}
          pagination={{
            total: listVendorLinkupDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.linkupSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            linkupSelectedData={this.state.linkupSelectedData}
            onSubmit={this.editSubmit}
            loginUserData={this.props.loginUserData}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listVendorLinkupData: state.vendor_linkup.listVendorLinkupData,
  listVendorLinkupDataTotal: state.vendor_linkup.listVendorLinkupDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVendorLinkupData: (listVendorLinkupData) => {
      dispatch({
        type: "SET_LIST_VENDOR_LINKUP_DATA",
        listVendorLinkupData: listVendorLinkupData,
      });
    },
    setListVendorLinkupDataTotal: (listVendorLinkupDataTotal) => {
      dispatch({
        type: "SET_LIST_VENDOR_LINKUP_DATA_TOTAL",
        listVendorLinkupDataTotal: listVendorLinkupDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VendorLinkupRecordsTable));
