import * as React from "react";
import { Modal, Spin, Divider, Button, notification } from "antd";

export class VoucherDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  redeemCodeSubmit = () => {
    try {
      const { redeemCode } = this.props;
      this.setState({
        isLoading: true,
      });
      //call api
    } catch (e) {
      notification["error"]({
        message: "Home",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
      this.handleCancel();
    }
  };

  render() {
    const { redeemCode } = this.props;

    return (
      // <StyleBox>
      <Modal
        width="600px"
        closable={this.state.closable}
        visible={this.state.visible}
        title={`Code Detail ( ${redeemCode} )`}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ justifyContent: "center", margin: "0 auto" }}>
              <Spin />
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                marginBottom: "10px",
                display: "inline-block",
                width: "100%",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  width: "100%",
                  float: "left",
                }}
              >
                Voucher Name
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "14px",
                  width: "100%",
                  float: "left",
                }}
              >
                Health voucher
              </span>
            </div>

            <div
              style={{
                marginBottom: "10px",
                display: "inline-block",
                width: "100%",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  width: "100%",
                  float: "left",
                }}
              >
                Expiry Date
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "14px",
                  width: "100%",
                  float: "left",
                }}
              >
                20th July, 2021
              </span>
            </div>

            <div
              style={{
                marginBottom: "10px",
                display: "inline-block",
                width: "100%",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  width: "100%",
                  float: "left",
                }}
              >
                Terms
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "12px",
                  width: "100%",
                  float: "left",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </span>
            </div>

            <Divider />

            <div style={{ marginTop: "10px" }}>
              <Button
                type="primary"
                style={{
                  background: "#5C6AC4",
                  color: "#fff",
                }}
                onClick={() => {
                  this.redeemCodeSubmit();
                }}
              >
                Redeem Now
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
