import { Modal, Button, Divider } from "antd";
import * as React from "react";
import {
  Layout,
  Input,
  TimePicker,
  Select,
  notification,
  DatePicker,
} from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { getAllTypeActiveCategory } from "../../Graphs/Category/listAllTypeActiveCategory";
import { AddProgramIcon } from "./AddProgramIcon";
import { PictureOutlined } from "@ant-design/icons";
import { WEBURL, AWS_URL } from "../../constant/comman";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
const dateFormat = "YYYY-MM-DD";

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      changeFile: true,
      visible: false,
      startDate: "",
      endDate: "",
      selectedCategory: [],
      selectedCharityCategory: [],
      errors: {
        uploadImage: "",
        selectedCharityCategory: "",
        selectedCategory: "",
      },
    };
  }

  input;
  allCategories = [];
  allCharityCategories = [];
  src = "";

  uploadImage = (name) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
        changeFile: true,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  showViewModal = (params) => {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  };

  async componentDidMount() {
    try {
      let { categorie_ids, cause_categorie_ids } = this.props.data;
      let { authToken } = this.props;
      let categoryData = await getAllTypeActiveCategory(authToken, "vendor");
      let charityCategoryData = await getAllTypeActiveCategory(
        authToken,
        "charity"
      );

      console.log(
        "!!!!!!categoryData and charityCategoryData printed here!!!!!",
        categoryData,
        charityCategoryData
      );

      this.src = this.props.data.icon_name;

      categoryData &&
        categoryData.data &&
        categoryData.data.map((data, index) => {
          this.allCategories.push(<Option value={data.id}>{data.name}</Option>);
        });

      charityCategoryData &&
        charityCategoryData.data &&
        charityCategoryData.data.map((data, index) => {
          this.allCharityCategories.push(
            <Option value={data.id}>{data.name}</Option>
          );
        });

      let selectedDummyType = [];
      categorie_ids &&
        categorie_ids.split(", ").map((data, index) => {
          selectedDummyType.push(data);
        });

      let selectedCauseDummyType = [];
      cause_categorie_ids &&
        cause_categorie_ids.split(", ").map((data, index) => {
          selectedCauseDummyType.push(data);
        });

      this.setState({
        selectedCategory: selectedDummyType,
        selectedCharityCategory: selectedCauseDummyType,
        categoryData: categoryData.data,
        charityCategoryData: charityCategoryData.data,
        startDate:
          this.props.data.start_date == null ? "" : this.props.data.start_date,
        endDate:
          this.props.data.end_date == null ? "" : this.props.data.end_date,
      });
    } catch (e) {
      notification["error"]({
        message: "Error",
        description:
          typeof e === "string"
            ? e
            : typeof e.message
            ? e.message
            : "Please try again later",
      });
    }
  }

  validateForm = (values) => {
    const { startDate, endDate } = this.state;

    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.title) {
      errors.title = "Title is required";
    }

    if (!values.short_description) {
      errors.short_description = "Short Description is required";
    }

    if (!values.long_description) {
      errors.long_description = "Long Description is required";
    }

    if (!startDate) {
      errors.start_date = "startDate is required";
    }

    if (!endDate) {
      errors.end_date = "endDate is required";
    }

    if (!this.src) {
      errors.uploadImage = "Icon is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit(values, action) {
    const {
      selectedCategory,
      selectedCharityCategory,
      errors,
      categoryData,
      startDate,
      charityCategoryData,
      endDate,
    } = this.state;
    const dummyErrors = { ...errors };

    if (!selectedCategory.length) {
      dummyErrors.selectedCategory = "Please select atleast one category";
      this.setState({
        errors: dummyErrors,
      });
      return;
    }

    if (!selectedCharityCategory.length) {
      dummyErrors.selectedCharityCategory =
        "Please select atleast one cause category";
      this.setState({
        errors: dummyErrors,
      });
      return;
    }

    /**
     * For category
     */
    let sendCategory = [];
    selectedCategory.map((data, index) => {
      let filterData = categoryData.filter((subData, subIndex) => {
        return data == subData.id;
      });
      if (filterData && filterData.length > 0) {
        sendCategory.push({
          id: data,
          name: filterData[0]["name"],
        });
      }
    });

    /**
     * For cause category
     */
    let sendCauseCategory = [];
    selectedCharityCategory.map((data, index) => {
      let filterData = charityCategoryData.filter((subData, subIndex) => {
        return data == subData.id;
      });
      if (filterData && filterData.length > 0) {
        sendCauseCategory.push({
          id: data,
          name: filterData[0]["name"],
        });
      }
    });

    dummyErrors.selectedCharityCategory = "";
    dummyErrors.selectedCategory = "";
    values.cause_categories = sendCauseCategory;
    values.categories = sendCategory;
    values.start_date = startDate;
    values.end_date = endDate;
    values.icon_name = this.src;

    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  }

  changeCategory = (value) => {
    this.setState({
      selectedCategory: value,
    });
  };

  changeCauseCategory = (value) => {
    this.setState({
      selectedCharityCategory: value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  onstartDateChange = (date, dateString) => {
    this.setState({
      startDate: dateString,
    });
  };

  onEndDateChange = (date, dateString) => {
    this.setState({
      endDate: dateString,
    });
  };

  render() {
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                title: this.props.data.title,
                categories:
                  this.props.data.categories &&
                  this.props.data.categories.split(", "),
                cause_categories:
                  this.props.data.cause_categories &&
                  this.props.data.cause_categories.split(", "),
                short_description: this.props.data.short_description,
                long_description: this.props.data.long_description,
                start_date: this.props.data.start_date,
                end_date: this.props.data.end_date,
                venue: this.props.data.venue,
                contact_number: this.props.data.contact_number,
                contact_email: this.props.data.contact_email,
                contact_person_name: this.props.data.contact_person_name,
                icon_name: this.props.data.icon_name,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Title
                        <Input
                          id="title"
                          placeholder="Title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.title && touched.title ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.title}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Categories
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          value={this.state.selectedCategory}
                          onChange={(value) => this.changeCategory(value)}
                          placeholder="Select Categories"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allCategories}
                        </Select>
                        {this.state.errors.selectedCategory ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedCategory}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Short Description
                        <Input
                          id="short_description"
                          placeholder="Short Description"
                          value={values.short_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.short_description &&
                        touched.short_description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.short_description}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Long Description
                        <Input
                          id="long_description"
                          placeholder="Long Description "
                          value={values.long_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.long_description && touched.long_description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.long_description}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Venue
                        <TextArea
                          id="venue"
                          placeholder="Venue"
                          value={values.venue}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          rows={3}
                        />
                        {errors.venue && touched.venue ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.venue}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Contact Number
                        <Input
                          id="contact_number"
                          placeholder="Contact Number"
                          value={values.contact_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.contact_number && touched.contact_number ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.contact_number}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Contact Email
                        <Input
                          id="contact_email"
                          placeholder="Contact Email"
                          value={values.contact_email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          rows={3}
                        />
                        {errors.contact_email && touched.contact_email ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.contact_email}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Contact Person Name
                        <Input
                          id="contact_person_name"
                          placeholder="Contact Person Name"
                          value={values.contact_person_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.contact_person_name &&
                        touched.contact_person_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.contact_person_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        <span style={{ width: "100%", float: "left" }}>
                          Start Date
                        </span>
                        <DatePicker
                          format={dateFormat}
                          defaultValue={
                            this.state.startDate
                              ? moment(this.state.startDate, dateFormat)
                              : moment(new Date(), dateFormat)
                          }
                          onChange={(date, dateString) => {
                            this.onstartDateChange(date, dateString);
                            setFieldValue("start_date", dateString);
                          }}
                          disabledDate={(current) => {
                            return (
                              current && current < moment().subtract(1, "day")
                            );
                          }}
                        />
                        {errors.start_date ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.start_date}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        <span style={{ width: "100%", float: "left" }}>
                          End Date
                        </span>
                        <DatePicker
                          format={dateFormat}
                          defaultValue={
                            this.state.endDate
                              ? moment(this.state.endDate, dateFormat)
                              : moment(new Date(), dateFormat)
                          }
                          onChange={(date, dateString) => {
                            this.onEndDateChange(date, dateString);
                            setFieldValue("end_date", dateString);
                          }}
                          disabledDate={(current) => {
                            return (
                              current && current < moment().subtract(1, "day")
                            );
                          }}
                        />
                        {errors.end_date ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.end_date}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Cause Categories
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          value={this.state.selectedCharityCategory}
                          onChange={(value) => this.changeCauseCategory(value)}
                          placeholder="Select Cause Categories"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allCharityCategories}
                        </Select>
                        {this.state.errors.selectedCharityCategory ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedCharityCategory}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ float: "left", width: "10%" }}>
                        <PictureOutlined
                          style={{ width: "100%", fontSize: "30px" }}
                        />
                      </div>
                      <Button
                        style={{ width: "90%" }}
                        onClick={this.showViewModal.bind(this)} //this.showViewModal.bind(this)
                      >
                        Choose Program Icon ( Upload svg format only )
                      </Button>
                      {this.src ? (
                        <p
                          style={{
                            fontSize: "small",
                            margin: "0",
                            marginLeft: "5px",
                          }}
                        >
                          {this.src}
                        </p>
                      ) : null}
                      {this.state.view ? (
                        <AddProgramIcon
                          refx={(e) => (this.viewModalRef = e)}
                          onUpload={this.uploadImage}
                        />
                      ) : null}
                    </div>
                    <div width="100%" style={{ marginTop: "10px" }}>
                      {errors.uploadImage != "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.uploadImage}
                        </p>
                      ) : null}
                    </div>
                    <div
                      style={{
                        marhinTop: "20px",
                        width: "100px",
                        backgroundColor:
                          this.selectedColorType !== ""
                            ? this.selectedColorType
                            : "#ffffff",
                        borderRadius: "10px",
                      }}
                    >
                      {this.src && this.src !== "" ? (
                        <img
                          src={
                            this.state.changeFile
                              ? `${WEBURL}program/icon/${this.src}`
                              : `${AWS_URL}program/icon/${this.src}`
                          }
                          alt="ProgramIcon"
                          style={{ width: "100px" }}
                        />
                      ) : null}
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
