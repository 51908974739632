import * as React from "react";
import { Modal, Spin, Button } from "antd";
import { UploadForm } from "./UploadForm";
import { WEBURL } from "../../constant/comman";

export class UploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  downloadSample = () => {
    var link = document.createElement("a");
    // link.setAttribute("href", `${WEBURL}voucher/excel/example.xlsx`);
    link.setAttribute("href", `${WEBURL}/voucher/excel/example.xlsx`);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", `Example.xlsx`);
    document.body.appendChild(link);

    link.click();
  };

  render() {
    return (
      // <StyleBox>
      <Modal
        width="600px"
        closable={this.state.closable}
        visible={this.state.visible}
        title="Bulk Upload Voucher Codes"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          <div>
            <div style={{ marginBottom: "15px" }}>
              <Button
                style={{
                  marginTop: "19px",
                  background: "#5C6AC4",
                  color: "#fff",
                }}
                onClick={() => {
                  this.downloadSample();
                }}
              >
                Download Sample Excel
              </Button>
            </div>
            <UploadForm
              data={this.props.cardUploadSelectedData}
              authToken={this.props.authToken}
              onSubmit={this.props.onSubmit}
              handleCancel={this.handleCancel}
            />
          </div>
        )}
      </Modal>
    );
  }
}
