/* jshint-disable */

import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;

export class AddLinkup extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      view: false,
    };
  }

  onDescriptionChange = (value) => {
    this.setState({ description: value });
  };

  validateForm = (values) => {
    const { description } = this.state;

    const errors = {};

    if (!values.singpass_id) {
      errors.singpass_id = "Singpass Id is required";
    }

    if (!values.corppass_id) {
      errors.corppass_id = "Corppass Id is required";
    }

    return errors;
  };

  handleSubmit = (values, action) => {
    console.log("!!!!!values", values);
    this.props.onSubmit(values);
    this.handleCancel();
    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { primaryColor } = this.props;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          destroyOnClose={true}
          title="Link Singpass and Croppass"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          width={"800px"}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                singpass_id: "",
                corppass_id: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Singpass Id
                        <Input
                          id="singpass_id"
                          placeholder="Singpass Id"
                          value={values.singpass_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.singpass_id && touched.singpass_id ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.singpass_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Corppass Id
                        <Input
                          id="corppass_id"
                          placeholder="Corppass Id"
                          value={values.corppass_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.corppass_id && touched.corppass_id ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.corppass_id}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
