import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, Spin } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { getAllCharityProgramDropDownForSocialWorker } from "../../Graphs/CharityProgram/listCharityProgramDropDown";
import * as la from "lodash";

const { Content } = Layout;
const Option = Select.Option;

export class DeleteWorkerForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      changeFile: true,
      view: false,
      selectedProgram: "",
      errors: {
        selectedProgram: "",
      },
      programListData: []
    };
  }

  allPrograms = [];

  async componentDidMount() {
    const { authToken } = this.props;

    this.setState({
      isLoading: true,
    });

    let programData = await getAllCharityProgramDropDownForSocialWorker(
      authToken,
      this.props.data.id
    );
    programData &&
      programData.data &&
      programData.data.length > 0 &&
      la.map(programData.data, (subData, subIndex) => {
        this.allPrograms.push(
          <Option key={subData.id} value={subData.id}>
            {subData.title}
          </Option>
        );
      });

    this.setState({
      isLoading: false,
      programListData: programData.data
    });
  }

  validateForm = (values) => {
    const { selectedProgram, programListData } = this.state;
    const errors = {};
    console.log("Validating form -- ", values);

    if (programListData.length > 0 && !selectedProgram) {
      errors.selectedProgram = "Please select program";
    }

    return errors;
  };

  handleSubmit(values, action) {
    const { selectedProgram } = this.state;
    values.program_id = selectedProgram;
    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  }

  changeProgram = (value) => {
    this.setState({
      selectedProgram: value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                program_id: undefined,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    {isLoading ? (
                      <div>
                        <div
                          style={{
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <Spin />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Type
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              value={this.state.selectedProgram}
                              onChange={(value) => {
                                this.changeProgram(value);
                                setFieldValue("program_id", value);
                              }}
                              placeholder="Select Program"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this.allPrograms}
                            </Select>
                            {errors.selectedProgram ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.selectedProgram}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <Button
                          type="primary"
                          style={{
                            marginTop: "19px",
                            background: "#5C6AC4",
                            color: "#fff",
                          }}
                          onClick={handleSubmit}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
