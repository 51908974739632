import * as React from "react";
import { Modal, Spin, Input, Button, notification, Divider } from "antd";
import { deleteRedeemVoucher } from "../../Graphs/Voucher/deleteRedeemVoucher";

export class DeleteRedeemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      searchText: "",
      errorReasons: [],
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    const { refreshData } = this.props;
    refreshData();
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  onChangeText = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  deleteRedeemCode = async () => {
    try {
      const { searchText } = this.state;
      const { authToken, deleteRedeemCodeData } = this.props;

      if (!searchText) {
        notification["error"]({
          message: "Delete Redeem",
          description: "Please enter Redeem Code",
        });
        return;
      }

      this.setState({
        isLoading: true,
      });

      const statusData = await deleteRedeemVoucher(
        authToken,
        searchText,
        deleteRedeemCodeData.id
      );
      if (statusData.status == 200) {
        if (statusData.errorReasons.length == searchText.split(",").length) {
          notification["error"]({
            message: "Cannot Delete Voucher Code",
            description: "Incorrect Voucher Code",
          });
        } else {
          notification["success"]({
            message: "Delete Redeem",
            description: "Successfully deleted",
          });
        }

        if (!statusData.errorReasons && statusData.errorReasons.length)
          this.handleCancel();

        this.setState({
          errorReasons: statusData.errorReasons,
          searchText: "",
        });
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Redeem",
        description:
          typeof e === "string" ? e : typeof e.message ? e.message : "",
      });
      return;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { searchText } = this.state;

    return (
      <Modal
        width="600px"
        closable={this.state.closable}
        visible={this.state.visible}
        title="Bulk Delete Redeem Code"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          <div>
            <label style={{ marginBottom: "3px" }}>
              Voucher Codes (Comma Separated)
            </label>
            <Input
              id="redeem"
              placeholder="Redeem Code"
              value={searchText}
              onChange={(e) => this.onChangeText(e)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.deleteRedeemCode();
                }
              }}
            />

            {this.state.errorReasons && this.state.errorReasons.length > 0 && (
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginBottom: "5px",
                  }}
                >
                  Failed to delete Voucher Codes
                </div>
                {this.state.errorReasons.map((errorReason, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "inline-block",
                        marginBottom: "3px",
                        paddingBottom: "3px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                          fontWeight: "bold",
                        }}
                      >
                        {errorReason.redeemCode}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                        }}
                      >
                        {errorReason.reason}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div style={{ marginTop: "15px" }}>
              <Button type="primary" onClick={() => this.deleteRedeemCode()}>
                Delete
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
