import { callGetApiToServer } from "../callApi";

export async function filterVoucherBydate(
  authtoken,
  skipNumber,
  limitNumber,
  startDate,
  endDate
) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl =
      "v1/auth/filter-voucher/" +
      startDate +
      "/" +
      endDate +
      "/" +
      skipNumber +
      "/" +
      limitNumber;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
