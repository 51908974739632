import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllVendor } from "../../Graphs/Vendor/listVendor";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { deleteVendor } from "../../Graphs/Vendor/deleteVendor";
import { changeStatus } from "../../Graphs/Vendor/changeStatus";
import { editVendor } from "../../Graphs/Vendor/editVendor";
import { searchVendor } from "../../Graphs/Vendor/searchVendor";
import { EditModal } from "./EditModal";
import { PaidModal } from "./PaidModal";
import { paidAmount } from "../../Graphs/Vendor/paidAmount";
import { TransactionModal } from "./TransactionModal";
import { DollarSign, List } from "react-feather";

var moment = require("moment");

class VendorRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedVendor = this.getLimitedVendor.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      vendorSelectedData: null,
    });
  }

  async getLimitedVendor(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchVendorData(start, end);
        } else {
          //call normal user Data
          this.listVendorData(start, end);
        }
      }
    );
  }

  listSearchVendorData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVendorData,
        setListVendorDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let vendorData = await searchVendor(authToken, searchText, start, end);

      if (vendorData.status === 200) {
        //set user Data
        await setListVendorData(vendorData.data);
        await setListVendorDataTotal(vendorData.total);

        this.setState({
          isLoading: false,
          data: vendorData.data,
        });
      } else if (vendorData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search vendor Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listVendorData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVendorData,
        setListVendorDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let vendorData = await getAllVendor(authToken, start, end);

      if (vendorData.status === 200) {
        //set user Data
        await setListVendorData(vendorData.data);
        await setListVendorDataTotal(vendorData.total);

        this.setState({
          isLoading: false,
          data: vendorData.data,
        });
      } else if (vendorData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list vendor Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  modalRef;
  paidModalRef;
  transactionModalRef;

  showModal(item) {
    this.setState(
      {
        vendorSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      vendorSelectedData: null,
    });
  }

  showPaidAmountModal(item) {
    this.setState(
      {
        vendorPaidData: item,
        // isOpen: true
      },
      () => {
        this.paidModalRef.show();
      }
    );
  }

  handleDetailsPaidAmountModalclose() {
    this.setState({
      vendorPaidData: null,
    });
  }

  showTransactionModal(item) {
    this.setState(
      {
        vendorTransData: item,
        // isOpen: true
      },
      () => {
        this.transactionModalRef.show();
      }
    );
  }

  handleDetailsTransactionModalclose() {
    this.setState({
      vendorPaidData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Vendor Name",
      key: "VendorName",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Vendor Name">
                <div>
                  {item.user_metadata ? item.user_metadata.entity_name : "-"}
                </div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "CorpPass ID",
      key: "CorpPass ID",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="CorpPass ID">
              <div>{item.corppass_id ? item.corppass_id : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Categories",
      key: "Categories",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Categories">
              <div>{item.categories ? item.categories : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Outstanding Amount",
      key: "Outstanding Amount",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Outstanding Amount">
              <div>
                {item.outstanding_balance
                  ? `$ ${String(item.outstanding_balance)}`
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "isActive",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="Vendor Activation">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Edit">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{ float: "left", marginRight: "5px" }}
            >
              <Tooltip placement="bottom" title="Delete">
                <Popconfirm
                  onConfirm={this.deleteVendor.bind(this, item)}
                  title="Are you sure you want to delete vendor?"
                >
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Pay Amount">
                <Button
                  shape="circle"
                  icon={<DollarSign />}
                  className="feather-svg"
                  onClick={() => {
                    this.showPaidAmountModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              mr="5px"
              style={{ float: "left", marginRight: "5px", marginTop: "5px" }}
            >
              <Tooltip placement="bottom" title="View Paid Transactions">
                <Button
                  shape="circle"
                  className="feather-svg"
                  icon={<List />}
                  onClick={() => {
                    this.showTransactionModal(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  async deleteVendor(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteVendor(authToken, item.id);

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete Vendor",
          description: "Successfully deleted",
        });

        if (searchText != null && searchText !== "") {
          this.listSearchVendorData(startIndex, limitNumber);
          return;
        }
        this.listVendorData(startIndex, limitNumber);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Vendor",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Change Vendor Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchVendorData(startIndex, limitNumber);
          return;
        }
        this.listVendorData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Vendor Status",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (vendorObject, vendorId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editVendor(authToken, vendorObject, vendorId);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Edit Vendor Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchVendorData(startIndex, limitNumber);
          return;
        }
        this.listVendorData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Edit Vendor",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  paidSubmit = async (vendorObject, vendorId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });

      let statusData = await paidAmount(authToken, vendorObject, vendorId);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Pay Amount",
          description: "Successfully paid",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchVendorData(startIndex, limitNumber);
          return;
        }
        this.listVendorData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Pay Amount",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedVendor(pagination)
    );
  }

  render() {
    const { isLoading, data } = this.state;
    const { listVendorData, listVendorDataTotal, searchLoader } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listVendorData}
          pagination={{
            total: listVendorDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.vendorSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            vendorSelectedData={this.state.vendorSelectedData}
            onSubmit={this.editSubmit}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.vendorPaidData ? (
          <PaidModal
            refx={(e) => (this.paidModalRef = e)}
            onClose={this.handleDetailsPaidAmountModalclose.bind(this)}
            vendorPaidData={this.state.vendorPaidData}
            authToken={this.props.authToken}
            onSubmit={this.paidSubmit}
          />
        ) : null}
        {this.state.vendorTransData ? (
          <TransactionModal
            refx={(e) => (this.transactionModalRef = e)}
            onClose={this.handleDetailsTransactionModalclose.bind(this)}
            vendorTransData={this.state.vendorTransData}
            authToken={this.props.authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listVendorData: state.vendor.listVendorData,
  listVendorDataTotal: state.vendor.listVendorDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVendorData: (listVendorData) => {
      dispatch({
        type: "SET_LIST_VENDOR_DATA",
        listVendorData: listVendorData,
      });
    },
    setListVendorDataTotal: (listVendorDataTotal) => {
      dispatch({
        type: "SET_LIST_VENDOR_DATA_TOTAL",
        listVendorDataTotal: listVendorDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VendorRecordsTable));
