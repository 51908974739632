import {
  SET_LIST_CHARITY_PROGRAM_DATA,
  SET_LIST_CHARITY_PROGRAM_DATA_TOTAL,
} from "../constant/actionTypes";

const initial_state = {
  listCharityProgramData: null,
  listCharityProgramDataTotal: 0,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_LIST_CHARITY_PROGRAM_DATA:
      return {
        ...state,
        loading: false,
        listCharityProgramData: action.listCharityProgramData,
      };

    case SET_LIST_CHARITY_PROGRAM_DATA_TOTAL:
      return {
        ...state,
        loading: false,
        listCharityProgramDataTotal: action.listCharityProgramDataTotal,
      };

    default:
      return { ...state };
  }
};
