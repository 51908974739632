import { callApiToServer } from "../callApi";

export async function changeProfileImage(authtoken, profileImage) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      profile_image: profileImage,
    });

    let endUrl = "v1/auth/change-profile-image";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
