import { callApiToServer } from "../callApi";

export async function uploadRedeem(authtoken, voucherObject, voucherId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      voucherId: voucherId,
      fileName: voucherObject.file,
    });

    let endUrl = "v1/auth/add-voucher-inventory-excel";

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}

export async function uploadSingleRedeem(authtoken, redeemObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify(redeemObject);

    let endUrl = "v1/auth/add-redeem-code";

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
