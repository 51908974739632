import React, { Fragment, Component } from "react";
import Title from "./Title";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { listVoucherInventory } from "../../Graphs/Voucher/listVoucherInventory";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import FilterVoucherRecordsTable from "./FilterVoucherRecordsTable";

class FilterVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherInventoryList: [],
      voucherInventoryTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      type: "total_evoucher",
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText, history } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("VOUCHER_INVENTORY");
      await setSearchText("");

      this.setState(
        {
          isLoading: true,
          primaryColor: editPrimaryColor,
          type:
            history.location.pathname === "/total_evoucher"
              ? "total_evoucher"
              : history.location.pathname === "/expiry_evoucher"
              ? "expiry_evoucher"
              : history.location.pathname === "/unassigned_evoucher"
              ? "unassigned_evoucher"
              : history.location.pathname === "/active_evoucher"
              ? "assigned_evoucher"
              : history.location.pathname === "/redeemed_evoucher"
              ? "used_evoucher"
              : "total_evoucher",
        },
        async () => {
          await this.listVoucherInventoryData();
        }
      );
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listVoucherInventoryData = async () => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVoucherInventoryData,
        setListVoucherInventoryDataTotal,
        setRouteName,
        setSearchText,
        loginUserData,
      } = this.props;

      this.setState({
        isLoading: true,
      });

      let voucherInventoryData = await listVoucherInventory(
        authToken,
        this.state.type,
        0,
        10
      );

      if (voucherInventoryData.status == 200) {
        //set user data
        await setListVoucherInventoryData(voucherInventoryData.data);
        await setListVoucherInventoryDataTotal(voucherInventoryData.total);

        this.setState({
          voucherInventoryList: voucherInventoryData.data,
          voucherInventoryTotal: voucherInventoryData.total,
        });
      } else if (voucherInventoryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherInventoryData && voucherInventoryData.message
          ? voucherInventoryData.message
          : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const {
      primaryColor,
      voucherInventoryList,
      isLoading,
      voucherInventoryTotal,
    } = this.state;
    const { history } = this.props;

    return (
      <Fragment>
        <Title
          parent="Voucher Inventory"
          title={
            history.location.pathname === "/total_evoucher"
              ? "Uploaded e-Voucher List"
              : history.location.pathname === "/unassigned_evoucher"
              ? "Unassigned e-Voucher List"
              : history.location.pathname === "/active_evoucher"
              ? "Active e-Voucher List"
              : history.location.pathname === "/redeemed_evoucher"
              ? "Redeemed e-Voucher List"
              : history.location.pathname === "/expiry_evoucher"
              ? "Expired e-Voucher List"
              : ""
          }
        />

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <FilterVoucherRecordsTable
                data={voucherInventoryList}
                primaryColor={primaryColor}
                voucherInventoryTotal={voucherInventoryTotal}
                type={this.state.type}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVoucherInventoryData: (listVoucherInventoryData) => {
      dispatch({
        type: "SET_LIST_VOUCHER_INVENTORY_DATA",
        listVoucherInventoryData: listVoucherInventoryData,
      });
    },
    setListVoucherInventoryDataTotal: (listVoucherInventoryDataTotal) => {
      dispatch({
        type: "SET_LIST_VOUCHER_INVENTORY_DATA_TOTAL",
        listVoucherInventoryDataTotal: listVoucherInventoryDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterVoucher));
