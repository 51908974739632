import * as React from "react";
import { Upload, message, Button, Modal } from "antd";
import styled from "styled-components";
import { WEBURL } from "../../constant/comman";
import { InboxOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";

export class ColorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: "#ffffff",
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
    this.setState({
      background: this.props.selectedColor,
    });
  }

  handleCancel = (e) => {
    console.log("!!!!!handleCancel printed ");
    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.changeColor(null);
      }
    );
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.changeColor(null);
      }
    );
  }

  handleChangeComplete = (color) => {
    this.setState({
      background: color.hex,
    });
  };

  changeColor = () => {
    const { background } = this.state;
    this.props.changeColor(background);
  };

  render() {
    return (
      <Modal
        width="300px"
        closable={this.state.closable}
        visible={this.state.visible}
        // onOk={this.handleOk}
        onCancel={this.handleCancel.bind(this)}
        footer={null}
      >
        <div style={{ marginTop: "20px" }}>
          <SketchPicker
            color={this.state.background}
            onChangeComplete={this.handleChangeComplete}
          />

          <Button
            style={{ marginTop: "50px" }}
            disabled={this.state.disable}
            onClick={this.changeColor}
          >
            Add
          </Button>
        </div>
      </Modal>
    );
  }
}
