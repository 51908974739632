import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllCharityProgram } from "../../Graphs/CharityProgram/listCharityProgram";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import { addCharityProgram } from "../../Graphs/CharityProgram/addCharityProgram";
import { AddCharityProgram } from "./AddCharityProgram";
import CharityProgramRecordsTable from "./CharityProgramRecordsTable";

class CharityProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charityList: [],
      charityTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("CHARITY_PROGRAM");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listCharityProgramData();
    } catch (e) {
      notification["error"]({
        message: "Charity Program",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listCharityProgramData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListCharityProgramData,
      setListCharityProgramDataTotal,
    } = this.props;
    try {
      this.setState({
        isLoading: true,
      });
      let charityData = await getAllCharityProgram(authToken, 0, 10);

      if (charityData.status === 200) {
        //set category data
        await setListCharityProgramData(charityData.data);
        await setListCharityProgramDataTotal(charityData.total);

        this.setState({
          charityList: charityData.data,
          charityTotal: charityData.total,
        });
      } else if (charityData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      let addCategoryData = await addCharityProgram(authToken, values);
      await this.listCharityProgramData();
    } catch (e) {
      notification["error"]({
        message: "Add Charity Program",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  generateCsv = async () => {
    try {
      const { authToken } = this.props;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getAllCharityProgram(authToken, 0, 100000000000000000)
            .then((responseData) => {
              let csvArray = [];

              csvArray.push([
                "Program Name",
                "Description",
                "Cause Categories",
                "Categories",
                "Unassigned Credits",
                "Is Active",
              ]);

              responseData.data.map((dataItem) => {
                csvArray.push([
                  dataItem.title ? dataItem.title : "NA",
                  dataItem.short_description
                    ? dataItem.short_description
                    : "NA",
                  dataItem.cause_categories
                    ? `"${dataItem.cause_categories}"`
                    : "NA",
                  dataItem.categories ? `"${dataItem.categories}"` : "NA",
                  dataItem.total_donation_left &&
                  dataItem.total_donation_left > 0
                    ? `"$ ${String(
                        dataItem.total_donation_left.toLocaleString()
                      )}"`
                    : "NA",
                  dataItem.is_active ? "Active" : "InActive",
                ]);
              });

              const rows = csvArray;
              let csvName = "AllPrograms.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((e) => {
              console.log("ERROR", e);
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "EXPORT DATA",
                description: typeof e === "string" ? e : "Something went wrong",
              });
              throw e;
            });
        }
      );
    } catch (e) {
      notification["error"]({
        message: "EXPORT DATA",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      console.log("error in generateCsv function", e);
    }
  };

  render() {
    const { primaryColor, charityList, isLoading, charityTotal } = this.state;

    return (
      <Fragment>
        <Title parent="List of Programs" title="List of Programs" />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <AddCharityProgram
              primaryColor={primaryColor}
              onSubmit={this.addSubmit}
              authToken={this.props.authToken}
            />
          </div>
          <div style={{ float: "left", cursor: "pointer" }}>
            <div
              onClick={() => this.generateCsv()}
              style={{
                width: "100px",
                backgroundColor: primaryColor,
                color: "white",
                padding: "7px 0px",
                textAlign: "center",
                borderRadius: "50px",
                marginLeft: "15px",
              }}
            >
              Export
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <CharityProgramRecordsTable
                data={charityList}
                primaryColor={primaryColor}
                charityTotal={charityTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCharityProgramData: (listCharityProgramData) => {
      dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA",
        listCharityProgramData: listCharityProgramData,
      });
    },
    setListCharityProgramDataTotal: (listCharityProgramDataTotal) => {
      dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA_TOTAL",
        listCharityProgramDataTotal: listCharityProgramDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CharityProgram));
