import { callGetApiToServer } from "../callApi";

export async function getAllTypeActiveCategory(authtoken, type) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = "v1/auth/list-all-type-active-category/" + type;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
