import {
  SET_LIST_VENDOR_AMOUNT_DATA,
  SET_LIST_VENDOR_AMOUNT_DATA_TOTAL,
} from "../constant/actionTypes";

const initial_state = {
  listVendorAmountData: null,
  listVendorAmountDataTotal: 0,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_LIST_VENDOR_AMOUNT_DATA:
      return {
        ...state,
        loading: false,
        listVendorAmountData: action.listVendorAmountData,
      };

    case SET_LIST_VENDOR_AMOUNT_DATA_TOTAL:
      return {
        ...state,
        loading: false,
        listVendorAmountDataTotal: action.listVendorAmountDataTotal,
      };

    default:
      return { ...state };
  }
};
