import { callApiToServer } from "../callApi";

export async function addStaticPage(authtoken, staticPageObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      title: staticPageObject.title,
      description: staticPageObject.description,
      slug_name: staticPageObject.slug_name,
    });

    let endUrl = "v1/auth/static-page";

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
