//export const WEBURL = "http://173.249.10.82:3001/";
// export const WEBURL = "https://six.api.dharmatech.in/";
export const AWS_URL = "https://sixprod.s3.ap-south/-1.amazonaws.com/";
// export const WEBURL = "http://localhost:3001/";
// STAGING ENV
export const WEBURL = "https://api.dev.sixtech.org/";
export const MODE = "development";
// PRODUCTION ENV
// export const WEBURL = "https://api.sixtech.org/";
// export const MODE = "production";
