import {
  SET_LIST_CHARITY_DATA,
  SET_LIST_CHARITY_DATA_TOTAL,
} from "../constant/actionTypes";

const initial_state = {
  listCharityData: null,
  listCharityDataTotal: 0,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_LIST_CHARITY_DATA:
      return {
        ...state,
        loading: false,
        listCharityData: action.listCharityData,
      };

    case SET_LIST_CHARITY_DATA_TOTAL:
      return {
        ...state,
        loading: false,
        listCharityDataTotal: action.listCharityDataTotal,
      };

    default:
      return { ...state };
  }
};
