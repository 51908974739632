import { callApiToServer } from "../callApi";

export async function searchUser(
  authtoken,
  searchText,
  skipNumber,
  limitNumber,
  role
) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      search_text: searchText,
    });

    let endUrl;
    if (role === "needy_family") {
      endUrl =
        "v1/auth/filter-family-user/" +
        role +
        "/" +
        skipNumber +
        "/" +
        limitNumber;
    } else if (role === "social_worker") {
      endUrl =
        "v1/auth/filter-worker-user/" +
        role +
        "/" +
        skipNumber +
        "/" +
        limitNumber;
    } else {
      endUrl =
        "v1/auth/filter-user/" + role + "/" + skipNumber + "/" + limitNumber;
    }

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
