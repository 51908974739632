import { WEBURL } from "../constant/comman";
import { encrypt } from "../service/encryption";

export async function callApiToServer(body, header, method, endPoint) {
  // console.log(
  //   "encryptedString in about to call adminLogin",
  //   body,
  //   header,
  //   method,
  //   endPoint
  // );
  let encryptedString = await encrypt(body);
  let sendBody = JSON.stringify({
    data: encryptedString,
  });

  return new Promise((resolve, reject) => {
    fetch(WEBURL + endPoint, {
      method: method,
      headers: header,
      body: sendBody,
    })
      .then(async (response) => {
        try {
          let data = await response.json();

          if (response.status === 500) {
            throw typeof data.message === "string"
              ? data.message
              : "Internal server Error";
          }
          resolve(data);
        } catch (err) {
          throw err;
        }
      })
      .catch((err) => {
        console.log("error printed here in callApi", err);
        reject(err);
      });
  });
}

export function callGetApiToServer(header, method, endPoint) {
  // console.log("in about to call adminLogin", header, method, endPoint);

  return new Promise((resolve, reject) => {
    fetch(WEBURL + endPoint, {
      method: method,
      headers: header,
    })
      .then(async (response) => {
        try {
          let data = await response.json();

          if (response.status === 500) {
            throw typeof data.message === "string"
              ? data.message
              : "Internal server Error";
          }
          resolve(data);
        } catch (err) {
          throw err;
        }
      })
      .catch((err) => {
        console.log("error printed here in callApi", err);
        reject(err);
      });
  });
}
