import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import * as serviceWorker from "./serviceWorker";
import Router from "./router/index";

//css
import "./assets/css/comman1.css";
import "./assets/css/comman2.css";
import "./assets/css/comman.css";

// ** Import custom components for redux**
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./components/app";

// Import custom Components

import Default from "./components/dashboard/defaultCompo/default";
import Login from "./components/Login/Login";
import SocketApp from "./SocketApp";

//firebase Auth
function Root() {
  useEffect(() => {
    const layout = localStorage.getItem("layout_version");
    document.body.classList.add(layout);
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Router />
            {/* <SocketApp /> */}
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
