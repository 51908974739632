import { callApiToServer } from "../callApi";

export async function editCharity(authtoken, charityObject, charityId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      charityId: charityId,
      corppass_id: charityObject.corppass_id,
      categories: charityObject.categories,
      nric_fin: charityObject.nric_fin,
      partial_nric_fin: charityObject.partial_nric_fin,
      principal_name: charityObject.principal_name,
      sex: charityObject.sex,
      race: charityObject.race,
      dob: charityObject.dob,
      nationality: charityObject.nationality,
      mobile_number: charityObject.mobile_number,
      email: charityObject.email,
      address: charityObject.address,
      type_of_hdb: charityObject.type_of_hdb,
      type_of_housing: charityObject.type_of_housing,
      maritial_status: charityObject.maritial_status,
      child_births: charityObject.child_births,
      entity_name: charityObject.entity_name,
      primary_activity: charityObject.primary_activity,
      secondary_activity: charityObject.secondary_activity,
    });

    let endUrl = "v1/auth/charity";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
