import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import RichTextEditor from "react-rte";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      changeFile: true,
      view: false,
      errors: {},
      description: RichTextEditor.createEmptyValue(),
    };
  }

  onDescriptionChange = (value) => {
    this.setState({ description: value });
  };

  validateForm = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Title is required";
    }

    if (!values.slug_name) {
      errors.slug_name = "Slug Name is required";
    }

    if (!values.description || values.description === "<p><br></p>") {
      errors.description = "Description is required";
    }

    return errors;
  };

  componentDidMount() {
    const { data } = this.props;
    if (data.description) {
      this.setState({
        description: RichTextEditor.createValueFromString(
          data.description,
          "html"
        ),
      });
    }
  }

  handleSubmit(values, action) {
    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                title: this.props.data.title,
                slug_name: this.props.data.slug_name,
                description: this.props.data.description,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Page Title
                        <Input
                          id="title"
                          placeholder="Page Title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.title && touched.title ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.title}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Page Description
                        <Input
                          id="slug_name"
                          placeholder="Page Description"
                          value={values.slug_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.slug_name && touched.slug_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.slug_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div
                        className=""
                        style={{ width: "100%", float: "left" }}
                      >
                        Page Content
                        <RichTextEditor
                          value={this.state.description}
                          onChange={(e) => {
                            setFieldValue("description", e.toString("html"));
                            this.onDescriptionChange(e);
                          }}
                          placeholder={"Description"}
                        />
                        {/* <TextArea
                          id="description"
                          placeholder="Page Content"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoSize={{ minRows: 10, maxRows: 10000 }}
                        /> */}
                        {errors.description && touched.description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.description}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
