/* eslint-disable */

import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Default from "../components/dashboard/defaultCompo/default";
import Login from "../components/Login/Login";
import App from "../components/app";
import { connect } from "react-redux";
import { AuthRouteGaurd } from "./AuthRouteGuard";
import { UnAuthRouteGaurd } from "./UnAuthRouteGuard";
import Category from "../components/Category";
import Vendor from "../components/Vendor";
import Charity from "../components/Charity";
import NeedyFamily from "../components/NeedyFamily";
import SocialWorker from "../components/SocialWorker";
import Contributor from "../components/Contributor";
import Voucher from "../components/Voucher";
import VendorHome from "../components/VendorHome";
import FilterVoucher from "../components/FilterVoucher";
import CharityNeedyFamily from "../components/CharityNeedyFamily";
import CharityHome from "../components/CharityHome";
import CharitySocialWorker from "../components/CharitySocialWorker";
import voucherIndex from "../components/FilterVoucher/voucherIndex";
import DragTest from "../components/Drag/index";
import CharityProgram from "../components/CharityProgram";
import CharitySetting from "../components/CharitySetting";
import VendorSetting from "../components/VendorSetting";
import CharityProgramAvailable from "../components/CharityAvailableCreditsTable";
import StaticPage from "../components/StaticPage";
import Linkup from "../components/Linkup";
import FilterVendorAmount from "../components/FilterVendorAmount";
import SubUser from "../components/SubUser";
import VendorLinkup from "../components/VendorLinkup";
import SubVendorHome from "../components/VendorHome/SubVendorHome";
import { MODE } from "../constant/comman";
import { Modal, Button } from "antd";

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    const { loginUserData, loginFlag } = this.props;

    if (loginFlag) {
      if (
        window.location.href.includes("https://vendor.dev.sixtech.org/") ||
        window.location.href.includes("https://vendor.sixtech.org/")
      ) {
        // check if URL is for vendor and role is not vendor
        if (
          loginUserData.role !== "vendor" &&
          loginUserData.role !== "sub_vendor"
        ) {
          if (MODE === "development") {
            this.setState({
              showModal: true,
            });
          } else {
            this.setState({
              showModal: true,
            });
          }
        }
      }

      // check if URL is for organization and role is not organization
      if (
        window.location.href.includes(
          "https://organization.dev.sixtech.org/"
        ) ||
        window.location.href.includes("https://organization.sixtech.org/")
      ) {
        if (loginUserData.role !== "charity") {
          if (MODE === "development") {
            this.setState({
              showModal: true,
            });
          } else {
            this.setState({
              showModal: true,
            });
          }
        }
      }
    }
  }

  render() {
    const { loginFlag, loginUserData } = this.props;
    const { showModal } = this.state;

    return (
      <Switch>
        <UnAuthRouteGaurd
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        />
        <Fragment>
          {showModal && (
            <Modal
              title="Login Section"
              onCancel={() => {
                this.setState({
                  showModal: false,
                });
              }}
              visible={showModal}
              footer={null}
            >
              <div style={{ marginBottom: "10px" }}>
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Login Role:
                </span>
                <span>
                  {loginUserData.role
                    ? String(
                        loginUserData.role === "charity"
                          ? "Organization".toLocaleUpperCase()
                          : loginUserData.role.toLocaleUpperCase()
                      ).toLocaleUpperCase()
                    : "No Role Selected"}
                </span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Button
                  onClick={() => {
                    if (loginUserData.role === "vendor") {
                      if (MODE === "development") {
                        window.location.href = "https://vendor.dev.sixtech.org";
                      } else {
                        window.location.href = "https://vendor.sixtech.org";
                      }
                    } else {
                      if (MODE === "development") {
                        window.location.href =
                          "https://organization.dev.sixtech.org";
                      } else {
                        window.location.href =
                          "https://organization.sixtech.org";
                      }
                    }
                  }}
                  type="primary"
                >
                  Go To Site
                </Button>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Button
                  onClick={() => {
                    this.props.setLogout();
                    window.location.reload();
                  }}
                  type="primary"
                >
                  Logout
                </Button>
              </div>
            </Modal>
          )}

          {showModal ? (
            <div
              style={{ width: window.innerWidth, backgroundColor: "white" }}
            ></div>
          ) : (
            <App>
              {/* dashboard menu */}
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={Default}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/dashboard`}
                component={Default}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/category`}
                component={Category}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/vendor`}
                component={Vendor}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/organization`}
                component={Charity}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/beneficiary`}
                component={NeedyFamily}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/social-worker`}
                component={SocialWorker}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/contributor`}
                component={Contributor}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/voucher`}
                component={Voucher}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/home`}
                component={VendorHome}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/home-vendor`}
                component={SubVendorHome}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/total_evoucher`}
                component={FilterVoucher}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/expiry_evoucher`}
                component={FilterVoucher}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/unassigned_evoucher`}
                component={FilterVoucher}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/active_evoucher`}
                component={FilterVoucher}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/redeemed_evoucher`}
                component={FilterVoucher}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/vendor_outstanding_amount`}
                component={FilterVendorAmount}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/organization-beneficiaries`}
                component={CharityNeedyFamily}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/organization-social-worker`}
                component={CharitySocialWorker}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/organization-home`}
                component={CharityHome}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/voucher-types`}
                component={voucherIndex}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/test-drag`}
                component={DragTest}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/organization-program`}
                component={CharityProgram}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/organization-setting`}
                component={CharitySetting}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/vendor-setting`}
                component={VendorSetting}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/organization-available-credit`}
                component={CharityProgramAvailable}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/static-pages`}
                component={StaticPage}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/sub-user`}
                component={SubUser}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/linkup`}
                component={Linkup}
              />
              <AuthRouteGaurd
                exact
                path={`${process.env.PUBLIC_URL}/vendor-linkup`}
                component={VendorLinkup}
              />
            </App>
          )}
        </Fragment>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  loginFlag: state.auth.loginFlag,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLogout: () => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: false,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
