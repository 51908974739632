import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
  DatePicker,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllVoucher } from "../../Graphs/Voucher/listVoucher";
import { filterVoucherBydate } from "../../Graphs/Voucher/filterVoucherByDate";
import { withRouter } from "react-router";
import { connect } from "react-redux";
var moment = require("moment");
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

class FilterVoucherTypeRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      isFilterApply: false,
      startDate: null,
      endDate: null,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedVoucher = this.getLimitedVoucher.bind(this);
  }

  financialYear = (value) => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    if (currentMonth < 3) {
      year -= 1;
    }

    const start = moment(`1/4/${year}`, "DD/MM/YYYY");
    const end = value ? moment() : moment(`31/03/${year + 1}`, "DD/MM/YYYY");
    return [start, end];
  };

  previousFinancialYear = () => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    const start = moment(`1/4/${year - 1}`, "DD/MM/YYYY");
    const end = moment(`31/3/${year}`, "DD/MM/YYYY");
    return [start, end];
  };

  onChangeDate = (date) => {
    if (!date.length) {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
    this.setState({
      startDate: date[0].format("YYYY-MM-DD HH:mm:ss"),
      endDate: date[1].format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  onFilter = async () => {
    try {
      const { startDate, endDate, pagination } = this.state;
      if (startDate && endDate) {
        this.setState(
          {
            isFilterApply: true,
          },
          async () => {
            await this.filterVoucherData(
              startDate,
              endDate,
              0,
              pagination.pageSize
            );
          }
        );
      } else {
        throw "Please select startDate and endDate";
      }
    } catch (e) {
      notification["error"]({
        message: "FILTER",
        description: typeof e === "string" ? e : "Error",
      });
    }
  };

  resetFilter = () => {
    const { pagination } = this.state;
    this.setState(
      {
        isFilterApply: false,
        startDate: null,
        endDate: null,
      },
      async () => {
        await this.listVoucherData(0, pagination.pageSize);
      }
    );
  };

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
    });
  }

  getLimitedVoucher = async (pagination) => {
    const { isFilterApply, startDate, endDate } = this.state;

    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (isFilterApply) {
          this.filterVoucherData(startDate, endDate, start, end);
        } else {
          this.listVoucherData(start, end);
        }
      }
    );
  };

  filterVoucherData = async (startDate, endDate, start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVoucherData,
        setListVoucherDataTotal,
        loginUserData,
        type,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let voucherData = await filterVoucherBydate(
        authToken,
        start,
        end,
        startDate,
        endDate
      );

      if (voucherData.status == 200) {
        //set user Data
        await setListVoucherData(voucherData.data);
        await setListVoucherDataTotal(voucherData.total);

        this.setState({
          isLoading: false,
          data: voucherData.data,
        });
      } else if (voucherData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherData && voucherData.message
          ? voucherData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list FILTER VOUCHER Data printed here", e);
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listVoucherData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVoucherData,
        setListVoucherDataTotal,
        loginUserData,
        type,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let voucherData = await getAllVoucher(authToken, start, end);

      if (voucherData.status == 200) {
        //set user Data
        await setListVoucherData(voucherData.data);
        await setListVoucherDataTotal(voucherData.total);

        this.setState({
          isLoading: false,
          data: voucherData.data,
        });
      } else if (voucherData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherData && voucherData.message
          ? voucherData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list VOUCHER INVENTORY Data printed here", e);
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 60,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      width: 160,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <Tooltip placement="bottom" title="Full Name">
                    <div>{item.name ? item.name : "-"}</div>
                  </Tooltip>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      render: (text, item, index) => {
        return (
          <div>
            {item.description ? (
              <Tooltip placement="bottom" title="Description">
                {item.description}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Category Name",
      key: "category_name",
      dataIndex: "category_name",
      render: (text, item, index) => {
        return (
          <div>
            {item.category ? (
              <Tooltip placement="bottom" title="Category Name">
                {item.category.name ? item.category.name : "-"}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (text, item, index) => {
        return (
          <div>
            {item.amount ? (
              <Tooltip placement="bottom" title="Amount">
                {`$ ${String(item.amount)}`}
              </Tooltip>
            ) : (
              "0"
            )}
          </div>
        );
      },
    },
  ];

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedVoucher(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const {
      listVoucherData,
      listVoucherDataTotal,
      searchLoader,
      authToken,
      type,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        {/* <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            display: "inline-block",
          }}
        >
          <div style={{ float: "left", width: "50%" }}>
            <RangePicker
              format="DD-MM-YYYY"
              onChange={this.onChangeDate}
              ranges={{
                Today: [
                  moment().startOf("day"),
                  moment().add(1, "days").endOf("day"),
                ],
                Yesterday: [
                  moment().subtract(1, "days").startOf("day"),
                  moment().endOf("day"),
                  // moment()
                  //   .subtract(1, "days")
                  //   .endOf("day")
                ],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Financial Year": this.financialYear(0),
                "Previous Financial Year": this.previousFinancialYear(),
              }}
            />
          </div>
          <div style={{ float: "right" }}>
            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={this.onFilter}
            >
              Filter
            </Button>

            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
                marginLeft: "15px",
              }}
              onClick={() => {
                this.resetFilter();
              }}
            >
              Reset
            </Button>
          </div>
        </div> */}

        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          //   scroll={{ x: 900 }}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listVoucherData}
          pagination={{
            total: listVoucherDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listVoucherData: state.voucher.listVoucherData,
  listVoucherDataTotal: state.voucher.listVoucherDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVoucherData: (listVoucherData) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA",
        listVoucherData: listVoucherData,
      });
    },
    setListVoucherDataTotal: (listVoucherDataTotal) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA_TOTAL",
        listVoucherDataTotal: listVoucherDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterVoucherTypeRecordsTable));
