import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select, notification } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import { getAllTypeActiveCategory } from "../../Graphs/Category/listAllTypeActiveCategory";

const { Content } = Layout;
const Option = Select.Option;

export class AddVendor extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedCategory: [],
      errors: {
        selectedCategory: "",
      },
    };
  }

  input;
  allCategories = [];

  async componentDidMount() {
    try {
      const { authToken } = this.props;

      let categoryData = await getAllTypeActiveCategory(authToken, "vendor");
      console.log("!!!!!!categoryData printed here", categoryData);

      categoryData &&
        categoryData.data &&
        categoryData.data.map((data, index) => {
          this.allCategories.push(<Option value={data.id}>{data.name}</Option>);
        });

      this.setState({
        categoryData: categoryData.data,
      });
    } catch (e) {
      notification["error"]({
        message: "Error",
        description:
          typeof e === "string"
            ? e
            : typeof e.message
            ? e.message
            : "Please try again later",
      });
    }
  }

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.corppass_id) {
      errors.corppass_id = "Corppass Id is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit = (values, action) => {
    const { selectedCategory, errors, categoryData } = this.state;
    const dummyErrors = { ...errors };

    if (!selectedCategory.length) {
      dummyErrors.selectedCategory = "Please select atleast one category";
      this.setState({
        errors: dummyErrors,
      });
      return;
    }

    let sendCategory = [];
    selectedCategory.map((data, index) => {
      let filterData = categoryData.filter((subData, subIndex) => {
        return data == subData.id;
      });
      if (filterData && filterData.length > 0) {
        sendCategory.push({
          id: data,
          name: filterData[0]["name"],
        });
      }
    });

    dummyErrors.selectedCategory = "";
    values.categories = sendCategory;

    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  };

  changeCategory = (value) => {
    this.setState({
      selectedCategory: value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedCategory: [],
      errors: {
        selectedCategory: "",
      },
    });
  };

  render() {
    const { primaryColor } = this.props;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          destroyOnClose={true}
          title="Add Vendor"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                corppass_id: "",
                categories: undefined,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        CorpPass ID
                        <Input
                          id="corppass_id"
                          placeholder="CorpPass ID"
                          value={values.corppass_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.corppass_id && touched.corppass_id ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.corppass_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Category
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeCategory(value)}
                          placeholder="Select Categories"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allCategories}
                        </Select>
                        {this.state.errors.selectedCategory ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedCategory}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
