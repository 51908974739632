const crypto = require("crypto");
const Cryptr = require("cryptr");
var aesjs = require("aes-js");
const { generateKeyPair } = require("crypto");
const JSEncrypt = require("node-jsencrypt");
let QuickEncrypt = require("quick-encrypt");

const ENCRYPTION_KEY = "62660f804bfa5c6e9a823b95531b7f33"; // Must be 256 bits (32 characters)
const IV_LENGTH = 16; // For AES, this is always 16
const cryptr = new Cryptr(ENCRYPTION_KEY);

const SECRET_KEY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
const SECRET_IV = [
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
];
const PUBLIC_KEY = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC65J6uDUcoNQV33Q11qs+F2oZ5
N7O73HjD0ouSlnDqJHzt4K/74Gd/qBljK1OxOi5EmBl1AiuYFwEzK8ail801Job1
kFj3T9AQLKh6wySy+8LkvUkI6oiALwPdSi53IMdJu4EHxDLFH5ub722EVs/VdUCy
55WQP3wCCDyei9EH4wIDAQAB`;
const PRIVATE_KEY = `MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBALrknq4NRyg1BXfd
DXWqz4Xahnk3s7vceMPSi5KWcOokfO3gr/vgZ3+oGWMrU7E6LkSYGXUCK5gXATMr
xqKXzTUmhvWQWPdP0BAsqHrDJLL7wuS9SQjqiIAvA91KLncgx0m7gQfEMsUfm5vv
bYRWz9V1QLLnlZA/fAIIPJ6L0QfjAgMBAAECgYBmQcSYkudGFoyhYq+EnCrjoAdH
t7R9q0ngSwjW8b1iPwij1vYzKDs4267YVdJ6+8xo95emL8POeXfMmb0bJVe/N1x1
OjS1BrRBpvAXjK0tKpb+O7vh1ElK7SafW/O1UpI0AID4g1qx/0kE2cAmKS9GA7N4
4nsKRkGSWfxLXSoZIQJBAOary7io6UKJcHdJZy9PcBCJNjXfOQFhMWeg3g1X/Ejh
OCjW+fM4Shp3HkIR3E7P7ZDZqWMSmDrcB+oqSYsStOkCQQDPajdJxhPwVqCoGBvP
im296xnertZ2obHawGr6vBzRMPD0pqX+pZlnq+hcZ+TKjH+CU6vZyJmSDhMUW4rz
e4brAkEArtS265badNvGqiKwWmNTBLV6qQovIqSP1YNPyb1OMc7ByfJmL7oSMEzo
Whs/Z1yhKpBOmhCV4Ma0GP9EDmx3QQJBAKKoVnFpgAtztAv+1Bh/I67Tp3cFv7RK
+9JkIcE+Mo8vBVenNPgtX894dgG5jCf35KsM+PESqrRqizXk0GQ/lD8CQFr7bIoM
SMt2CQM2nqwuWU8xJVF5pUYHGB6aZXrAFHT7W/slzgwzvLFPrdzSLgp1c4pHmT+c
lWrylAoU4F4p3uU=`;

// function encrypt(text) {
//     return cryptr.encrypt(text);
// }

// function decrypt(text) {
//     return cryptr.decrypt(text);
// }

// function encrypt(text) {
//  let iv = crypto.randomBytes(IV_LENGTH);
//  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), iv);
//  let encrypted = cipher.update(text);

//  encrypted = Buffer.concat([encrypted, cipher.final()]);

//  return iv.toString('hex') + ':' + encrypted.toString('hex');
// }

// function decrypt(text) {
//  let textParts = text.split(':');
//  let iv = Buffer.from(textParts.shift(), 'hex');
//  let encryptedText = Buffer.from(textParts.join(':'), 'hex');
//  let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), iv);
//  let decrypted = decipher.update(encryptedText);

//  decrypted = Buffer.concat([decrypted, decipher.final()]);

//  return decrypted.toString();
// }

function encrypt(address) {
  var key = SECRET_KEY;
  var iv = SECRET_IV;

  var textBytes = aesjs.utils.utf8.toBytes(address);
  // var padded = aesjs.padding.pkcs7.pad(textBytes);
  var aesOfb = new aesjs.ModeOfOperation.ctr(key, iv);
  var encryptedBytes = aesOfb.encrypt(textBytes);

  // To print or store the binary data, you may convert it to hex
  var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

  return encryptedHex;
}

function decrypt(address) {
  var key = SECRET_KEY;
  var iv = SECRET_IV;

  // When ready to decrypt the hex string, convert it back to bytes
  var encryptedBytes = aesjs.utils.hex.toBytes(address);
  // The output feedback mode of operation maintains internal state,
  // so to decrypt a new instance must be instantiated.
  var aesOfb = new aesjs.ModeOfOperation.ctr(key, iv);
  var decryptedBytes = aesOfb.decrypt(encryptedBytes);

  // Convert our bytes back into text
  var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

  return decryptedText;
}

function encryptBody(address) {
  // generateKeyPair(
  //   "rsa",
  //   {
  //     modulusLength: 1024, // key size in bits
  //     publicKeyEncoding: {
  //       type: "spki",
  //       format: "pem",
  //     },
  //     privateKeyEncoding: {
  //       type: "pkcs8",
  //       format: "pem",
  //     },
  //   },
  //   (err, publicKey, privateKey) => {
  //     // Handle errors and use the generated key pair.
  //     console.log("!!!!!publicKey", publicKey, privateKey);
  //   }
  // );

  // let keys = QuickEncrypt.generate(1024); // Use either 2048 bits or 1024 bits.
  // console.log(keys.public); // Public Key that has been generated.
  // console.log(keys.private);

  let encrypted;

  try {
    const crypt = new JSEncrypt();
    crypt.setKey(PUBLIC_KEY);
    encrypted = crypt.encrypt(address);

    // encrypted = QuickEncrypt.encrypt(address, PUBLIC_KEY);

    return encrypted;
  } catch (e) {
    console.log("!!!!!error printed here", e);
    throw "Encryption not worked, please try again";
  }
}

function decryptBody(address) {
  let decrypted;
  console.log("!!!!!!!!address", address);

  try {
    const crypt = new JSEncrypt();
    crypt.setPrivateKey(PRIVATE_KEY);
    decrypted = crypt.decrypt(address);
    console.log("!!!!!!!decrypted", decrypted);

    return decrypted;
  } catch (e) {
    console.log("!!!!!error printed here", e);
    throw "Decryption not worked, please try again";
  }
}

export { decrypt, encrypt, encryptBody, decryptBody };

// var getEncryptedKey = async (address) => {
//     var key = JSON.parse(process.env.SECRET_KEY);
//     var iv = JSON.parse(process.env.SECRET_IV);

//     var textBytes = aesjs.utils.utf8.toBytes(address);
//     var aesOfb = new aesjs.ModeOfOperation.ofb(key, iv);
//     var encryptedBytes = aesOfb.encrypt(textBytes);

//     // To print or store the binary data, you may convert it to hex
//     var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

//     return encryptedHex;
// }

// module.exports = {
//     getEncryptedKey
// }

// var aesjs = require('aes-js');

// var decryptPrivateKey = async (address) => {
//     var key = JSON.parse(process.env.SECRET_KEY);
//     var iv = JSON.parse(process.env.SECRET_IV);

//     // When ready to decrypt the hex string, convert it back to bytes
//     var encryptedBytes = aesjs.utils.hex.toBytes(address);
//     // The output feedback mode of operation maintains internal state,
//     // so to decrypt a new instance must be instantiated.
//     var aesOfb = new aesjs.ModeOfOperation.ofb(key, iv);
//     var decryptedBytes = aesOfb.decrypt(encryptedBytes);

//     // Convert our bytes back into text
//     var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

//     return decryptedText;
// }

// module.exports = {
//     decryptPrivateKey
// }

// An example 128-bit key
// var key = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ];

// The initialization vector (must be 16 bytes)
// var iv = [ 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,35, 36 ];
