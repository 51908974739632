/* jshint-disable */

import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import RichTextEditor from "react-rte";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;

export class AddStaticPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      view: false,
      description: RichTextEditor.createEmptyValue(),
    };
  }

  onDescriptionChange = (value) => {
    this.setState({ description: value });
  };

  validateForm = (values) => {
    const { description } = this.state;

    const errors = {};

    if (!values.title) {
      errors.title = "Title is required";
    }

    if (!values.slug_name) {
      errors.slug_name = "Slug Name is required";
    }

    if (!values.description || values.description === "<p><br></p>") {
      errors.description = "Description is required";
    }

    return errors;
  };

  handleSubmit = (values, action) => {
    console.log("!!!!!values", values);
    this.props.onSubmit(values);
    this.handleCancel();
    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { primaryColor } = this.props;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          destroyOnClose={true}
          title="Add Static Page"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          width={"800px"}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                title: "",
                slug_name: "",
                description: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Page Title
                        <Input
                          id="title"
                          placeholder="Page Title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.title && touched.title ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.title}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Page Description
                        <Input
                          id="slug_name"
                          placeholder="Page Description"
                          value={values.slug_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.slug_name && touched.slug_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.slug_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div
                        className=""
                        style={{ width: "100%", float: "left" }}
                      >
                        Page Content
                        <RichTextEditor
                          value={this.state.description}
                          onChange={(e) => {
                            setFieldValue("description", e.toString("html"));
                            this.onDescriptionChange(e);
                          }}
                          placeholder={"Description"}
                        />
                        {/* <TextArea
                          id="description"
                          placeholder="Page Content"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoSize={{ minRows: 10, maxRows: 10000 }}
                        /> */}
                        {errors.description && touched.description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.description}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
