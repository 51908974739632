import * as React from "react";
import {
  Modal,
  Spin,
  Layout,
  Input,
  TimePicker,
  Select,
  notification,
  Button,
  Divider,
} from "antd";
import { Formik, FieldProps, Field } from "formik";

const { Content } = Layout;

export class AddRedeemCodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.redeem_code) {
      errors.redeem_code = "Voucher code is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = async (values, action) => {
    console.log("Basic Value - ", values, this.props.data);
    values.voucherId = this.props.data.id;
    this.props.onSubmit(values, this.props.data);

    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      // <StyleBox>
      <Modal
        width="600px"
        closable={this.state.closable}
        visible={this.state.visible}
        title="Add Single Voucher Code"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          <div>
            <Content style={{ background: "#fff", marginLeft: "10px" }}>
              <Formik
                initialValues={{
                  redeem_code: "",
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }) => {
                  return (
                    <div>
                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Voucher Code
                          <Input
                            id="redeem_code"
                            placeholder="Voucher Code"
                            value={values.redeem_code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.redeem_code && touched.redeem_code ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.redeem_code}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <Button
                        // type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </div>
                  );
                }}
              </Formik>
            </Content>
          </div>
        )}
      </Modal>
    );
  }
}
