import { callApiToServer } from "../callApi";

export async function uploadSocialWorker(authtoken, socialWorkerObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      fileName: socialWorkerObject.file,
    });

    let endUrl = "v1/auth/add-social-worker-excel";

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}

export async function uploadSingleSocialWorker(authtoken, socialWorkerObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify(socialWorkerObject);

    let endUrl = "v1/auth/add-social-worker";

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
