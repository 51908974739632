import React, { Fragment, Component } from 'react';
import Title from './Title';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getAllUser } from '../../Graphs/User/listUser';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Spin, notification, Button } from 'antd';
import UserRecordsTable from './UserRecordsTable';

class NeedyFamily extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userList: [],
			userTotal: 0,
			isLoading: false,
			primaryColor: '#4466f2',
			envData: null,
		};
	}

	async componentDidMount() {
		try {
			const { setRouteName, setSearchText } = this.props;
			let editPrimaryColor = await localStorage.getItem('primary_color');

			await setRouteName('NEEDY_FAMILY');
			await setSearchText('');

			this.setState({
				isLoading: true,
				primaryColor: editPrimaryColor,
			});

			await this.listUserData();
		} catch (e) {
			notification['error']({
				message: 'User',
				description: typeof e === 'string' ? e : 'Something went wrong',
			});
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	}

	listUserData = async () => {
		const { authToken, history, setLoginFlag, setUserData, setUserToken, setListUserData, setListUserDataTotal } =
			this.props;

		try {
			this.setState({
				isLoading: true,
			});

			let userData = await getAllUser(authToken, 0, 10, 'needy_family');

			if (userData.status === 200) {
				//set category data
				await setListUserData(userData.data);
				await setListUserDataTotal(userData.total);

				this.setState({
					userList: userData.data,
					userTotal: userData.total,
				});
			} else if (userData.status === 401) {
				await setLoginFlag(false);
				await setUserData(null);
				await setUserToken(null);
				history.push(`${process.env.PUBLIC_URL}/login`);
			}
		} catch (e) {
			throw e;
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	};

	render() {
		const { primaryColor, userList, isLoading, userTotal } = this.state;

		return (
			<Fragment>
				<Title parent="Beneficiaries" title="Beneficiaries" />

				<div className="container-fluid">
					{isLoading ? (
						<div style={{ marginLeft: '20px' }}>
							<Spin size="large" style={{ marginLeft: '480px', marginTop: '130px' }} />
						</div>
					) : (
						<div>
							<UserRecordsTable data={userList} primaryColor={primaryColor} userTotal={userTotal} />
						</div>
					)}
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	authToken: state.auth.authToken,
});

const mapDispatchToProps = (dispatch) => {
	return {
		setLoginFlag: (flag) => {
			dispatch({
				type: 'SET_LOGIN_FLAG',
				flag: flag,
			});
		},
		setUserData: (userData) => {
			dispatch({
				type: 'SET_USER_DATA',
				userData: userData,
			});
		},
		setUserToken: (authToken) => {
			dispatch({
				type: 'SET_USER_AUTHTOKEN',
				authToken: authToken,
			});
		},
		setListUserData: (listUserData) => {
			dispatch({
				type: 'SET_LIST_USER_DATA',
				listUserData: listUserData,
			});
		},
		setListUserDataTotal: (listUserDataTotal) => {
			dispatch({
				type: 'SET_LIST_USER_DATA_TOTAL',
				listUserDataTotal: listUserDataTotal,
			});
		},
		setRouteName: (routeName) => {
			dispatch({
				type: 'SET_ROUTE_NAME',
				routeName: routeName,
			});
		},
		setSearchText: (searchText) => {
			dispatch({
				type: 'SET_SEARCH_TEXT',
				searchText: searchText,
			});
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NeedyFamily));
