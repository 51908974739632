import { Modal, Button, Divider } from "antd";
import * as React from "react";
import {
  Layout,
  Input,
  TimePicker,
  Select,
  notification,
  DatePicker,
  Spin,
} from "antd";
import { Formik, FieldProps, Field } from "formik";
import { getAllTypeActiveCategory } from "../../Graphs/Category/listAllTypeActiveCategory";
import { getAllSocialWorkerDropDown } from "../../Graphs/SocialWorker/listAllSocialWorkerDropDown";

const { Content } = Layout;
const Option = Select.Option;

export class AssignWorkerCredit extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedCategory: undefined,
      selectedWorker: undefined,
    };
  }

  allCategories = [];
  allWorkers = [];

  async componentDidMount() {
    try {
      const { authToken } = this.props;
      let { categorie_ids } = this.props.data;
      let allprogramCategory = categorie_ids.split(", ");
      console.log("!!!!!allprogramCategory", allprogramCategory);

      this.setState({
        isLoading: true,
      });

      let categoryData = await getAllTypeActiveCategory(authToken, "vendor");
      let workerData = await getAllSocialWorkerDropDown(authToken);

      console.log("categoryData", categoryData.data);

      categoryData &&
        categoryData.data &&
        categoryData.data.map((data, index) => {
          if (allprogramCategory.includes(data.id)) {
            this.allCategories.push(
              <Option value={data.id}>{data.name}</Option>
            );
          }
        });

      workerData &&
        workerData.data &&
        workerData.data.map((data, index) => {
          this.allWorkers.push(
            <Option value={data.id}>
              {data.user_metadata && data.user_metadata.principal_name
                ? data.user_metadata.principal_name
                : "Social Worker"}
            </Option>
          );
        });

      this.setState({
        isLoading: false,
        categoryData: categoryData.data,
        workerData: workerData.data,
      });
    } catch (e) {
      notification["error"]({
        message: "Error",
        description:
          typeof e === "string"
            ? e
            : typeof e.message
            ? e.message
            : "Please try again later",
      });
    }
  }

  changeCategory = (value) => {
    this.setState({
      selectedCategory: value,
    });
  };

  changeWorker = (value) => {
    this.setState({
      selectedWorker: value,
    });
  };

  validateForm = (values) => {
    const { selectedCategory, selectedWorker } = this.state;
    const errors = {};

    if (!values.amount) {
      errors.amount = "Amount is required";
    }

    if (!(values.amount >= 0)) {
      errors.amount = "Amount can not be negative";
    }

    if (!selectedCategory) {
      errors.category_id = "Category is required";
    }

    if (!selectedWorker) {
      errors.worker_user_id = "Supervisor is required";
    }

    return errors;
  };

  handleSubmit = (values, action) => {
    try {
      this.props.onSubmit(values, this.props.data.id);
      this.props.handleCancel();

      this.setState({
        visible: false,
      });
      this.handleCancel();
    } catch (e) {
      console.log("!!!!error", e);
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      selectedCategory: undefined,
      selectedWorker: undefined,
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                amount: 0,
                category_id: undefined,
                worker_user_id: undefined,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return isLoading ? (
                  <div style={{ marginLeft: "20px" }}>
                    <Spin
                      size="large"
                      style={{ width: "100%", margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Amount ( $ )
                        <Input
                          id="amount"
                          type={"number"}
                          placeholder="Amount"
                          value={values.amount}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.handleSubmit(values);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.amount && touched.amount ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.amount}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Category
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onSelect={(value) => this.changeCategory(value)}
                          onChange={(value) => {
                            this.changeCategory(value);
                            setFieldValue("category_id", value);
                          }}
                          onBlur={() => setFieldTouched("category_id", true)}
                          placeholder="Select Category"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allCategories}
                        </Select>
                        {errors.category_id ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.category_id}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Supervisor
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onSelect={(value) => this.changeWorker(value)}
                          onChange={(value) => {
                            this.changeWorker(value);
                            setFieldValue("worker_user_id", value);
                          }}
                          onBlur={() => setFieldTouched("worker_user_id", true)}
                          placeholder="Select Supervisor"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allWorkers}
                        </Select>
                        {errors.worker_user_id ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.worker_user_id}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Assign
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
