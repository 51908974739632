import { Table, Tooltip, notification, Spin, Button } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllSocialWorkerNeedyFamily } from "../../Graphs/SocialWorker/listSocialWorkerNeedyFamily";
import { withRouter } from "react-router";
import { connect } from "react-redux";
var moment = require("moment");

class NeedyFamilyData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.listWorkerNeedyFamilyData(0, 5);
  }

  getLimitedRedeem = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listWorkerNeedyFamilyData(start, end);
      }
    );
  };

  listWorkerNeedyFamilyData = async (start, end) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });

      let workerFamilyData = await getAllSocialWorkerNeedyFamily(
        authToken,
        this.props.data.id,
        start,
        end
      );

      if (workerFamilyData.status == 200) {
        this.setState({
          isLoading: false,
          data: workerFamilyData.data,
          dataTotal: workerFamilyData.total,
        });
      } else if (workerFamilyData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw workerFamilyData && workerFamilyData.message
          ? workerFamilyData.message
          : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "SOCIAL_WORKER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedRedeem(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 80,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "NRIC/FIN",
      key: "SingpassId",
      width: 120,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="NRIC/FIN">
                <div>
                  {item.familyUserForWorker
                    ? item.familyUserForWorker.singpass_id
                    : "-"}
                </div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "Name",
      width: 180,
      render: (text, item, index) => {
        return (
          <div
            style={{
              alignItems: "center",
              display: "inline-block",
              width: "100%",
            }}
          >
            <div flexDirection="column">
              <Tooltip placement="bottom" title="Name">
                <div>
                  {item.familyUserForWorker &&
                  item.familyUserForWorker.user_metadata &&
                  item.familyUserForWorker.user_metadata.principal_name
                    ? item.familyUserForWorker.user_metadata.principal_name
                    : "-"}
                </div>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "Email",
      width: 180,
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Email">
              <div>
                {item.familyUserForWorker &&
                item.familyUserForWorker.user_metadata &&
                item.familyUserForWorker.user_metadata.email
                  ? item.familyUserForWorker.user_metadata.email
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Mobile Number",
      key: "Mobile Number",
      width: 180,
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Mobile Number">
              <div>
                {item.familyUserForWorker &&
                item.familyUserForWorker.user_metadata &&
                item.familyUserForWorker.user_metadata.mobile_number
                  ? item.familyUserForWorker.user_metadata.mobile_number
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Address",
      key: "Address",
      render: (text, item, index) => {
        let addressString = "";
        if (
          item.familyUserForWorker &&
          item.familyUserForWorker.user_metadata &&
          item.familyUserForWorker.user_metadata.address
        ) {
          let addressJson = JSON.parse(
            item.familyUserForWorker.user_metadata.address
          );
          if (addressJson.floor && addressJson.floor.value) {
            addressString = addressString + addressJson.floor.value + ", ";
          }

          if (addressJson.block && addressJson.block.value) {
            addressString = addressString + addressJson.block.value + ", ";
          }

          if (addressJson.building && addressJson.building.value) {
            addressString = addressString + addressJson.building.value + ", ";
          }

          if (addressJson.street && addressJson.street.value) {
            addressString = addressString + addressJson.street.value + ", ";
          }

          if (addressJson.postal && addressJson.postal.value) {
            addressString = addressString + addressJson.postal.value + ", ";
          }

          if (addressJson.country && addressJson.country.value) {
            addressString = addressString + addressJson.country.desc;
          }
        }
        return (
          <div>
            <Tooltip placement="bottom" title="Address">
              <div>{addressString ? addressString : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  capitalizeFirstLetter(string) {
    return (
      string.charAt(0).toUpperCase() + String(string.slice(1)).toLowerCase()
    );
  }

  generateCsv = async () => {
    try {
      const { authToken } = this.props;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getAllSocialWorkerNeedyFamily(
            authToken,
            this.props.data.id,
            0,
            100000000000000000
          )
            .then((responseData) => {
              let csvArray = [];

              csvArray.push([
                "NRIC/FIN",
                "Name",
                "Email",
                "Mobile Number",
                "Address",
              ]);

              responseData.data.map((dataItem) => {
                let addressString = "";
                if (
                  dataItem.familyUserForWorker &&
                  dataItem.familyUserForWorker.user_metadata &&
                  dataItem.familyUserForWorker.user_metadata.address
                ) {
                  let addressJson = JSON.parse(
                    dataItem.familyUserForWorker.user_metadata.address
                  );
                  if (addressJson.floor && addressJson.floor.value) {
                    addressString =
                      addressString + addressJson.floor.value + ", ";
                  }

                  if (addressJson.block && addressJson.block.value) {
                    addressString =
                      addressString + addressJson.block.value + ", ";
                  }

                  if (addressJson.building && addressJson.building.value) {
                    addressString =
                      addressString + addressJson.building.value + ", ";
                  }

                  if (addressJson.street && addressJson.street.value) {
                    addressString =
                      addressString + addressJson.street.value + ", ";
                  }

                  if (addressJson.postal && addressJson.postal.value) {
                    addressString =
                      addressString + addressJson.postal.value + ", ";
                  }

                  if (addressJson.country && addressJson.country.value) {
                    addressString = addressString + addressJson.country.desc;
                  }
                }

                csvArray.push([
                  dataItem.familyUserForWorker
                    ? dataItem.familyUserForWorker.singpass_id
                    : "NA",
                  dataItem.familyUserForWorker &&
                  dataItem.familyUserForWorker.user_metadata &&
                  dataItem.familyUserForWorker.user_metadata.principal_name
                    ? dataItem.familyUserForWorker.user_metadata.principal_name
                    : "NA",
                  dataItem.familyUserForWorker &&
                  dataItem.familyUserForWorker.user_metadata &&
                  dataItem.familyUserForWorker.user_metadata.email
                    ? dataItem.familyUserForWorker.user_metadata.email
                    : "NA",
                  dataItem.familyUserForWorker &&
                  dataItem.familyUserForWorker.user_metadata &&
                  dataItem.familyUserForWorker.user_metadata.mobile_number
                    ? dataItem.familyUserForWorker.user_metadata.mobile_number
                    : "NA",
                  addressString ? `"${addressString}"` : "NA",
                ]);
              });

              const rows = csvArray;
              let csvName = "AllAssignedBeneficiaries.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((e) => {
              console.log("ERROR", e);
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "EXPORT DATA",
                description: typeof e === "string" ? e : "Something went wrong",
              });
              throw e;
            });
        }
      );
    } catch (e) {
      notification["error"]({
        message: "EXPORT DATA",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      console.log("error in generateCsv function", e);
    }
  };

  render() {
    const { isLoading, data, dataTotal } = this.state;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
      </div>
    ) : (
      <>
        <div
          style={{
            marginTop: "0px",
            marginBottom: "20px",
            width: "100%",
            display: "inline-block",
          }}
        >
          <div style={{ float: "left", width: "50%" }}>
            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
                marginLeft: "15px",
              }}
              onClick={() => {
                this.generateCsv();
              }}
            >
              Export
            </Button>
          </div>
        </div>
        <div style={{ "overflow-x": "auto" }}>
          <Table
            loading={this.state.isLoading}
            rowKey={(record) => record._id}
            columns={this.columns}
            size={"small"}
            dataSource={data}
            pagination={{
              total: dataTotal,
              showSizeChanger: true,
              pageSize: this.state.pagination.pageSize,
              current: this.state.pagination.current,
              pageSizeOptions: ["1", "5", "10", "25", "50", "100"],
              onChange: (e) => {
                console.log("onChanges pagination");
                // e - 1
                //   ? (this.counter = this.tempCounter =
                //       (e - 1) * this.state.pagination.pageSize)
                //   : ((this.counter = 0), (this.tempCounter = 0));
                if (e - 1) {
                  this.counter = this.tempCounter =
                    (e - 1) * this.state.pagination.pageSize;
                  return;
                }

                this.counter = 0;
                this.tempCounter = 0;
              },
            }}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NeedyFamilyData));
