import { callApiToServer } from "../callApi";

export async function assignSocialWorker(authtoken, userObject, familyUserId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      family_user_id: familyUserId,
      worker_user_id: userObject.worker_user_id,
    });

    let endUrl = "v1/auth/assign-social-worker-needy-family";

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
