import { callGetApiToServer } from "../callApi";

export async function getAllVendorLinkup(authtoken, skipNumber, limitNumber) {
  try {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    const endUrl =
      "v1/auth/list-vendor-linkup/" + skipNumber + "/" + limitNumber;
    const responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
