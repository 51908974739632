import { callApiToServer } from "../callApi";

export async function editVendorLinkup(
  authtoken,
  vendorLinkupObject,
  linkupId
) {
  try {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    const body = JSON.stringify({
      linkupId: linkupId,
      linkup_corppass_id: vendorLinkupObject.linkup_corppass_id,
      corppass_id: vendorLinkupObject.corppass_id,
    });
    const endUrl = "v1/auth/vendor-linkup";
    const responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
