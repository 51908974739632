import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllLinkup } from "../../Graphs/Linkup/listLinkup";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import LinkupRecordsTable from "./LinkupRecordsTable";
import { AddLinkup } from "./AddLinkup";
import { addLinkup } from "../../Graphs/Linkup/addLinkup";

class Linkup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkupList: [],
      linkupTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("LINKUP");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listLinkupData();
    } catch (e) {
      notification["error"]({
        message: "Linkup",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listLinkupData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListLinkupData,
      setListLinkupDataTotal,
    } = this.props;
    try {
      this.setState({
        isLoading: true,
      });

      let linkupData = await getAllLinkup(authToken, 0, 10);

      if (linkupData.status === 200) {
        //set category data
        await setListLinkupData(linkupData.data);
        await setListLinkupDataTotal(linkupData.total);

        this.setState({
          linkupList: linkupData.data,
          linkupTotal: linkupData.total,
        });
      } else if (linkupData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      await addLinkup(authToken, values);
      notification["success"]({
        message: "Add Linkup",
        description: "SuccessFully added",
      });

      await this.listLinkupData();
    } catch (e) {
      notification["error"]({
        message: "Add Linkup",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, linkupList, isLoading, linkupTotal } = this.state;

    return (
      <Fragment>
        <Title parent="Manage Linkups" title="Manage Linkups" />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <AddLinkup primaryColor={primaryColor} onSubmit={this.addSubmit} />
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <LinkupRecordsTable
                data={linkupList}
                primaryColor={primaryColor}
                linkupTotal={linkupTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListLinkupData: (listLinkupData) => {
      dispatch({
        type: "SET_LIST_LINKUP_DATA",
        listLinkupData: listLinkupData,
      });
    },
    setListLinkupDataTotal: (listLinkupDataTotal) => {
      dispatch({
        type: "SET_LIST_LINKUP_DATA_TOTAL",
        listLinkupDataTotal: listLinkupDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Linkup));
