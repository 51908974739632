import { callApiToServer } from "../callApi";

export async function addVoucher(authtoken, voucherObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      name: voucherObject.name,
      description: voucherObject.description,
      category_id: voucherObject.category_id,
      terms: voucherObject.terms,
      instructions: voucherObject.instructions,
      amount: String(voucherObject.amount),
      discount_amount: String(voucherObject.discount_amount),
      discount_percentage: String(voucherObject.discount_percentage),
      is_paid: voucherObject.is_paid,
      validity: parseInt(voucherObject.validity),
      start_date: voucherObject.start_date,
      end_date: voucherObject.end_date,
      icon_name: voucherObject.icon_name,
      is_external: voucherObject.is_external,
      external_link: voucherObject.external_link,
    });

    let endUrl = "v1/auth/voucher";

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
