import React, { Fragment, Component } from "react";
import Title from "./Title";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllVoucher } from "../../Graphs/Voucher/listVoucher";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import FilterVoucherTypeRecordsTable from "./FilterVoucherTypeRecordsTable";

class VoucherIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherList: [],
      voucherTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("VOUCHER_INVENTORY");
      await setSearchText("");

      this.setState(
        {
          isLoading: true,
          primaryColor: editPrimaryColor,
        },
        async () => {
          await this.listVoucherData();
        }
      );
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listVoucherData = async () => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVoucherData,
        setListVoucherDataTotal,
        setRouteName,
        setSearchText,
        loginUserData,
      } = this.props;

      this.setState({
        isLoading: true,
      });

      let voucherData = await getAllVoucher(authToken, 0, 10);

      if (voucherData.status == 200) {
        //set user data
        await setListVoucherData(voucherData.data);
        await setListVoucherDataTotal(voucherData.total);

        this.setState({
          voucherList: voucherData.data,
          voucherTotal: voucherData.total,
        });
      } else if (voucherData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherData && voucherData.message
          ? voucherData.message
          : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  generateCsv = async () => {
    try {
      const { authToken } = this.props;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getAllVoucher(authToken, 0, 100000000000000000)
            .then((responseData) => {
              let csvArray = [];

              csvArray.push(["Name", "Description", "Category Name", "Amount"]);

              responseData.data.map((dataItem) => {
                csvArray.push([
                  dataItem.name ? dataItem.name : "NA",
                  dataItem.description ? dataItem.description : "NA",
                  dataItem.category.name ? dataItem.category.name : "NA",
                  dataItem.amount ? `$ ${String(dataItem.amount)}` : "NA",
                ]);
              });

              const rows = csvArray;
              let csvName = "AllVoucherTypes.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((e) => {
              console.log("ERROR", e);
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "EXPORT DATA",
                description: typeof e === "string" ? e : "Something went wrong",
              });
              throw e;
            });
        }
      );
    } catch (e) {
      notification["error"]({
        message: "EXPORT DATA",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      console.log("error in generateCsv function", e);
    }
  };

  render() {
    const { primaryColor, voucherList, isLoading, voucherTotal } = this.state;

    return (
      <Fragment>
        <Title parent="Voucher Types" title="Voucher Types" />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <div
              onClick={() => this.generateCsv()}
              style={{
                width: "100px",
                backgroundColor: primaryColor,
                color: "white",
                padding: "7px 0px",
                textAlign: "center",
                borderRadius: "0px",
                marginLeft: "0px",
              }}
            >
              Export
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <FilterVoucherTypeRecordsTable
                data={voucherList}
                primaryColor={primaryColor}
                voucherTotal={voucherTotal}
                type={this.state.type}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVoucherData: (listVoucherData) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA",
        listVoucherData: listVoucherData,
      });
    },
    setListVoucherDataTotal: (listVoucherDataTotal) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA_TOTAL",
        listVoucherDataTotal: listVoucherDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VoucherIndex));
