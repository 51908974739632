import {
  Table,
  Button,
  Select,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
  DatePicker,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllPaidTransactions } from "../../Graphs/Vendor/listPaidTransactions";
import { getAllFilterPaidTransactions } from "../../Graphs/Vendor/filterPaidTransactions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
var moment = require("moment");
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const Option = Select.Option;

class FilterVendorAmountRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      isFilterApply: false,
      startDate: null,
      endDate: null,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      voucherData: [],
      searchText: "",
      selectedVoucherId: undefined,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedVendorAmount = this.getLimitedVendorAmount.bind(this);
  }

  allVoucherType = [];

  financialYear = (value) => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    if (currentMonth < 3) {
      year -= 1;
    }

    const start = moment(`1/4/${year}`, "DD/MM/YYYY");
    const end = value ? moment() : moment(`31/03/${year + 1}`, "DD/MM/YYYY");
    return [start, end];
  };

  previousFinancialYear = () => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    const start = moment(`1/4/${year - 1}`, "DD/MM/YYYY");
    const end = moment(`31/3/${year}`, "DD/MM/YYYY");
    return [start, end];
  };

  onChangeDate = (date) => {
    if (!date.length) {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
    this.setState({
      startDate: date[0].format("YYYY-MM-DD HH:mm:ss"),
      endDate: date[1].format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  onFilter = async () => {
    try {
      const { startDate, endDate, pagination } = this.state;
      if (startDate && endDate) {
        this.setState(
          {
            isFilterApply: true,
          },
          async () => {
            await this.filterVendorAmountData(
              startDate,
              endDate,
              0,
              pagination.pageSize
            );
          }
        );
      } else {
        throw "Please select startDate and endDate";
      }
    } catch (e) {
      notification["error"]({
        message: "FILTER",
        description: typeof e === "string" ? e : "Error",
      });
    }
  };

  resetFilter = () => {
    const { pagination } = this.state;
    this.setState(
      {
        isFilterApply: false,
        startDate: null,
        endDate: null,
        selectedVoucherId: undefined,
        searchText: "",
      },
      async () => {
        await this.listVendorAmountData(0, pagination.pageSize);
      }
    );
  };

  getLimitedVendorAmount = async (pagination) => {
    const { isFilterApply, startDate, endDate } = this.state;

    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (isFilterApply) {
          this.filterVendorAmountData(startDate, endDate, start, end);
        } else {
          this.listVendorAmountData(start, end);
        }
      }
    );
  };

  generateCsv = async () => {
    try {
      const { authToken, loginUserData } = this.props;
      const { isFilterApply, startDate, endDate } = this.state;

      if (isFilterApply) {
        this.setState(
          {
            isLoading: true,
          },
          () => {
            getAllFilterPaidTransactions(
              authToken,
              loginUserData.id,
              startDate,
              endDate,
              0,
              100000000000000000
            )
              .then((responseData) => {
                let csvArray = [];

                csvArray.push([
                  "Amount",
                  "Type",
                  "Transaction At",
                  "Invoice Number",
                  "PO Number",
                  "Transaction Number",
                ]);

                responseData.data.map((dataItem) => {
                  csvArray.push([
                    dataItem.amount ? `$ ${dataItem.amount}` : "NA",
                    dataItem.type
                      ? dataItem.type === "credit"
                        ? "Credit"
                        : "Debit"
                      : "NA",
                    dataItem.transaction_at
                      ? `"${this.dateFormat(dataItem.transaction_at)}"`
                      : "NA",
                    dataItem.invoice_number ? dataItem.invoice_number : "NA",
                    dataItem.po_number ? dataItem.po_number : "NA",
                    dataItem.transaction_number
                      ? dataItem.transaction_number
                      : "NA",
                  ]);
                });

                const rows = csvArray;
                let csvName = "AllOutstandingAmount.csv";
                let csvContent = "data:text/csv;charset=utf-8,";

                rows.forEach(function (rowArray) {
                  let row = rowArray.join(",");
                  csvContent += row + "\r\n";
                });

                var encodedUri = encodeURI(csvContent);
                // // // // window.open(encodedUri);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", csvName);
                document.body.appendChild(link); // Required for FF

                link.click();

                this.setState({
                  isLoading: false,
                });
              })
              .catch((e) => {
                console.log("ERROR", e);
                this.setState({
                  isLoading: false,
                });
                notification["error"]({
                  message: "EXPORT DATA",
                  description:
                    typeof e === "string" ? e : "Something went wrong",
                });
                throw e;
              });
          }
        );

        return;
      }

      this.setState(
        {
          isLoading: true,
        },
        () => {
          getAllPaidTransactions(
            authToken,
            loginUserData.id,
            0,
            100000000000000000
          )
            .then((responseData) => {
              let csvArray = [];

              csvArray.push([
                "Amount",
                "Type",
                "Transaction At",
                "Invoice Number",
                "PO Number",
                "Transaction Number",
              ]);

              responseData.data.map((dataItem) => {
                csvArray.push([
                  dataItem.amount ? `$ ${dataItem.amount}` : "NA",
                  dataItem.type
                    ? dataItem.type === "credit"
                      ? "Credit"
                      : "Debit"
                    : "NA",
                  dataItem.transaction_at
                    ? `"${this.dateFormat(dataItem.transaction_at)}"`
                    : "NA",
                  dataItem.invoice_number ? dataItem.invoice_number : "NA",
                  dataItem.po_number ? dataItem.po_number : "NA",
                  dataItem.transaction_number
                    ? dataItem.transaction_number
                    : "NA",
                ]);
              });

              const rows = csvArray;
              let csvName = "AllOutstandingAmount.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((e) => {
              console.log("ERROR", e);
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "EXPORT DATA",
                description: typeof e === "string" ? e : "Something went wrong",
              });
              throw e;
            });
        }
      );
    } catch (e) {
      notification["error"]({
        message: "EXPORT DATA",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      console.log("error in generateCsv function", e);
    }
  };

  filterVendorAmountData = async (startDate, endDate, start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVendorAmountData,
        setListVendorAmountDataTotal,
        loginUserData,
      } = this.props;

      this.setState({
        isLoading: true,
      });

      let vendorAmountData = await getAllFilterPaidTransactions(
        authToken,
        loginUserData.id,
        startDate,
        endDate,
        start,
        end
      );

      if (vendorAmountData.status == 200) {
        //set user Data
        await setListVendorAmountData(vendorAmountData.data);
        await setListVendorAmountDataTotal(vendorAmountData.total);

        this.setState({
          isLoading: false,
          data: vendorAmountData.data,
        });
      } else if (vendorAmountData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw vendorAmountData && vendorAmountData.message
          ? vendorAmountData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list FILTER Vendor Amount Data printed here", e);
      notification["error"]({
        message: "VENDOR",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listVendorAmountData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVendorAmountData,
        setListVendorAmountDataTotal,
        loginUserData,
      } = this.props;

      this.setState({
        isLoading: true,
      });

      let vendorAmountData = await getAllPaidTransactions(
        authToken,
        loginUserData.id,
        start,
        end
      );

      if (vendorAmountData.status == 200) {
        //set user Data
        await setListVendorAmountData(vendorAmountData.data);
        await setListVendorAmountDataTotal(vendorAmountData.total);

        this.setState({
          isLoading: false,
          data: vendorAmountData.data,
        });
      } else if (vendorAmountData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw vendorAmountData && vendorAmountData.message
          ? vendorAmountData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list VENDOR AMOUNT Data printed here", e);
      notification["error"]({
        message: "VENDOR",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 60,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Amount">
                <div>{item.amount ? `$ ${item.amount}` : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Type">
              <div>
                {item.type
                  ? item.type === "credit"
                    ? "Credit"
                    : "Debit"
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Transaction At",
      key: "Transaction At",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Transaction At">
              <div>
                {item.transaction_at
                  ? this.dateFormat(item.transaction_at)
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   title: "Order Code",
    //   key: "Order Code",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         <strong>
    //           <Tooltip placement="bottom" title="Order Code">
    //             <div>{item.order_code ? item.order_code : "-"}</div>
    //           </Tooltip>
    //         </strong>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Invoice Number",
      key: "invoice_number",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Invoice Number">
              <div>{item.invoice_number ? item.invoice_number : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "PO Number",
      key: "po_number",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="PO Number">
              <div>{item.po_number ? item.po_number : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Transaction Number",
      key: "transaction_number",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Transaction Number">
              <div>
                {item.transaction_number ? item.transaction_number : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedVendorAmount(pagination)
    );
  }

  handleSelectVouchertype = (value, option) => {
    const { pagination } = this.state;
    this.setState(
      {
        selectedVoucherId: value,
        isFilterApply: true,
      },
      () => {
        this.getLimitedVendorAmount(pagination);
      }
    );
  };

  render() {
    const { isLoading, searchText } = this.state;
    const {
      listVendorAmountData,
      listVendorAmountDataTotal,
      searchLoader,
      authToken,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <div
          style={{
            float: "left",
            cursor: "pointer",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div
            onClick={() => this.generateCsv()}
            style={{
              width: "100px",
              backgroundColor: "#5C6AC4",
              color: "white",
              padding: "7px 0px",
              textAlign: "center",
              borderRadius: "0px",
              marginLeft: "0px",
              cursor: "pointer",
              marginBottom: "10px",
            }}
          >
            Export
          </div>
        </div>

        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            display: "inline-block",
          }}
        >
          <div style={{ float: "left", width: "50%" }}>
            <RangePicker
              format="DD-MM-YYYY"
              onChange={this.onChangeDate}
              ranges={{
                Today: [
                  moment().startOf("day"),
                  moment().add(1, "days").endOf("day"),
                ],
                Yesterday: [
                  moment().subtract(1, "days").startOf("day"),
                  moment().endOf("day"),
                  // moment()
                  //   .subtract(1, "days")
                  //   .endOf("day")
                ],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Financial Year": this.financialYear(0),
                "Previous Financial Year": this.previousFinancialYear(),
              }}
            />
          </div>
          <div style={{ float: "right" }}>
            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={this.onFilter}
            >
              Filter
            </Button>

            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
                marginLeft: "15px",
              }}
              onClick={() => {
                this.resetFilter();
              }}
            >
              Reset
            </Button>
          </div>
        </div>

        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listVendorAmountData}
          pagination={{
            total: listVendorAmountDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listVendorAmountData: state.vendor_amount.listVendorAmountData,
  listVendorAmountDataTotal: state.vendor_amount.listVendorAmountDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVendorAmountData: (listVendorAmountData) => {
      dispatch({
        type: "SET_LIST_VENDOR_AMOUNT_DATA",
        listVendorAmountData: listVendorAmountData,
      });
    },
    setListVendorAmountDataTotal: (listVendorAmountDataTotal) => {
      dispatch({
        type: "SET_LIST_VENDOR_AMOUNT_DATA_TOTAL",
        listVendorAmountDataTotal: listVendorAmountDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterVendorAmountRecordsTable));
