import { callApiToServer } from "../callApi";

export async function editVendor(authtoken, vendorObject, vendorId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      vendorId: vendorId,
      corppass_id: vendorObject.corppass_id,
      categories: vendorObject.categories,
      nric_fin: vendorObject.nric_fin,
      partial_nric_fin: vendorObject.partial_nric_fin,
      principal_name: vendorObject.principal_name,
      sex: vendorObject.sex,
      race: vendorObject.race,
      dob: vendorObject.dob,
      nationality: vendorObject.nationality,
      mobile_number: vendorObject.mobile_number,
      email: vendorObject.email,
      address: vendorObject.address,
      type_of_hdb: vendorObject.type_of_hdb,
      type_of_housing: vendorObject.type_of_housing,
      maritial_status: vendorObject.maritial_status,
      child_births: vendorObject.child_births,
      entity_name: vendorObject.entity_name,
      primary_activity: vendorObject.primary_activity,
      secondary_activity: vendorObject.secondary_activity,
    });

    let endUrl = "v1/auth/vendor";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
