import { callApiToServer } from "../callApi";

export async function filterVoucherInventory(
  authtoken,
  type,
  skipNumber,
  limitNumber,
  startDate,
  endDate,
  voucherId,
  searchText
) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      voucherId: voucherId,
      searchText: searchText,
    });

    let endUrl =
      "v1/auth/filter-voucher-inventory-from-voucher-comman/" +
      type +
      "/" +
      startDate +
      "/" +
      endDate +
      "/" +
      skipNumber +
      "/" +
      limitNumber;

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
