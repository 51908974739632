import { Modal, Button, Divider } from "antd";
import * as React from "react";
import {
  Layout,
  Input,
  TimePicker,
  Select,
  notification,
  DatePicker,
} from "antd";
import { Formik, FieldProps, Field } from "formik";

const { Content } = Layout;

export class RemoveAmount extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
    };
  }

  validateForm = (values) => {
    const { startDate, endDate } = this.state;
    const errors = {};

    if (!values.amount) {
      errors.amount = "Amount is required";
    }

    if (!(values.amount >= 0)) {
      errors.amount = "Amount can not be negative";
    }

    return errors;
  };

  handleSubmit = (values, action) => {
    try {
      this.props.onSubmit(values, this.props.data.id);
      this.props.handleCancel();
      this.setState({
        visible: false,
      });
    } catch (e) {
      console.log("!!!!erro", e);
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                amount: 0,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Amount ( $ )
                        <Input
                          id="amount"
                          type={"number"}
                          placeholder="Amount"
                          value={values.amount}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.handleSubmit(values);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.amount && touched.amount ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.amount}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Remove
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
