import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllUser } from "../../Graphs/User/listUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteUser } from "../../Graphs/User/deleteUser";
import { changeStatus } from "../../Graphs/User/ChangeStatus";
import { editUser } from "../../Graphs/User/editUser";
import { searchUser } from "../../Graphs/User/searchUser";
import { EditModal } from "./EditModal";
import { DeleteWorkerModal } from "./DeleteWorkerModal";
import { deleteSocialWorker } from "../../Graphs/SocialWorker/deleteSocialWorker";
var moment = require("moment");

class UserRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      deleteSelectedData: null,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedUser = this.getLimitedUser.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      userSelectedData: null,
      deleteSelectedData: null,
    });
  }

  modalRef;
  deleteModalRef;

  async getLimitedUser(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchUserData(start, end);
        } else {
          //call normal user Data
          this.listUserData(start, end);
        }
      }
    );
  }

  listSearchUserData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListUserData,
        setListUserDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let userData = await searchUser(
        authToken,
        searchText,
        start,
        end,
        "social_worker"
      );

      if (userData.status === 200) {
        //set user Data
        await setListUserData(userData.data);
        await setListUserDataTotal(userData.total);

        this.setState({
          isLoading: false,
          data: userData.data,
        });
      } else if (userData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listUserData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListUserData,
        setListUserDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let userData = await getAllUser(authToken, start, end, "social_worker");

      if (userData.status === 200) {
        //set user Data
        await setListUserData(userData.data);
        await setListUserDataTotal(userData.total);

        this.setState({
          isLoading: false,
          data: userData.data,
        });
      } else if (userData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        userSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      userSelectedData: null,
    });
  }

  showDeleteModal(item) {
    this.setState(
      {
        deleteSelectedData: item,
      },
      () => {
        this.deleteModalRef.show();
      }
    );
  }

  handleDeleteModalDeleteclose() {
    this.setState({
      deleteSelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "SingpassId",
      key: "SingpassId",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Singpass Id">
                <div>{item.singpass_id ? item.singpass_id : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "Name",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Name">
                <div>
                  {item.user_metadata ? item.user_metadata.principal_name : "-"}
                </div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Organization Name",
      key: "Organization Name",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Organization Name">
                <div>
                  {item.workerUser &&
                  item.workerUser.length > 0 &&
                  item.workerUser[0].charityUser &&
                  item.workerUser[0].charityUser.user_metadata
                    ? item.workerUser[0].charityUser.user_metadata.entity_name
                    : "-"}
                </div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "Email",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Email">
              <div>
                {item.user_metadata
                  ? item.user_metadata.email
                    ? item.user_metadata.email
                    : "-"
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Mobile Number",
      key: "Mobile Number",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Mobile Number">
              <div>
                {item.user_metadata
                  ? item.user_metadata.mobile_number
                    ? item.user_metadata.mobile_number
                    : "-"
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "isActive",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="User Activation">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left" }}>
              <Tooltip placement="bottom" title="Edit">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{ float: "left", marginLeft: "10px" }}
            >
              <Tooltip placement="bottom" title="Delete">
                <Popconfirm
                  onConfirm={() => this.showDeleteModal(item)}
                  title="Are you sure you want to delete this Supervisor?"
                >
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  deleteUser = async (deleteWorkerObject, workerId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      // let deleteData = await deleteUser(authToken, item.id);
      let deleteData = await deleteSocialWorker(
        authToken,
        deleteWorkerObject,
        workerId
      );

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete User",
          description: "Successfully deleted",
        });

        if (searchText != null && searchText !== "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listUserData(startIndex, limitNumber);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete User",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Change User Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listUserData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Vendor Status",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (userObject, userId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editUser(authToken, userObject, userId);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Edit User Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listUserData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Edit User",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedUser(pagination)
    );
  }

  render() {
    const { isLoading, data } = this.state;
    const { listUserData, listUserDataTotal, searchLoader } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listUserData}
          pagination={{
            total: listUserDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.deleteSelectedData ? (
          <DeleteWorkerModal
            refx={(e) => (this.deleteModalRef = e)}
            onClose={this.handleDeleteModalDeleteclose.bind(this)}
            deleteSelectedData={this.state.deleteSelectedData}
            authToken={this.props.authToken}
            onSubmit={this.deleteUser}
          />
        ) : null}
        {this.state.userSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            userSelectedData={this.state.userSelectedData}
            onSubmit={this.editSubmit}
            authToken={this.props.authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listUserData: state.user.listUserData,
  listUserDataTotal: state.user.listUserDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserData: (listUserData) => {
      dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: listUserData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserRecordsTable));
