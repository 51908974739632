import React, { Component } from "react";
import AddSessionTopic from "./AddSessionTopic";
import { Select, Button } from "antd";

const Option = Select.Option;

class DragTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      courseData: [],
      sessionList: [],
    };
  }

  componentDidMount() {}

  addSessionTopic = (data) => {
    const { courseData, sessionList } = this.state;
    let dummyCourseData = courseData;
    let dummySesionList = sessionList;

    let filterData = courseData.filter((subData, subIndex) => {
      if (data.type === subData.type && data.name === subData.name) {
        return true;
      }
    });

    if (filterData && filterData.length > 0) {
      return;
    }

    if (data.sessionId) {
      let filterSessionData = courseData.filter((subData, subIndex) => {
        if (data.sessionId === subData.name) {
          return true;
        }
      });

      if (filterSessionData && filterSessionData.length > 0) {
        let dummyFiltersessionData = filterSessionData[0];
        let dummyTopics = dummyFiltersessionData.topics;

        dummyTopics.push({
          type: data.type,
          isModule: data.isModule,
          isTopic: data.isTopic,
          name: data.name,
          sessionId: data.sessionId,
        });

        let dummyCourseData = [];
        courseData.map((subData, subIndex) => {
          if (data.sessionId === subData.name) {
            dummyCourseData.push({ ...subData, topics: dummyTopics });
          } else {
            dummyCourseData.push(subData);
          }
        });

        this.setState({
          courseData: dummyCourseData,
        });
      }

      return;
    }

    dummyCourseData.push({
      type: data.type,
      isModule: data.isModule,
      isTopic: data.isTopic,
      name: data.name,
      sessionId: data.sessionId,
      topics: [],
    });

    if (!data.isTopic) {
      dummySesionList.push(
        <Option key={data.name} value={data.name}>
          {data.name}
        </Option>
      );
    }

    this.setState({
      sessionList: dummySesionList,
      courseData: dummyCourseData,
    });
  };

  mainUp = (data, index) => {
    const { courseData } = this.state;
    let dummyCourseData = courseData;

    if (index === 0) {
      return;
    }

    var element = dummyCourseData[index];
    dummyCourseData.splice(index, 1);
    dummyCourseData.splice(index - 1, 0, element);

    this.setState({
      courseData: dummyCourseData,
    });
  };

  mainDown = (data, index) => {
    const { courseData } = this.state;
    let dummyCourseData = courseData;

    if (index === courseData.length - 1) {
      return;
    }

    var element = dummyCourseData[index];
    dummyCourseData.splice(index, 1);
    dummyCourseData.splice(index + 1, 0, element);

    this.setState({
      courseData: dummyCourseData,
    });
  };

  subUp = (data, index, sessionData) => {
    const { courseData } = this.state;

    let filterData = courseData.filter((subData, subIndex) => {
      if (data.sessionId === subData.name) {
        return true;
      }
    });

    if (!(filterData && filterData.length > 0)) {
      return;
    }

    if (index === 0) {
      return;
    }
    let dummyTopicsArray = filterData[0]["topics"];

    var element = dummyTopicsArray[index];
    dummyTopicsArray.splice(index, 1);
    dummyTopicsArray.splice(index - 1, 0, element);

    let dummyCourseData = [];
    courseData.map((subData, subIndex) => {
      if (data.sessionId === subData.name) {
        dummyCourseData.push({ ...subData, topics: dummyTopicsArray });
      } else {
        dummyCourseData.push(subData);
      }
    });

    this.setState({
      courseData: dummyCourseData,
    });
  };

  subDown = (data, index, sessionData) => {
    const { courseData } = this.state;

    let filterData = courseData.filter((subData, subIndex) => {
      if (data.sessionId === subData.name) {
        return true;
      }
    });

    if (!(filterData && filterData.length > 0)) {
      return;
    }

    let dummyTopicsArray = filterData[0]["topics"];
    if (index === dummyTopicsArray.length - 1) {
      return;
    }

    var element = dummyTopicsArray[index];
    dummyTopicsArray.splice(index, 1);
    dummyTopicsArray.splice(index + 1, 0, element);

    let dummyCourseData = [];
    courseData.map((subData, subIndex) => {
      if (data.sessionId === subData.name) {
        dummyCourseData.push({ ...subData, topics: dummyTopicsArray });
      } else {
        dummyCourseData.push(subData);
      }
    });

    this.setState({
      courseData: dummyCourseData,
    });
  };

  addSessionTopicSubmit = () => {
    const { courseData, sessionList } = this.state;
    console.log("!!!!!!courseData", courseData, sessionList);
  };

  render() {
    const { courseData, sessionList } = this.state;

    return (
      <div>
        <div
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "24px",
          }}
        >
          Course Example
        </div>
        {courseData &&
          courseData.map((data, index) => {
            if (data.type === "session") {
              return (
                <div
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "15px",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                      display: "inline-block",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "5px",
                        float: "left",
                        marginRight: "20px",
                        width: "40px",
                        display: "inline-block",
                      }}
                    >
                      <div
                        style={{
                          float: "left",
                          width: "100%",
                          marginBottom: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => this.mainUp(data, index)}
                      >
                        {"Up"}
                      </div>
                      <div
                        style={{
                          float: "left",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => this.mainDown(data, index)}
                      >
                        {"Down"}
                      </div>
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "5px",
                        float: "left",
                      }}
                    >
                      {data.name}
                    </div>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    {data.topics &&
                      data.topics.map((subData, subIndex) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "white",
                              width: "100%",
                              display: "inline-block",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                marginBottom: "5px",
                                float: "left",
                                marginRight: "20px",
                                width: "40px",
                                display: "inline-block",
                              }}
                            >
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                  marginBottom: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.subUp(subData, subIndex, data)
                                }
                              >
                                {"Up"}
                              </div>
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.subDown(subData, subIndex, data)
                                }
                              >
                                {"Down"}
                              </div>
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                marginBottom: "5px",
                                float: "left",
                              }}
                            >
                              {subData.name}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            }

            return (
              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  display: "inline-block",
                  marginBottom: "15px",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    display: "inline-block",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "5px",
                      float: "left",
                      marginRight: "20px",
                      width: "40px",
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{
                        float: "left",
                        width: "100%",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => this.mainUp(data, index)}
                    >
                      {"Up"}
                    </div>
                    <div
                      style={{
                        float: "left",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => this.mainDown(data, index)}
                    >
                      {"Down"}
                    </div>
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "5px",
                      float: "left",
                    }}
                  >
                    {data.name}
                  </div>
                </div>
              </div>
            );
          })}
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <Button
            style={{
              marginTop: "19px",
              background: "#5C6AC4",
              color: "#fff",
            }}
            onClick={() => this.addSessionTopicSubmit()}
          >
            Submit
          </Button>
        </div>
        <div
          style={{
            marginTop: "10px",
            fontWeight: "bold",
            fontSize: "24px",
          }}
        >
          Add Session Topic
          <div style={{ marginTop: "5px" }}>
            <AddSessionTopic
              sessionList={sessionList}
              addSessionTopic={(data) => this.addSessionTopic(data)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DragTest;
