import { callGetApiToServer } from "../callApi";

export async function getAllAvailableCreditsCaetgory(authtoken, needyFamilyId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl =
      "v1/auth/beneficiary-available-credits-category/" + needyFamilyId;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
