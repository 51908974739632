import { Table, Tooltip, notification, Spin } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllVoucherTransactionList } from "../../Graphs/SocialWorker/listVoucherTransactionList";
import { withRouter } from "react-router";
import { connect } from "react-redux";
var moment = require("moment");

class TransactionListData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.listVoucherTransactionData(0, 5);
  }

  getLimitedVoucherTransaction = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listVoucherTransactionData(start, end);
      }
    );
  };

  listVoucherTransactionData = async (start, end) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });

      let voucherTransData = await getAllVoucherTransactionList(
        authToken,
        this.props.data.id,
        start,
        end
      );
      console.log("!!!!!!!!!!!!voucherTransData", voucherTransData);

      if (voucherTransData.status == 200) {
        this.setState({
          isLoading: false,
          data: voucherTransData.data,
          dataTotal: voucherTransData.total,
        });
      } else if (voucherTransData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherTransData && voucherTransData.message
          ? voucherTransData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list voucher Data printed here", e);
      notification["error"]({
        message: "VOUCHER TRANSACTION",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedVoucherTransaction(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Voucher Name",
      key: "voucher_name",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <Tooltip placement="bottom" title="Voucher Name">
                    <div>{item.name ? item.name : "-"}</div>
                  </Tooltip>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Amount",
      key: "Amount",
      dataIndex: "Amount",
      render: (text, item, index) => {
        return (
          <div>
            {item.amount ? (
              <Tooltip placement="bottom" title="Amount">
                {item.amount}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Beneficiary Name",
      key: "beneficiary_name",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                {" "}
                <Tooltip placement="bottom" title="Beneficiary Name">
                  <div>
                    {item.VoucherTransUserData
                      ? item.VoucherTransUserData.first_name
                        ? item.VoucherTransUserData.first_name
                        : "-"
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Transaction Date",
      key: "transaction_at",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Transaction Date">
                  <div>
                    {item.transaction_at
                      ? this.dateFormat(item.transaction_at)
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Order Code",
      key: "order_code",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Order Code">
                  <div>{item.order_code ? item.order_code : "-"}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  render() {
    const { isLoading, data, dataTotal } = this.state;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          // scroll={{ x: 1000 }}
          size={"small"}
          dataSource={data}
          pagination={{
            total: dataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "5", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listVoucherData: state.voucher.listVoucherData,
  listVoucherDataTotal: state.voucher.listVoucherDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVoucherData: (listVoucherData) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA",
        listVoucherData: listVoucherData,
      });
    },
    setListVoucherDataTotal: (listVoucherDataTotal) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA_TOTAL",
        listVoucherDataTotal: listVoucherDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TransactionListData));
