import {
  SET_LIST_STATIC_PAGE_DATA,
  SET_LIST_STATIC_PAGE_DATA_TOTAL,
} from "../constant/actionTypes";

const initial_state = {
  listStaticPageData: null,
  listStaticPageDataTotal: 0,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_LIST_STATIC_PAGE_DATA:
      return {
        ...state,
        loading: false,
        listStaticPageData: action.listStaticPageData,
      };

    case SET_LIST_STATIC_PAGE_DATA_TOTAL:
      return {
        ...state,
        loading: false,
        listStaticPageDataTotal: action.listStaticPageDataTotal,
      };

    default:
      return { ...state };
  }
};
