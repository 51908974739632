import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllCharityProgram } from "../../Graphs/CharityProgram/listCharityProgram";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import CharityProgramRecordsTable from "./CharityProgramRecordsTable";

class CharityProgramAvailable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charityList: [],
      charityTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("CHARITY_PROGRAM");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listCharityProgramData();
    } catch (e) {
      notification["error"]({
        message: "Charity Program",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listCharityProgramData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListCharityProgramData,
      setListCharityProgramDataTotal,
    } = this.props;
    try {
      this.setState({
        isLoading: true,
      });
      let charityData = await getAllCharityProgram(authToken, 0, 10);

      if (charityData.status === 200) {
        //set category data
        await setListCharityProgramData(charityData.data);
        await setListCharityProgramDataTotal(charityData.total);

        this.setState({
          charityList: charityData.data,
          charityTotal: charityData.total,
        });
      } else if (charityData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, charityList, isLoading, charityTotal } = this.state;

    return (
      <Fragment>
        <Title
          parent="Programs Available Credits"
          title="Programs Available Credits"
        />

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <CharityProgramRecordsTable
                data={charityList}
                primaryColor={primaryColor}
                charityTotal={charityTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCharityProgramData: (listCharityProgramData) => {
      dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA",
        listCharityProgramData: listCharityProgramData,
      });
    },
    setListCharityProgramDataTotal: (listCharityProgramDataTotal) => {
      dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA_TOTAL",
        listCharityProgramDataTotal: listCharityProgramDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CharityProgramAvailable));
