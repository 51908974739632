import { Modal, Button, Divider, notification } from "antd";
import * as React from "react";
import { Layout, Input, Select, Spin, DatePicker } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import { getAllSocialWorkerWithoutPagination } from "../../Graphs/SocialWorker/listAllSocialWorker";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class AddSocialWorker extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: true,
      errors: {},
    };
  }

  input;
  allWorkerType = [];
  selectedWorkerId = "";

  async componentDidMount() {
    const { authToken } = this.props;

    this.setState({
      isLoading: true,
    });

    let workerData = await getAllSocialWorkerWithoutPagination(authToken);
    this.selectedWorkerId =
      this.props.data.familyUserForWorker &&
      this.props.data.familyUserForWorker.length > 0 &&
      this.props.data.familyUserForWorker[0]["workerUserForFamily"] &&
      this.props.data.familyUserForWorker[0]["workerUserForFamily"]["id"];
    console.log("!!!!selectedWorkerId", this.selectedWorkerId);

    workerData &&
      workerData.data &&
      workerData.data.length > 0 &&
      la.map(workerData.data, (subData, subIndex) => {
        // if (!subData.user_metadata) {
        //   this.allWorkerType.push(
        //     <Option key={subData.id} value={subData.id}>
        //       {subData.user_metadata
        //         ? subData.user_metadata.principal_name
        //         : "Social Worker"}
        //     </Option>
        //   );
        // }
        this.allWorkerType.push(
          <Option key={subData.id} value={subData.id}>
            {subData.user_metadata
              ? subData.user_metadata.principal_name
                ? subData.user_metadata.principal_name
                : "Social Worker"
              : "Social Worker"}
          </Option>
        );
      });

    this.setState({
      isLoading: false,
    });
  }

  handleSelectWorkertype(value, option) {
    this.selectedWorkerId = value;
  }

  validateForm = (values) => {
    const errors = {};
    const { startDate, endDate } = this.state;

    if (!this.selectedWorkerId) {
      errors.worker_user_id = "Supervisor is required";
    }

    return errors;
  };

  handleSubmit = (values, action) => {
    const { errors } = this.state;
    let dummyErrors = { ...errors };

    values.worker_user_id = this.selectedWorkerId;
    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                worker_user_id: this.props.data.worker_user_id,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return isLoading ? (
                  <div style={{ marginLeft: "20px" }}>
                    <Spin
                      size="large"
                      style={{ width: "100%", margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Supervisor
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            value={this.selectedWorkerId}
                            onSelect={(value) =>
                              this.handleSelectWorkertype(value)
                            }
                            placeholder="Select Supervisor"
                            showSearch
                            onChange={(value) => {
                              this.handleSelectWorkertype(value);
                              setFieldValue("worker_user_id", value);
                            }}
                            onBlur={() =>
                              setFieldTouched("worker_user_id", true)
                            }
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allWorkerType}
                          </Select>
                          {errors.worker_user_id ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.worker_user_id}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <Button
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Assign
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
