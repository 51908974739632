import { Table, Tooltip, notification, Spin, Button } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllAdminCharityProgramTransaction } from "../../Graphs/CharityProgram/listProgramTransaction";
import { withRouter } from "react-router";
import { connect } from "react-redux";
var moment = require("moment");

class TransactionRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.listCharityProgramTransactionData(0, 5);
  }

  getLimitedProgramTransaction = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listCharityProgramTransactionData(start, end);
      }
    );
  };

  listCharityProgramTransactionData = async (start, end) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });

      let programData = await getAllAdminCharityProgramTransaction(
        authToken,
        this.props.data.id,
        start,
        end
      );

      if (programData.status == 200) {
        this.setState({
          isLoading: false,
          data: programData.data,
          dataTotal: programData.total,
        });
      } else if (programData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw programData && programData.message
          ? programData.message
          : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "CHARITY_PROGRAM_TRANSACTION",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedProgramTransaction(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 80,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Amount">
                <div>{item.amount ? `$ ${item.amount}` : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Category",
      key: "Category",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Category">
                <div>{item.category ? item.category.name : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Supervisor",
      key: "Supervisor",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Supervisor">
                <div>
                  {item.receiverBalanceTransactionData
                    ? item.receiverBalanceTransactionData.user_metadata &&
                      item.receiverBalanceTransactionData.user_metadata
                        .principal_name
                      ? item.receiverBalanceTransactionData.user_metadata
                          .principal_name
                      : "Supervisor"
                    : "-"}
                </div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Type">
              <div>
                {item.type
                  ? item.receiverBalanceTransactionData
                    ? item.type == "credit"
                      ? "debit"
                      : "credit"
                    : item.type
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Entity",
      key: "Entity",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Entity">
              <div>{item.entity ? item.entity : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         <Tooltip placement="bottom" title="Status">
    //           <div>{item.status ? item.status : "-"}</div>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Order Code",
    //   key: "order_code",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         <Tooltip placement="bottom" title="Order Code">
    //           <div>{item.order_code ? item.order_code : "-"}</div>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
  ];

  generateCsv = async () => {
    try {
      const { authToken } = this.props;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getAllAdminCharityProgramTransaction(
            authToken,
            this.props.data.id,
            0,
            100000000000000000
          )
            .then((responseData) => {
              let csvArray = [];

              csvArray.push([
                "Amount",
                "Category",
                "Supervisor",
                "Type",
                "Entity",
              ]);

              responseData.data.map((dataItem) => {
                csvArray.push([
                  dataItem.amount ? dataItem.amount : "NA",
                  dataItem.category ? dataItem.category.name : "NA",
                  dataItem.receiverBalanceTransactionData
                    ? dataItem.receiverBalanceTransactionData.user_metadata &&
                      dataItem.receiverBalanceTransactionData.user_metadata
                        .principal_name
                      ? dataItem.receiverBalanceTransactionData.user_metadata
                          .principal_name
                      : "Supervisor"
                    : "NA",
                  dataItem.type
                    ? dataItem.receiverBalanceTransactionData
                      ? dataItem.type == "credit"
                        ? "debit"
                        : "credit"
                      : dataItem.type
                    : "NA",
                  dataItem.entity ? dataItem.entity : "NA",
                ]);
              });

              const rows = csvArray;
              let csvName = "AllTransactions.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((e) => {
              console.log("ERROR", e);
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "EXPORT DATA",
                description: typeof e === "string" ? e : "Something went wrong",
              });
              throw e;
            });
        }
      );
    } catch (e) {
      notification["error"]({
        message: "EXPORT DATA",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      console.log("error in generateCsv function", e);
    }
  };

  render() {
    const { isLoading, data, dataTotal } = this.state;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
      </div>
    ) : (
      <>
        <div
          style={{
            marginTop: "0px",
            marginBottom: "20px",
            width: "100%",
            display: "inline-block",
          }}
        >
          <div style={{ float: "left", width: "50%" }}>
            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
                marginLeft: "15px",
              }}
              onClick={() => {
                this.generateCsv();
              }}
            >
              Export
            </Button>
          </div>
        </div>
        <div style={{ "overflow-x": "auto" }}>
          <Table
            loading={this.state.isLoading}
            rowKey={(record) => record._id}
            columns={this.columns}
            size={"small"}
            dataSource={data}
            pagination={{
              total: dataTotal,
              showSizeChanger: true,
              pageSize: this.state.pagination.pageSize,
              current: this.state.pagination.current,
              pageSizeOptions: ["1", "5", "10", "25", "50", "100"],
              onChange: (e) => {
                console.log("onChanges pagination");
                // e - 1
                //   ? (this.counter = this.tempCounter =
                //       (e - 1) * this.state.pagination.pageSize)
                //   : ((this.counter = 0), (this.tempCounter = 0));
                if (e - 1) {
                  this.counter = this.tempCounter =
                    (e - 1) * this.state.pagination.pageSize;
                  return;
                }

                this.counter = 0;
                this.tempCounter = 0;
              },
            }}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TransactionRecordsTable));
