import React, { Fragment, useState, useEffect } from "react";
import man from "../../../assets/images/dashboard/boy-2.png";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { useSelector } from "react-redux";
import ProfileComponent from "./ProfileComponent";

const UserPanel = ({ adminName }) => {
  const profileImageUrl = useSelector((content) =>
    content.auth.loginUserData
      ? content.auth.loginUserData.profile_image_url
      : null
  );
  const authToken = useSelector((content) => content.auth.authToken);

  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div>
          <img
            className="img-60 rounded-circle lazyloaded blur-up"
            src={profileImageUrl ? profileImageUrl : man}
            alt="#"
            style={{ height: "60px", objectFit: "contain" }}
          />
          <ProfileComponent authToken={authToken} />
        </div>
        <h6 className="mt-3 f-14">{adminName}</h6>
      </div>
    </Fragment>
  );
};

export default UserPanel;
