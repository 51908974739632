import { Table, Tooltip, notification, Spin, Button } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllAdminCharityProgram } from "../../Graphs/CharityProgram/listAdminCharityProgram";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  PlusCircleOutlined,
  EyeOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { AddAmountModal } from "./AddAmountModal";
import { TransactionModal } from "./TransactionModal";
import { addAmount } from "../../Graphs/CharityProgram/addAmount";
import { removeAmount } from "../../Graphs/CharityProgram/removeAmount";
import { RemoveAmountModal } from "./RemoveAmountModal";
var moment = require("moment");

class ProgramData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.listCharityProgramData(0, 5);
  }

  getLimitedProgram = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listCharityProgramData(start, end);
      }
    );
  };

  listCharityProgramData = async (start, end) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });

      let programData = await getAllAdminCharityProgram(
        authToken,
        this.props.data.id,
        start,
        end
      );

      if (programData.status == 200) {
        this.setState({
          isLoading: false,
          data: programData.data,
          dataTotal: programData.total,
        });
      } else if (programData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw programData && programData.message
          ? programData.message
          : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "CHARITY_PROGRAM",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedProgram(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  modalRef;
  modalTransRef;
  modalRemoveRef;

  showModal(item) {
    this.setState(
      {
        programData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      programData: null,
    });
  }

  showRemoveModal(item) {
    this.setState(
      {
        programRemoveData: item,
        // isOpen: true
      },
      () => {
        this.modalRemoveRef.show();
      }
    );
  }

  handleDetailsRemoveModalclose() {
    this.setState({
      programRemoveData: null,
    });
  }

  showTransModal(item) {
    this.setState(
      {
        programTransData: item,
        // isOpen: true
      },
      () => {
        this.modalTransRef.show();
      }
    );
  }

  handleDetailsTransModalclose() {
    this.setState({
      programTransData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 80,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Organization Program",
      key: "Organization Program",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Organization Program">
                <div>{item.title ? item.title : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Short Description",
      key: "short_description",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Short Description">
              <div>{item.short_description ? item.short_description : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Cause",
      key: "CauseCategories",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Cause Categories">
              <div>{item.cause_categories ? item.cause_categories : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Categories",
      key: "Categories",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Categories">
              <div>{item.categories ? item.categories : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Total Donation Received",
      key: "Total Donation Received",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Total Donation Received">
              {/* <div>
                {item.total_donation && item.total_donation > 0
                  ? `$ ${String(item.total_donation)}`
                  : "-"}
              </div> */}
              <div>
                {item.total_donation && item.total_donation > 0
                  ? `$ ${String(
                      (
                        parseFloat(item.total_donation) -
                        parseFloat(item.total_donation_admin)
                      ).toLocaleString()
                    )}`
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Unassigned Credits",
      key: "Unassigned Credits",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Unassigned Credits">
              <div>
                {item.total_donation_left && item.total_donation_left > 0
                  ? `$ ${String(item.total_donation_left.toLocaleString())}`
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Add Credits">
                <Button
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Remove Credits">
                <Button
                  shape="circle"
                  icon={<MinusCircleOutlined />}
                  onClick={() => {
                    this.showRemoveModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Show Transactions">
                <Button
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showTransModal(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  addAmountSubmit = async (amountObject, programId) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await addAmount(authToken, amountObject, programId);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Add Amount",
          description: "Successfully added",
        });

        this.listCharityProgramData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Add Amount",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  removeAmountSubmit = async (amountObject, programId) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await removeAmount(authToken, amountObject, programId);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Remove Amount",
          description: "Successfully added",
        });

        this.listCharityProgramData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Remove Amount",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { isLoading, data, dataTotal } = this.state;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          dataSource={data}
          pagination={{
            total: dataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "5", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.programData ? (
          <AddAmountModal
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            programData={this.state.programData}
            onSubmit={this.addAmountSubmit}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.programTransData ? (
          <TransactionModal
            refx={(e) => (this.modalTransRef = e)}
            onClose={this.handleDetailsTransModalclose.bind(this)}
            programTransData={this.state.programTransData}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.programRemoveData ? (
          <RemoveAmountModal
            refx={(e) => (this.modalRemoveRef = e)}
            onClose={this.handleDetailsRemoveModalclose.bind(this)}
            programRemoveData={this.state.programRemoveData}
            onSubmit={this.removeAmountSubmit}
            authToken={this.props.authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProgramData));
