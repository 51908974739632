import React, { Component } from "react";
import { Checkbox, Input, Select, Button } from "antd";
const Option = Select.Option;

class AddSessionTopic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "session",
      isModule: false,
      isTopic: false,
      name: "",
      sessionId: "",
    };
  }

  onIsModuleChange = (checked) => {
    this.setState({
      isModule: checked,
    });
  };

  onIsTopicChange = (checked) => {
    this.setState({
      isTopic: checked,
    });
  };

  onChangeName = (name) => {
    this.setState({
      name,
    });
  };

  selectSession = (sessionId) => {
    this.setState({
      sessionId: sessionId,
    });
  };

  addSubmit = () => {
    const { isModule, isTopic, name, sessionId } = this.state;
    const { addSessionTopic } = this.props;
    if (!name) {
      return;
    }
    addSessionTopic({
      isModule: isModule,
      isTopic: isTopic,
      name: name,
      sessionId: sessionId,
      type: isTopic ? "topic" : "session",
    });
    this.setState({
      type: "session",
      isModule: false,
      isTopic: false,
      name: "",
      sessionId: "",
    });
  };

  render() {
    const { isModule, isTopic, name } = this.state;
    const { sessionList } = this.props;

    return (
      <div
        style={{ width: "100%", marginBottom: "10px", display: "inline-block" }}
      >
        <div style={{ width: "100%", marginBottom: "10px" }}>
          <Input
            placeholder={"Name"}
            value={name}
            onChange={(e) => {
              this.onChangeName(e.target.value);
            }}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "10px" }}>
          <Checkbox
            checked={isTopic}
            onChange={(e) => this.onIsTopicChange(e.target.checked)}
          >
            Is Topic
          </Checkbox>
        </div>
        {isTopic ? (
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <Checkbox
              checked={isModule}
              onChange={(e) => this.onIsModuleChange(e.target.checked)}
            >
              Is Module
            </Checkbox>
          </div>
        ) : null}
        {isTopic ? (
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <Select
              allowClear
              style={{ width: "100%" }}
              onSelect={(value) => this.selectSession(value)}
              placeholder="Select Session"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {sessionList}
            </Select>
          </div>
        ) : null}

        <div style={{ width: "100%", marginBottom: "10px" }}>
          <Button
            // type="primary"
            style={{
              marginTop: "19px",
              background: "#5C6AC4",
              color: "#fff",
            }}
            onClick={() => this.addSubmit()}
          >
            Add
          </Button>
        </div>
      </div>
    );
  }
}
export default AddSessionTopic;
