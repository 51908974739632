import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllCategory } from "../../Graphs/Category/listCategory";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import CategoryRecordsTable from "./CategoryRecordsTable";
import { AddCategory } from "./AddCategory";
import { addCategory } from "../../Graphs/Category/addCategory";
import { getEnv } from "../../Graphs/Comman/getEnv";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      categoryTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("CATEGORY");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listCategoryData();
      await this.listGetEnvFunction();
    } catch (e) {
      notification["error"]({
        message: "Category",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listGetEnvFunction = async () => {
    const { authToken, history } = this.props;

    try {
      let envData = await getEnv(authToken);
      this.setState({
        envData: envData,
      });
    } catch (e) {
      console.log("!!!!!error in listGetEnvFunction", e);
      notification["error"]({
        message: "Add Category",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    }
  };

  callAuthorizeData = () => {
    const { envData } = this.state;

    var authoriseUrl =
      envData.authApiUrl +
      "?client_id=" +
      envData.clientId +
      "&attributes=" +
      envData.attributes +
      "&purpose=demonstrating MyInfo APIs" +
      "&state=" +
      encodeURIComponent("123") +
      "&redirect_uri=" +
      envData.redirectUrl;

    console.log("!!!envData", envData, authoriseUrl);
    window.location = authoriseUrl;
  };

  listCategoryData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListCategoryData,
      setListCategoryDataTotal,
    } = this.props;
    try {
      this.setState({
        isLoading: true,
      });

      let categoryData = await getAllCategory(authToken, 0, 10);

      if (categoryData.status === 200) {
        //set category data
        await setListCategoryData(categoryData.data);
        await setListCategoryDataTotal(categoryData.total);

        this.setState({
          categoryList: categoryData.data,
          categoryTotal: categoryData.total,
        });
      } else if (categoryData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      let addCategoryData = await addCategory(authToken, values);
      notification["success"]({
        message: "Add Category",
        description: "SuccessFully added",
      });

      await this.listCategoryData();
    } catch (e) {
      notification["error"]({
        message: "Add Category",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, categoryList, isLoading, categoryTotal } = this.state;

    return (
      <Fragment>
        <Title parent="Manage Categories" title="Manage Categories" />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <AddCategory
              primaryColor={primaryColor}
              onSubmit={this.addSubmit}
            />
          </div>
          {/* <div style={{ float: "right", cursor: "pointer" }}>
            <Button type="primary" onClick={() => this.callAuthorizeData()}>
              SingPass
            </Button>
          </div> */}
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <CategoryRecordsTable
                data={categoryList}
                primaryColor={primaryColor}
                categoryTotal={categoryTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCategoryData: (listCategoryData) => {
      dispatch({
        type: "SET_LIST_CATEGORY_DATA",
        listCategoryData: listCategoryData,
      });
    },
    setListCategoryDataTotal: (listCategoryDataTotal) => {
      dispatch({
        type: "SET_LIST_CATEGORY_DATA_TOTAL",
        listCategoryDataTotal: listCategoryDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Category));
