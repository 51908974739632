import { callGetApiToServer } from '../callApi';

export async function getAllAdminCharityProgramTransaction(authtoken, programId, skipNumber, limitNumber) {
	try {
		let header = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: 'Berear ' + authtoken,
		};

		let endUrl =
			'v1/auth/list-admin-charity-program-transaction/' + programId + '/' + skipNumber + '/' + limitNumber;

		let responseData = await callGetApiToServer(header, 'GET', endUrl);

		return responseData;
	} catch (err) {
		throw err;
	}
}
