import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { editUserMetadata } from "../../Graphs/Setting/editUserMetaData";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Input, Button } from "antd";
import RichTextEditor from "react-rte";

const { TextArea } = Input;

class CharitySetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      primaryColor: "#4466f2",
      adminData: null,
      errors: {
        email: "",
        mobile_number: "",
        about_us: "",
        display_address: "",
        website_link: "",
      },
      radius: 0,
      description: RichTextEditor.createEmptyValue(),
      descriptionString: "",
    };
  }

  onDescriptionChange = (value) => {
    this.setState({ description: value });
  };

  async componentDidMount() {
    try {
      const { adminData, setRouteName, setSearchText, loginUserData, history } =
        this.props;
      if (loginUserData.role === "sub_charity") {
        history.push(`${process.env.PUBLIC_URL}/organization-beneficiaries`);
      }

      let editPrimaryColor = await localStorage.getItem("primary_color");

      this.setState({
        isLoading: true,
      });

      await setRouteName("CHARITY_SETTING");
      await setSearchText("");

      if (
        adminData &&
        adminData.user_metadata &&
        adminData.user_metadata.about_us
      ) {
        this.setState({
          description: RichTextEditor.createValueFromString(
            adminData.user_metadata.about_us,
            "html"
          ),
          descriptionString: adminData.user_metadata.about_us,
        });
      }

      this.setState({
        isLoading: false,
        primaryColor: editPrimaryColor,
        adminData: adminData,
      });
    } catch (e) {
      console.log("!!!!!!!!error in did mount", e);
      notification["error"]({
        message: "SETTING",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editAdminDetails = async () => {
    try {
      const { adminData, errors, descriptionString } = this.state;
      const { authToken, setUserData } = this.props;

      let updatedAdminData = { ...adminData };
      if (updatedAdminData.user_metadata) {
        updatedAdminData.user_metadata.about_us = descriptionString;
      }

      let dummyErros = { ...errors };

      if (!(updatedAdminData && updatedAdminData.user_metadata)) {
        notification["error"]({
          message: "SETTING",
          description: "Please provide all data to update the setting",
        });

        return;
      }

      if (
        !updatedAdminData.user_metadata.email ||
        updatedAdminData.user_metadata.email === ""
      ) {
        dummyErros["email"] = "Email is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["email"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (
        !updatedAdminData.user_metadata.mobile_number ||
        updatedAdminData.user_metadata.mobile_number === ""
      ) {
        dummyErros["mobile_number"] = "Mobile number is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["mobile_number"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (
        !updatedAdminData.user_metadata.website_link ||
        updatedAdminData.user_metadata.website_link === ""
      ) {
        dummyErros["website_link"] = "Website link is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["website_link"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (
        !updatedAdminData.user_metadata.display_address ||
        updatedAdminData.user_metadata.display_address === ""
      ) {
        dummyErros["display_address"] = "Address is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["display_address"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      if (
        !updatedAdminData.user_metadata.about_us ||
        updatedAdminData.user_metadata.about_us === "" ||
        updatedAdminData.user_metadata.about_us === "<p><br></p>"
      ) {
        dummyErros["about_us"] = "About us is required";
        this.setState({
          errors: dummyErros,
        });
        return;
      } else {
        dummyErros["about_us"] = "";
        this.setState({
          errors: dummyErros,
        });
      }

      this.setState({
        isLoading: true,
      });

      let editAdminData = await editUserMetadata(authToken, updatedAdminData);
      console.log("!!!!!!!!!!!!!!!editAdminData", editAdminData);

      if (editAdminData && editAdminData.status === 200) {
        await setUserData(editAdminData.data);

        this.setState({
          adminData: editAdminData.data,
        });
      }
    } catch (e) {
      console.log("!!!!!!!error ", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  onHandleChange = (value, fieldName) => {
    const { adminData } = this.state;
    let dummyAdminData = { ...adminData };
    let dummyAdminMetaData = adminData.user_metadata
      ? { ...adminData.user_metadata }
      : {};
    dummyAdminMetaData[fieldName] = value;
    dummyAdminData.user_metadata = dummyAdminMetaData;
    this.setState({
      adminData: dummyAdminData,
    });
  };

  render() {
    const { isLoading, adminData, errors, editorState } = this.state;

    return (
      <Fragment>
        <Title parent="My Business Info" title="My Business Info" />

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <div className="form setting-form">
                <div className="form-field" style={{ marginBottom: "15px" }}>
                  <div className="input-label"> Email Address </div>
                  <Input
                    name="email"
                    placeholder="Enter your email address"
                    value={
                      adminData && adminData.user_metadata
                        ? adminData.user_metadata.email
                        : ""
                    }
                    onChange={(e) =>
                      this.onHandleChange(e.target.value, "email")
                    }
                  />
                  <div style={{ color: "red" }} className="error-message">
                    {errors && errors["email"] !== "" ? errors["email"] : ""}
                  </div>
                </div>

                <div className="form-field" style={{ marginBottom: "15px" }}>
                  <div className="input-label"> Contact Number </div>
                  <Input
                    name="mobile_number"
                    placeholder="Enter your contact number"
                    value={
                      adminData && adminData.user_metadata
                        ? adminData.user_metadata.mobile_number
                        : ""
                    }
                    onChange={(e) =>
                      this.onHandleChange(e.target.value, "mobile_number")
                    }
                  />
                  <div style={{ color: "red" }} className="error-message">
                    {errors && errors["mobile_number"] !== ""
                      ? errors["mobile_number"]
                      : ""}
                  </div>
                </div>

                <div className="form-field" style={{ marginBottom: "15px" }}>
                  <div className="input-label"> Website Link </div>
                  <Input
                    name="website_link"
                    placeholder="Enter your website link"
                    value={
                      adminData && adminData.user_metadata
                        ? adminData.user_metadata.website_link
                        : ""
                    }
                    onChange={(e) =>
                      this.onHandleChange(e.target.value, "website_link")
                    }
                  />
                  <div style={{ color: "red" }} className="error-message">
                    {errors && errors["website_link"] !== ""
                      ? errors["website_link"]
                      : ""}
                  </div>
                </div>

                <div className="form-field" style={{ marginBottom: "15px" }}>
                  <div className="input-label"> Display Address </div>
                  <TextArea
                    name="display_address"
                    placeholder="Enter your address details"
                    rows={3}
                    value={
                      adminData && adminData.user_metadata
                        ? adminData.user_metadata.display_address
                        : ""
                    }
                    onChange={(e) =>
                      this.onHandleChange(e.target.value, "display_address")
                    }
                  />
                  <div style={{ color: "red" }} className="error-message">
                    {errors && errors["display_address"] !== ""
                      ? errors["display_address"]
                      : ""}
                  </div>
                </div>

                <div
                  className="form-field"
                  style={{ marginBottom: "15px", width: "100%" }}
                >
                  <div className="input-label"> About Us </div>
                  <RichTextEditor
                    value={this.state.description}
                    onChange={(e) => {
                      this.setState({
                        descriptionString: e.toString("html"),
                      });
                      this.onDescriptionChange(e);
                    }}
                    placeholder={"About Us"}
                  />
                  {/* <TextArea
                    name="about_us"
                    placeholder="Provide a brief description of your business"
                    rows={8}
                    style={{ width: "100%" }}
                    value={
                      adminData && adminData.user_metadata
                        ? adminData.user_metadata.about_us
                        : ""
                    }
                    onChange={(e) =>
                      this.onHandleChange(e.target.value, "about_us")
                    }
                  /> */}
                  <div style={{ color: "red" }} className="error-message">
                    {errors && errors["about_us"] !== ""
                      ? errors["about_us"]
                      : ""}
                  </div>
                </div>

                <div className="form-field">
                  <Button
                    type="primary"
                    style={{
                      marginTop: "19px",
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                    onClick={() => this.editAdminDetails()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  adminData: state.auth.loginUserData,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserData: (listUserData) => {
      dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: listUserData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CharitySetting));
