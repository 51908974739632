import * as React from "react";
import { Modal, Spin, Button, Divider } from "antd";
import { UploadForm } from "./UploadForm";
import { WEBURL } from "../../constant/comman";

export class ErrorCodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  render() {
    const { errorCodes } = this.props;

    return (
      // <StyleBox>
      <Modal
        width="600px"
        closable={this.state.closable}
        visible={this.state.visible}
        title="Duplicate Voucher Codes"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          <div>
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "15px",
                }}
              >
                The following voucher codes are already present in the system
              </div>
              <Divider />
              <div style={{ width: "100%", display: "inline-block" }}>
                {errorCodes.map((data, index) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        marginBottom: "10px",
                      }}
                    >
                      <span style={{ fontSize: "14px" }}>{data}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
