import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCharityProgram } from "../../Graphs/CharityProgram/listCharityProgram";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  DollarCircleOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { deleteCharityProgram } from "../../Graphs/CharityProgram/deleteCharityProgram";
import { changeStatus } from "../../Graphs/CharityProgram/changeStatus";
import { editCharityProgram } from "../../Graphs/CharityProgram/editCharityProgram";
import { searchCharityProgram } from "../../Graphs/CharityProgram/searchCharityProgram";
import { EditModal } from "./EditModal";
import { TransactionModal } from "./TransactionModal";
import { PurchaseCreditModal } from "./PurchaseCreditModal";
import { purchaseCredit } from "../../Graphs/CharityProgram/purchaseCredit";
import { AvailableCreditModal } from "./AvailableCreditModal";
import { assignCredit } from "../../Graphs/CharityProgram/assignCredit";
import { AssignWorkerCreditModal } from "./AssignWorkerCreditModal";
import { UserPlus, List } from "react-feather";

var moment = require("moment");

class CharityProgramRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCharityProgram = this.getLimitedCharityProgram.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      charityProgramSelectedData: null,
      programPurchaseData: null,
    });
  }

  modalRef;
  modalPurchaseCreditRef;
  modalAssignCreditRef;
  modalAvailableCreditRef;
  modalTransRef;

  async getLimitedCharityProgram(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchCharityProgramData(start, end);
        } else {
          //call normal user Data
          this.listCharityProgramData(start, end);
        }
      }
    );
  }

  listSearchCharityProgramData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCharityProgramData,
        setListCharityProgramDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let charityData = await searchCharityProgram(
        authToken,
        searchText,
        start,
        end
      );

      if (charityData.status === 200) {
        //set user Data
        await setListCharityProgramData(charityData.data);
        await setListCharityProgramDataTotal(charityData.total);

        this.setState({
          isLoading: false,
          data: charityData.data,
        });
      } else if (charityData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search charity Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCharityProgramData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCharityProgramData,
        setListCharityProgramDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let charityData = await getAllCharityProgram(authToken, start, end);

      if (charityData.status === 200) {
        //set user Data
        await setListCharityProgramData(charityData.data);
        await setListCharityProgramDataTotal(charityData.total);

        this.setState({
          isLoading: false,
          data: charityData.data,
        });
      } else if (charityData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list vendor Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        charityProgramSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      charityProgramSelectedData: null,
    });
  }

  showTransModal(item) {
    this.setState(
      {
        programTransData: item,
        // isOpen: true
      },
      () => {
        this.modalTransRef.show();
      }
    );
  }

  handleDetailsTransModalclose() {
    this.setState({
      programTransData: null,
    });
  }

  showPurchaseCreditModal(item) {
    this.setState(
      {
        programPurchaseData: item,
        // isOpen: true
      },
      () => {
        this.modalPurchaseCreditRef.show();
      }
    );
  }

  handleDetailsPurchaseCreditModalclose() {
    this.setState({
      programPurchaseData: null,
    });
  }

  showAvailableCreditModal(item) {
    this.setState(
      {
        programAvailableData: item,
        // isOpen: true
      },
      () => {
        this.modalAvailableCreditRef.show();
      }
    );
  }

  handleDetailsAvailableCreditModalclose() {
    this.setState({
      programAvailableData: null,
    });
  }

  showAssignCreditModal(item) {
    this.setState(
      {
        programAssignData: item,
        // isOpen: true
      },
      () => {
        this.modalAssignCreditRef.show();
      }
    );
  }

  handleDetailsAssignCreditModalclose() {
    this.setState({
      programAssignData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Program Name",
      key: "Program Name",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Program Name">
                <div>{item.title ? item.title : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "short_description",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Short Description">
              <div>{item.short_description ? item.short_description : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Cause",
      key: "CauseCategories",
      width: 150,
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Cause Categories">
              <div>{item.cause_categories ? item.cause_categories : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Categories",
      key: "Categories",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Categories">
              <div>{item.categories ? item.categories : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    // {
    // 	title: 'Total Donation',
    // 	key: 'Total Donation',
    // 	render: (text, item, index) => {
    // 		return (
    // 			<div>
    // 				<Tooltip placement="bottom" title="Total Donation">
    // 					<div>
    // 						{item.total_donation && item.total_donation > 0 ? String(item.total_donation) : '-'}
    // 					</div>
    // 				</Tooltip>
    // 			</div>
    // 		);
    // 	},
    // },
    {
      title: "Unassigned Credits",
      key: "Unassigned Credits",
      width: 150,
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Unassigned Credits">
              <div>
                {item.total_donation_left && item.total_donation_left > 0
                  ? `$ ${String(item.total_donation_left.toLocaleString())}`
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "isActive",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="Charity Program Activation">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Edit">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{ float: "left", marginRight: "5px" }}
            >
              <Tooltip placement="bottom" title="Delete">
                <Popconfirm
                  onConfirm={this.deleteCharityProgram.bind(this, item)}
                  title="Are you sure you want to delete this program?"
                >
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Purchase Credits">
                <Button
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    this.showPurchaseCreditModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              mr="5px"
              style={{ float: "left", marginRight: "5px", marginTop: "5px" }}
            >
              <Tooltip placement="bottom" title="Available Credits by Category">
                <Button
                  shape="circle"
                  icon={<DollarCircleOutlined />}
                  onClick={() => {
                    this.showAvailableCreditModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              mr="5px"
              style={{ float: "left", marginRight: "5px", marginTop: "5px" }}
            >
              <Tooltip placement="bottom" title="Assign Credits to Supervisors">
                <Button
                  shape="circle"
                  className="feather-svg"
                  icon={<UserPlus />}
                  onClick={() => {
                    this.showAssignCreditModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              mr="5px"
              style={{ float: "left", marginRight: "5px", marginTop: "5px" }}
            >
              <Tooltip placement="bottom" title="View Transactions">
                <Button
                  shape="circle"
                  className="feather-svg"
                  icon={<List />}
                  onClick={() => {
                    this.showTransModal(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  async deleteCharityProgram(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteCharityProgram(authToken, item.id);

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete Charity Program",
          description: "Successfully deleted",
        });

        if (searchText != null && searchText !== "") {
          this.listSearchCharityProgramData(startIndex, limitNumber);
          return;
        }
        this.listCharityProgramData(startIndex, limitNumber);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Charity Program",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Change Charity Program Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchCharityProgramData(startIndex, limitNumber);
          return;
        }
        this.listCharityProgramData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Charity Program Status",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (charityProgramObejct, charityProgramId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editCharityProgram(
        authToken,
        charityProgramObejct,
        charityProgramId
      );
      if (statusData.status === 200) {
        notification["success"]({
          message: "Edit Charity Program Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchCharityProgramData(startIndex, limitNumber);
          return;
        }
        this.listCharityProgramData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Edit Charity Program",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCharityProgram(pagination)
    );
  }

  purchaseSubmit = async (purchaseObject, programId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      console.log("!!!!purchaseObject", purchaseObject);

      let statusData = await purchaseCredit(
        authToken,
        purchaseObject,
        programId
      );
      if (statusData.status === 200) {
        notification["success"]({
          message: "Purchase Credit",
          description: "Successfully purchased",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchCharityProgramData(startIndex, limitNumber);
          return;
        }
        this.listCharityProgramData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Purchase Credit",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  assignSubmit = async (assignObject, programId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });

      let statusData = await assignCredit(authToken, assignObject, programId);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Assign Credit",
          description: "Successfully assigned",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchCharityProgramData(startIndex, limitNumber);
          return;
        }
        this.listCharityProgramData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Assign Credit",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { isLoading, data } = this.state;
    const {
      listCharityProgramData,
      listCharityProgramDataTotal,
      searchLoader,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          scroll={{ x: 1200 }}
          // dataSource={this.state.data}
          dataSource={listCharityProgramData}
          pagination={{
            total: listCharityProgramDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.charityProgramSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            charityProgramSelectedData={this.state.charityProgramSelectedData}
            onSubmit={this.editSubmit}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.programTransData ? (
          <TransactionModal
            refx={(e) => (this.modalTransRef = e)}
            onClose={this.handleDetailsTransModalclose.bind(this)}
            programTransData={this.state.programTransData}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.programPurchaseData ? (
          <PurchaseCreditModal
            refx={(e) => (this.modalPurchaseCreditRef = e)}
            onClose={this.handleDetailsPurchaseCreditModalclose.bind(this)}
            programPurchaseData={this.state.programPurchaseData}
            authToken={this.props.authToken}
            onSubmit={this.purchaseSubmit}
          />
        ) : null}
        {this.state.programAvailableData ? (
          <AvailableCreditModal
            refx={(e) => (this.modalAvailableCreditRef = e)}
            onClose={this.handleDetailsAvailableCreditModalclose.bind(this)}
            programAvailableData={this.state.programAvailableData}
            authToken={this.props.authToken}
            showMainTitle={true}
          />
        ) : null}
        {this.state.programAssignData ? (
          <AssignWorkerCreditModal
            refx={(e) => (this.modalAssignCreditRef = e)}
            onClose={this.handleDetailsAssignCreditModalclose.bind(this)}
            programAssignData={this.state.programAssignData}
            authToken={this.props.authToken}
            onSubmit={this.assignSubmit}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCharityProgramData: state.charity_program.listCharityProgramData,
  listCharityProgramDataTotal:
    state.charity_program.listCharityProgramDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCharityProgramData: (listCharityProgramData) => {
      dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA",
        listCharityProgramData: listCharityProgramData,
      });
    },
    setListCharityProgramDataTotal: (listCharityProgramDataTotal) => {
      dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA_TOTAL",
        listCharityProgramDataTotal: listCharityProgramDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CharityProgramRecordsTable));
