import { callGetApiToServer } from "../callApi";

export async function getAllAssignedVoucherList(
  authtoken,
  workerId,
  skipNumber,
  limitNumber
) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl =
      "v1/auth/worker-assigned-voucher-list-admin/" +
      workerId +
      "/" +
      skipNumber +
      "/" +
      limitNumber;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
