import { combineReducers } from "redux";
import Customizer from "./customizer.reducer";
import auth from "./auth";
import category from "./category";
import vendor from "./vendor";
import charity from "./charity";
import charity_program from "./charity_program";
import user from "./user";
import voucher from "./voucher";
import voucher_inventory from "./voucher_inventory";
import static_page from "./static_page";
import linkup from "./linkup";
import vendor_linkup from "./vendor_linkup";
import vendor_amount from "./vendor_amount";
import sub_user from "./sub_user";

const reducers = combineReducers({
  Customizer,
  auth,
  category,
  vendor,
  charity,
  user,
  voucher,
  voucher_inventory,
  charity_program,
  static_page,
  vendor_amount,
  linkup,
  sub_user,
  vendor_linkup,
});

export default reducers;
