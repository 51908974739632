/* eslint-disable */

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Component } from "react";
import { connect } from "react-redux";
class AuthRouteGaurdComponent extends Component {
  render() {
    return (
      <PrivateRoute
        component={this.props.component}
        isAuthenticated={this.props.loginFlag}
        loginUserData={this.props.loginUserData}
        {...this.props}
      />
    );
  }
}

function PrivateRoute({
  component: Component,
  isAuthenticated,
  loginUserData,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  loginFlag: state.auth.loginFlag,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const AuthRouteGaurd = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthRouteGaurdComponent);
