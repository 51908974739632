import { callApiToServer } from "../callApi";

export async function addVendorLinkup(authtoken, addVendorLinkupObject) {
  try {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    const body = JSON.stringify({
      linkup_corppass_id: addVendorLinkupObject.linkup_corppass_id,
      corppass_id: addVendorLinkupObject.corppass_id,
    });

    const endUrl = "v1/auth/vendor-linkup";
    const responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
