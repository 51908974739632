export class ConfigDB {
	static data = {
		settings: {
			layout_type: 'ltr',
			sidebar: {
				wrapper: 'default',
				bodyWrapper: 'default'
			},
			sidebar_setting: 'default-sidebar',
			sidebar_backround: 'light-sidebar'
		},
		color: {
			layout_version: 'light',
			color: 'color-6',
			primary_color: '#3949ab',
			secondary_color: '#4fc3f7',
			mix_layout: 'light-only'
		},
		router_animation: 'fadeIn'
	}
}

export default ConfigDB;
