import * as React from "react";
import { Modal, Spin, notification, Divider } from "antd";
import { PurchaseCredit } from "./PurchaseCredit";
import { getAllAvailableCreditsProgram } from "../../Graphs/CharityProgram/listAvailableCreditProgram";

export class AvailableCreditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      availableCreditData: null,
    };
  }

  componentDidMount = async () => {
    const { authToken, programAvailableData } = this.props;

    if (this.props.refx) {
      this.props.refx(this);
    }

    try {
      this.setState({
        isLoading: true,
      });

      let availableCreditData = await getAllAvailableCreditsProgram(
        authToken,
        programAvailableData.id
      );

      let totalAvailableData = availableCreditData.data;

      this.setState({
        availableCreditData: totalAvailableData,
      });
    } catch (e) {
      notification["error"]({
        message: "Error",
        description:
          typeof e === "string"
            ? e
            : typeof e.message
            ? e.message
            : "Please try again later",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  render() {
    const { availableCreditData, isLoading } = this.state;
    const { showMainTitle } = this.props;
    console.log("!!!!!availableCreditData", availableCreditData);

    let totalBalance = 0;
    availableCreditData &&
      availableCreditData.length > 0 &&
      availableCreditData.map((data, index) => {
        totalBalance = totalBalance + data.total_balance;
      });

    return (
      // <StyleBox>
      <Modal
        width="600px"
        closable={this.state.closable}
        visible={this.state.visible}
        title={
          showMainTitle
            ? "Available Credits by Category"
            : "View Assigned Credits"
        }
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ marginLeft: "20px" }}>
            <Spin size="large" style={{ width: "100%", margin: "0 auto" }} />
          </div>
        ) : (
          <div>
            {availableCreditData && availableCreditData.length > 0 ? (
              <div>
                {availableCreditData.map((data, index) => {
                  return (
                    <div style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          marginBottom: "5px",
                        }}
                      >
                        {data.category ? data.category.name : "-"}
                      </div>
                      <div
                        style={{
                          fontWeight: "normal",
                          fontSize: "14px",
                          marginBottom: "10px",
                        }}
                      >
                        {`$ ${data.total_balance.toLocaleString()}`}
                      </div>
                      <Divider />
                    </div>
                  );
                })}

                <div style={{ textAlign: "center" }}>
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "5px",
                      }}
                    >
                      {"Total Balance"}
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: "14px",
                        marginBottom: "10px",
                      }}
                    >
                      {`$ ${totalBalance.toLocaleString()}`}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: "10px" }}>
                  <div
                    style={{
                      fontWeight: "normal",
                      fontSize: "18px",
                      marginBottom: "5px",
                    }}
                  >
                    No Data Found
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }
}
