import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Users, DollarSign, UserPlus, Home } from "react-feather";
import { callAllKpis } from "../../Graphs/Dashboard/callCharityKpis";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, notification, Spin } from "antd";

class CharityHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCount: 0,
      needyFamilyCount: 0,
      socialWorkerCount: 0,
      availableCreditData: 0,
      totalDonation: 0,
      contributorsCount: 0,
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setRouteName,
        loginUserData,
      } = this.props;
      if (loginUserData.role === "sub_charity") {
        history.push(`${process.env.PUBLIC_URL}/organization-beneficiaries`);
      }

      this.setState({
        isLoading: true,
      });

      await setRouteName("DASHBOARD");
      let responseKpiData = await callAllKpis(authToken);

      if (responseKpiData.status === 200) {
        //set kpi data
        this.setState({
          needyFamilyCount: responseKpiData.data.needyFamilyCount,
          socialWorkerCount: responseKpiData.data.socialWorkerCount,
          availableCreditData: responseKpiData.data.availableCreditData,
          totalDonation: responseKpiData.data.totalDonation,
          contributorsCount: responseKpiData.data.contributorsCount,
        });
      } else if (responseKpiData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!error in did mount", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const {
      voucherCount,
      needyFamilyCount,
      socialWorkerCount,
      availableCreditData,
      totalDonation,
      contributorsCount,
    } = this.state;

    return (
      <Fragment>
        {/* <Breadcrumb parent="Dashboard" title="Dashboard" /> */}
        <div
          className="container-fluid"
          style={{ marginTop: "40px", display: "inline-block" }}
        >
          <div className="row">
            <div className="col-sm-6 col-xl-4 col-lg-6">
              <Link to="/organization-available-credit">
                <div className="card o-hidden">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <DollarSign />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Unassigned Credits</span>
                        <h4 className="mb-0 counter">
                          <span>{`$ ${availableCreditData.toLocaleString()}`}</span>
                        </h4>
                        <DollarSign className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xl-4 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-primary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <DollarSign />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Total Donations Received</span>
                      <h4 className="mb-0 counter">
                        <span>{`$ ${totalDonation.toLocaleString()}`}</span>
                      </h4>
                      <DollarSign className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-primary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <UserPlus />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Total Contributors</span>
                      <h4 className="mb-0 counter">
                        <CountUp className="counter" end={contributorsCount} />
                      </h4>
                      <UserPlus className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-sm-6 col-xl-4 col-lg-6">
							<div className="card o-hidden">
								<div className="bg-primary b-r-4 card-body">
									<div className="media static-top-widget">
										<div className="align-self-center text-center">
											<Type />
										</div>
										<div className="media-body">
											<span className="m-0">Active e-Vouchers</span>
											<h4 className="mb-0 counter">
												<CountUp className="counter" end={5} />
											</h4>
											<Type className="icon-bg" />
										</div>
									</div>
								</div>
							</div>
						</div> */}
            <div className="col-sm-6 col-xl-4 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-primary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Home />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Total Impacted Households</span>
                      <h4 className="mb-0 counter">
                        <CountUp className="counter" end={needyFamilyCount} />
                      </h4>
                      <Home className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-primary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Users />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Total Supervisors</span>
                      <h4 className="mb-0 counter">
                        <CountUp className="counter" end={socialWorkerCount} />
                      </h4>
                      <Users className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CharityHome));
