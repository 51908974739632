import { Modal, Button, Divider, notification } from "antd";
import * as React from "react";
import {
  Layout,
  Input,
  TimePicker,
  Select,
  Spin,
  Checkbox,
  DatePicker,
} from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { WEBURL, AWS_URL } from "../../constant/comman";
import { PictureOutlined } from "@ant-design/icons";
import { AddVoucherIcon } from "./AddVoucherIcon";
import * as la from "lodash";
import { getAllTypeActiveCategory } from "../../Graphs/Category/listAllTypeActiveCategory";

const { Content } = Layout;
const Option = Select.Option;
const dateFormat = "YYYY-MM-DD";
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: true,
      errors: {
        uploadImage: "",
        startDate: "",
        endDate: "",
      },
      roleType: "1",
      startDate: "",
      endDate: "",
      isPaid: false,
      changeFile: true,
      isExternalLink: this.props.data.is_external,
    };
  }

  input;
  allCategoryType = [];
  selectedCategoryType = "";
  src = "";
  viewModalRef;
  allValidityType = [
    <Option key={"3"} value={"3"}>
      3 Months
    </Option>,
    <Option key={"6"} value={"6"}>
      6 Months
    </Option>,
    <Option key={"12"} value={"12"}>
      12 Months
    </Option>,
  ];
  selectedValidityType = "";

  async componentDidMount() {
    const { authToken } = this.props;

    this.setState({
      isLoading: true,
    });

    let categoryData = await getAllTypeActiveCategory(authToken, "vendor");
    this.selectedValidityType = String(this.props.data.validity);
    this.selectedCategoryType =
      this.props.data.category && this.props.data.category.id;

    categoryData &&
      categoryData.data &&
      categoryData.data.length > 0 &&
      la.map(categoryData.data, (subData, subIndex) => {
        this.allCategoryType.push(
          <Option key={subData.id} value={subData.id}>
            {subData.name}
          </Option>
        );
      });

    this.src =
      this.props.data.icon_name && this.props.data.icon_name !== null
        ? this.props.data.icon_name
        : "";
    this.setState({
      isLoading: false,
      startDate:
        this.props.data.start_date == null ? "" : this.props.data.start_date,
      endDate: this.props.data.end_date == null ? "" : this.props.data.end_date,
      isPaid: this.props.data.is_paid,
    });
  }

  uploadImage = (name) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
        changeFile: true,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  handleSelectValiditytype(value, option) {
    this.selectedValidityType = value;
  }

  handleSelectCategorytype(value, option) {
    this.selectedCategoryType = value;
  }

  validateForm = (values) => {
    const errors = {};
    const { startDate, endDate, isExternalLink } = this.state;

    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "name is required";
    }

    if (!values.description) {
      errors.description = "description is required";
    }

    if (!values.amount) {
      errors.amount = "Amount is required";
    }

    if (!(values.amount >= 0)) {
      errors.amount = "Amount can not be negative";
    }

    if (!this.selectedCategoryType) {
      errors.category_id = "Category is required";
    }

    if (!this.selectedValidityType) {
      errors.validity = "Validity is required";
    }

    if (!startDate) {
      errors.start_date = "startDate is required";
    }

    if (!endDate) {
      errors.end_date = "endDate is required";
    }

    if (isExternalLink && !values.external_link) {
      errors.external_link = "External Link is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values, action) => {
    const { errors, startDate, endDate, isPaid, isExternalLink } = this.state;
    let dummyErrors = { ...errors };

    values.start_date = startDate;
    values.end_date = endDate;
    values.category_id = this.selectedCategoryType;
    values.validity = this.selectedValidityType;
    // values.is_paid = isPaid;
    values.is_external = isExternalLink;
    values.is_paid = true;

    if (!this.src) {
      dummyErrors.uploadImage = "Please upload the icon";
      this.setState({
        errors: dummyErrors,
      });
      return;
    }

    dummyErrors.uploadImage = "";
    this.setState({
      errors: dummyErrors,
    });

    values.icon_name = this.src;
    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  onPaidChange = (checked) => {
    this.setState({
      isPaid: checked,
    });
  };

  onstartDateChange = (date, dateString) => {
    this.setState({
      startDate: dateString,
    });
  };

  onEndDateChange = (date, dateString) => {
    this.setState({
      endDate: dateString,
    });
  };

  onIsExternalLinkChange = (checked) => {
    this.setState({
      isExternalLink: checked,
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: this.props.data.name,
                description: this.props.data.description,
                category_id: this.props.data.category_id,
                validity: this.props.data.validity,
                amount: this.props.data.amount,
                discount_amount: this.props.data.discount_amount,
                discount_percentage: this.props.data.discount_percentage,
                terms: this.props.data.terms,
                is_paid: this.props.data.is_paid,
                instructions: this.props.data.instructions,
                start_date: this.props.data.start_date,
                end_date: this.props.data.end_date,
                is_external: this.props.data.is_external,
                external_link: this.props.data.external_link,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return isLoading ? (
                  <div style={{ marginLeft: "20px" }}>
                    <Spin
                      size="large"
                      style={{ width: "100%", margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Name
                          <Input
                            id="name"
                            placeholder="Voucher Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Description
                          <Input
                            id="description"
                            placeholder="Voucher Description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.description}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Amount
                          <Input
                            id="amount"
                            placeholder="Voucher Amount"
                            type="number"
                            min={0}
                            value={values.amount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.amount && touched.amount ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.amount}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Category
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            value={this.selectedCategoryType}
                            onSelect={(value) =>
                              this.handleSelectCategorytype(value)
                            }
                            placeholder="Select Category"
                            showSearch
                            onChange={(value) => {
                              this.handleSelectCategorytype(value);
                              setFieldValue("category_id", value);
                            }}
                            onBlur={() => setFieldTouched("category_id", true)}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allCategoryType}
                          </Select>
                          {errors.category_id ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.category_id}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      {/* <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Discount Percentage
                          <Input
                            id="discount_percentage"
                            placeholder="Voucher Discount Percentage"
                            type="number"
                            min={0}
                            value={values.discount_percentage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.discount_percentage &&
                          touched.discount_percentage ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.discount_percentage}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Discount Amount
                          <Input
                            id="discount_amount"
                            placeholder="Voucher Discount Amount"
                            type="number"
                            min={0}
                            value={values.discount_amount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.discount_amount && touched.discount_amount ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.discount_amount}
                            </p>
                          ) : null}
                        </div>
                      </div> */}

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Terms
                          <TextArea
                            id="terms"
                            placeholder="Voucher Terms"
                            rows={8}
                            value={values.terms}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.terms && touched.terms ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.terms}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Validity
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            value={this.selectedValidityType}
                            onSelect={(value) =>
                              this.handleSelectValiditytype(value)
                            }
                            placeholder="Select Validity"
                            showSearch
                            onChange={(value) => {
                              this.handleSelectValiditytype(value);
                              setFieldValue("category_id", value);
                            }}
                            onBlur={() => setFieldTouched("category_id", true)}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allValidityType}
                          </Select>
                          {errors.validity ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.validity}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          <span style={{ width: "100%", float: "left" }}>
                            Start Date
                          </span>
                          <DatePicker
                            format={dateFormat}
                            defaultValue={moment(
                              this.state.startDate,
                              dateFormat
                            )}
                            onChange={(date, dateString) => {
                              this.onstartDateChange(date, dateString);
                              setFieldValue("start_date", dateString);
                            }}
                            disabledDate={(current) => {
                              return (
                                current && current < moment().subtract(1, "day")
                              );
                            }}
                          />
                          {errors.start_date ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.start_date}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          <span style={{ width: "100%", float: "left" }}>
                            End Date
                          </span>
                          <DatePicker
                            format={dateFormat}
                            defaultValue={moment(
                              this.state.endDate,
                              dateFormat
                            )}
                            onChange={(date, dateString) => {
                              this.onEndDateChange(date, dateString);
                              setFieldValue("end_date", dateString);
                            }}
                            disabledDate={(current) => {
                              return (
                                current && current < moment().subtract(1, "day")
                              );
                            }}
                          />
                          {errors.end_date ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.end_date}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Instructions
                          <TextArea
                            id="instructions"
                            placeholder="Voucher Instructions"
                            value={values.instructions}
                            rows={8}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.instructions && touched.instructions ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.instructions}
                            </p>
                          ) : null}
                        </div>
                        <div
                          className="formik-field-right"
                          style={{ marginTop: "20px" }}
                        >
                          <Checkbox
                            checked={this.state.isExternalLink}
                            onChange={(e) =>
                              this.onIsExternalLinkChange(e.target.checked)
                            }
                          >
                            Is External Link
                          </Checkbox>
                        </div>
                      </div>

                      {this.state.isExternalLink && (
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            External Link
                            <Input
                              id="external_link"
                              placeholder="External Link"
                              value={values.external_link}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.external_link && touched.external_link ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.external_link}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ float: "left", width: "10%" }}>
                          <PictureOutlined
                            style={{ width: "100%", fontSize: "30px" }}
                          />
                        </div>
                        <Button
                          style={{ width: "90%" }}
                          onClick={this.showViewModal.bind(this)} //this.showViewModal.bind(this)
                        >
                          Choose Voucher Icon ( Upload svg format only )
                        </Button>
                        {this.src ? (
                          <p
                            style={{
                              fontSize: "small",
                              margin: "0",
                              marginLeft: "5px",
                            }}
                          >
                            {this.src}
                          </p>
                        ) : null}
                        {this.state.view ? (
                          <AddVoucherIcon
                            refx={(e) => (this.viewModalRef = e)}
                            onUpload={this.uploadImage}
                          />
                        ) : null}
                      </div>
                      <div width="100%" style={{ marginTop: "10px" }}>
                        {this.state.errors.uploadImage != "" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.uploadImage}
                          </p>
                        ) : null}
                      </div>
                      <div
                        style={{
                          marhinTop: "20px",
                          width: "100px",
                          backgroundColor:
                            this.selectedColorType !== ""
                              ? this.selectedColorType
                              : "#ffffff",
                          borderRadius: "10px",
                        }}
                      >
                        {this.src && this.src !== "" ? (
                          <img
                            src={
                              this.state.changeFile
                                ? `${WEBURL}voucher/icon/${this.src}`
                                : `${AWS_URL}voucher/icon/${this.src}`
                            }
                            alt="VoucherIcon"
                            style={{ width: "100px" }}
                          />
                        ) : null}
                      </div>

                      <Button
                        // type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
