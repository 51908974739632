import {
  SET_LIST_VOUCHER_INVENTORY_DATA,
  SET_LIST_VOUCHER_INVENTORY_DATA_TOTAL,
} from "../constant/actionTypes";

const initial_state = {
  listVoucherInventoryData: null,
  listVoucherInventoryDataTotal: 0,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_LIST_VOUCHER_INVENTORY_DATA:
      return {
        ...state,
        loading: false,
        listVoucherInventoryData: action.listVoucherInventoryData,
      };

    case SET_LIST_VOUCHER_INVENTORY_DATA_TOTAL:
      return {
        ...state,
        loading: false,
        listVoucherInventoryDataTotal: action.listVoucherInventoryDataTotal,
      };

    default:
      return { ...state };
  }
};
