import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllSubUser } from "../../Graphs/SubUser/listSubUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import SubUserRecordsTable from "./SubUserRecordsTable";
import { AddSubUser } from "./AddSubUser";
import { addSubUser } from "../../Graphs/SubUser/addSubUser";

class SubUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subUserList: [],
      subUserTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText, loginUserData, history } =
        this.props;

      // if (loginUserData.role === "sub_vendor") {
      //   history.push(`${process.env.PUBLIC_URL}/voucher`);
      // }

      if (loginUserData.role === "vendor" && loginUserData.master_vendor_id) {
        history.push(`${process.env.PUBLIC_URL}/home-vendor`);
      }

      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("SUB_USER");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listSubUserData();
    } catch (e) {
      notification["error"]({
        message: "Category",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listSubUserData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListSubUserData,
      setListSubUserDataTotal,
    } = this.props;
    try {
      this.setState({
        isLoading: true,
      });

      let subUserData = await getAllSubUser(authToken, 0, 10);

      if (subUserData.status === 200) {
        //set sub user data
        await setListSubUserData(subUserData.data);
        await setListSubUserDataTotal(subUserData.total);

        this.setState({
          subUserList: subUserData.data,
          subUserTotal: subUserData.total,
        });
      } else if (subUserData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      let addSubUserData = await addSubUser(authToken, values);
      notification["success"]({
        message: "Add Sub User",
        description: "SuccessFully added",
      });

      await this.listSubUserData();
    } catch (e) {
      notification["error"]({
        message: "Add Sub User",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, subUserList, isLoading, subUserTotal } = this.state;

    return (
      <Fragment>
        <Title parent="Manage Sub Users" title="Manage Sub Users" />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <AddSubUser primaryColor={primaryColor} onSubmit={this.addSubmit} />
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <SubUserRecordsTable
                data={subUserList}
                primaryColor={primaryColor}
                subUserTotal={subUserTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListSubUserData: (listSubUserData) => {
      dispatch({
        type: "SET_LIST_SUB_USER_DATA",
        listSubUserData: listSubUserData,
      });
    },
    setListSubUserDataTotal: (listSubUserDataTotal) => {
      dispatch({
        type: "SET_LIST_SUB_USER_DATA_TOTAL",
        listSubUserDataTotal: listSubUserDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubUser));
