import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllSocialWorker } from "../../Graphs/SocialWorker/listSocialWorker";
import {
  uploadSocialWorker,
  uploadSingleSocialWorker,
} from "../../Graphs/SocialWorker/uploadSocialWorker";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import UserRecordsTable from "./UserRecordsTable";
import { UploadModal } from "./UploadModal";
import { AddSingleUser } from "./AddSingleUser";

class CharitySocialWorker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
      uploadUserData: false,
      uploadSingleUserData: false,
    };
  }

  uploadModalRef;
  uploadSingleModalRef;

  showUploadModal = (item) => {
    this.setState(
      {
        uploadUserData: true,
        // isOpen: true
      },
      () => {
        this.uploadModalRef.show();
      }
    );
  };

  handleDetailsUploadModalclose() {
    this.setState({
      uploadUserData: false,
    });
  }

  showSingleUploadModal() {
    this.setState(
      {
        uploadSingleUserData: true,
        // isOpen: true
      },
      () => {
        this.uploadSingleModalRef.show();
      }
    );
  }

  handleDetailsUploadSingleModalclose() {
    this.setState({
      uploadSingleUserData: false,
    });
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("CHARITY_SOCIAL_WORKER");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listUserData();
    } catch (e) {
      notification["error"]({
        message: "User",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listUserData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListUserData,
      setListUserDataTotal,
    } = this.props;

    try {
      this.setState({
        isLoading: true,
      });

      let userData = await getAllSocialWorker(authToken, 0, 10);

      if (userData.status === 200) {
        //set category data
        await setListUserData(userData.data);
        await setListUserDataTotal(userData.total);

        this.setState({
          userList: userData.data,
          userTotal: userData.total,
        });
      } else if (userData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  uploadSubmit = async (userObject) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;

      this.setState({
        isLoading: true,
      });
      let statusData = await uploadSocialWorker(authToken, userObject);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Supervisor",
          description: "Successfully uploaded",
        });
        this.listUserData();
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "Supervisor",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    }
  };

  uploadSingleSubmit = async (value) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;

      this.setState({
        isLoading: true,
      });
      let statusData = await uploadSingleSocialWorker(authToken, value);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Supervisor",
          description: "Successfully added",
        });
        this.listUserData();
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "Supervisor",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  capitalizeFirstLetter(string) {
    return (
      string.charAt(0).toUpperCase() + String(string.slice(1)).toLowerCase()
    );
  }

  generateCsv = async () => {
    try {
      const { authToken } = this.props;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getAllSocialWorker(authToken, 0, 100000000000000000)
            .then((responseData) => {
              let csvArray = [];

              csvArray.push([
                "NRIC/FIN",
                "Name",
                "Email",
                "Mobile Number",
                "Address",
                "Is Active",
              ]);

              responseData.data.map((dataItem) => {
                let addressString = "";
                if (dataItem.user_metadata && dataItem.user_metadata.address) {
                  let addressJson = JSON.parse(dataItem.user_metadata.address);
                  if (addressJson.floor && addressJson.floor.value) {
                    addressString =
                      addressString +
                      this.capitalizeFirstLetter(addressJson.floor.value) +
                      ", ";
                  }

                  if (addressJson.block && addressJson.block.value) {
                    addressString =
                      addressString +
                      this.capitalizeFirstLetter(addressJson.block.value) +
                      ", ";
                  }

                  if (addressJson.building && addressJson.building.value) {
                    addressString =
                      addressString +
                      this.capitalizeFirstLetter(addressJson.building.value) +
                      ", ";
                  }

                  if (addressJson.street && addressJson.street.value) {
                    addressString =
                      addressString +
                      this.capitalizeFirstLetter(addressJson.street.value) +
                      ", ";
                  }

                  if (addressJson.postal && addressJson.postal.value) {
                    addressString =
                      addressString +
                      this.capitalizeFirstLetter(addressJson.postal.value) +
                      ", ";
                  }

                  if (addressJson.country && addressJson.country.value) {
                    addressString =
                      addressString +
                      this.capitalizeFirstLetter(addressJson.country.desc);
                  }
                }

                csvArray.push([
                  dataItem.singpass_id ? dataItem.singpass_id : "NA",
                  dataItem.user_metadata
                    ? dataItem.user_metadata.principal_name
                    : "NA",
                  dataItem.user_metadata ? dataItem.user_metadata.email : "NA",
                  dataItem.user_metadata
                    ? dataItem.user_metadata.mobile_number
                    : "NA",
                  addressString ? `"${addressString}"` : "NA",
                  dataItem.is_active ? "Active" : "InActive",
                ]);
              });

              const rows = csvArray;
              let csvName = "AllSuperVisors.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((e) => {
              console.log("ERROR", e);
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "EXPORT DATA",
                description: typeof e === "string" ? e : "Something went wrong",
              });
              throw e;
            });
        }
      );
    } catch (e) {
      notification["error"]({
        message: "EXPORT DATA",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      console.log("error in generateCsv function", e);
    }
  };

  render() {
    const { primaryColor, userList, isLoading, userTotal } = this.state;
    const { authToken } = this.props;

    return (
      <Fragment>
        <Title parent="List of Supervisors" title="List of Supervisors" />

        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            display: "inline-block",
          }}
        >
          <div style={{ float: "left", width: "50%" }}>
            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => {
                this.showUploadModal();
              }}
            >
              Bulk Upload
            </Button>
            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
                marginLeft: "15px",
              }}
              onClick={() => {
                this.generateCsv();
              }}
            >
              Export
            </Button>
          </div>
          <div style={{ float: "right" }}>
            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
                marginLeft: "15px",
              }}
              onClick={() => {
                this.showSingleUploadModal();
              }}
            >
              Single Upload
            </Button>
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <UserRecordsTable
                data={userList}
                primaryColor={primaryColor}
                userTotal={userTotal}
              />
            </div>
          )}
        </div>

        {this.state.uploadUserData ? (
          <UploadModal
            refx={(e) => (this.uploadModalRef = e)}
            onClose={this.handleDetailsUploadModalclose.bind(this)}
            onSubmit={this.uploadSubmit}
            authToken={authToken}
          />
        ) : null}

        {this.state.uploadSingleUserData ? (
          <AddSingleUser
            refx={(e) => (this.uploadSingleModalRef = e)}
            onClose={this.handleDetailsUploadSingleModalclose.bind(this)}
            onSubmit={this.uploadSingleSubmit}
            authToken={authToken}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserData: (listUserData) => {
      dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: listUserData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CharitySocialWorker));
