import React, { Fragment, Component } from "react";
import Title from "./Title";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllPaidTransactions } from "../../Graphs/Vendor/listPaidTransactions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import FilterVendorAmountRecordsTable from "./FilterVendorAmountRecordsTable";

class FilterVendorAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorAmountList: [],
      vendorAmountTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      type: "total_evoucher",
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText, history } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("VENDOR_AMOUNT");
      await setSearchText("");

      this.setState(
        {
          isLoading: true,
          primaryColor: editPrimaryColor,
        },
        async () => {
          await this.listVendorAmountData();
        }
      );
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listVendorAmountData = async () => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVendorAmountData,
        setListVendorAmountDataTotal,
        setRouteName,
        setSearchText,
        loginUserData,
      } = this.props;

      this.setState({
        isLoading: true,
      });

      let vendorAmountData = await getAllPaidTransactions(
        authToken,
        loginUserData.id,
        0,
        10
      );

      if (vendorAmountData.status == 200) {
        //set user data
        await setListVendorAmountData(vendorAmountData.data);
        await setListVendorAmountDataTotal(vendorAmountData.total);

        this.setState({
          vendorAmountList: vendorAmountData.data,
          vendorAmountTotal: vendorAmountData.total,
        });
      } else if (vendorAmountData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw vendorAmountData && vendorAmountData.message
          ? vendorAmountData.message
          : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, vendorAmountList, isLoading, vendorAmountTotal } =
      this.state;
    const { history } = this.props;

    return (
      <Fragment>
        <Title parent="Outstanding Amount" title={"Outstanding Amount"} />

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <FilterVendorAmountRecordsTable
                data={vendorAmountList}
                primaryColor={primaryColor}
                vendorAmountTotal={vendorAmountTotal}
                type={this.state.type}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVendorAmountData: (listVendorAmountData) => {
      dispatch({
        type: "SET_LIST_VENDOR_AMOUNT_DATA",
        listVendorAmountData: listVendorAmountData,
      });
    },
    setListVendorAmountDataTotal: (listVendorAmountDataTotal) => {
      dispatch({
        type: "SET_LIST_VENDOR_AMOUNT_DATA_TOTAL",
        listVendorAmountDataTotal: listVendorAmountDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterVendorAmount));
