import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, Spin } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { WEBURL, color } from "../../constant/comman";
import { PictureOutlined } from "@ant-design/icons";
import { AddVoucherExcel } from "./AddVoucherExcel";
import * as la from "lodash";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class UploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      errors: {
        uploadImage: "",
      },
    };
  }

  input;
  src = "";
  viewModalRef;

  uploadImage = (name) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  validateForm = (values) => {
    const errors = {};
    console.log("Validating form -- ", values);
    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values, action) => {
    const { errors } = this.state;
    let listErrors = { ...errors };

    if (!this.src || (this.src && this.src === "")) {
      listErrors.uploadImage = "Excel is Required";

      this.setState({
        errors: listErrors,
      });

      return;
    }

    if (this.src) {
      listErrors.uploadImage = "";

      this.setState({
        errors: listErrors,
      });
    }

    values.file = this.src;
    console.log("Basic Value - ", values, this.props.data.id);
    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.src = "";
  };

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{}}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return isLoading ? (
                  <div style={{ marginLeft: "20px" }}>
                    <Spin
                      size="large"
                      style={{ width: "100%", margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ float: "left", width: "10%" }}>
                          <PictureOutlined
                            style={{ width: "100%", fontSize: "30px" }}
                          />
                        </div>
                        <Button
                          style={{ width: "90%" }}
                          onClick={this.showViewModal.bind(this)} //this.showViewModal.bind(this)
                        >
                          Choose Excel file
                        </Button>
                        {this.src ? (
                          <p
                            style={{
                              fontSize: "small",
                              margin: "0",
                              marginLeft: "5px",
                            }}
                          >
                            {this.src}
                          </p>
                        ) : null}
                        {this.state.view ? (
                          <AddVoucherExcel
                            refx={(e) => (this.viewModalRef = e)}
                            onUpload={this.uploadImage}
                          />
                        ) : null}
                      </div>
                      <div width="100%" style={{ marginTop: "10px" }}>
                        {this.state.errors.uploadImage != "" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.uploadImage}
                          </p>
                        ) : null}
                      </div>

                      <Button
                        // type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
