import { callApiToServer } from "../callApi";

export async function deleteUser(authtoken, userId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      userId: userId,
    });

    let endUrl = "v1/auth/user";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "DELETE", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
