import { callGetApiToServer, callApiToServer } from "../callApi";

export async function getUserData(userId, role) {
  try {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const url = `v1/get-admin-user-detail/${userId}/${role}`;

    const responseData = await callGetApiToServer(header, "GET", url);

    return responseData;
  } catch (error) {
    throw error;
  }
}

export async function getUserCorppassData(nricFin, role) {
  try {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const url = `v1/get-admin-corppass-singpass-detail/${nricFin}/${role}`;

    const responseData = await callGetApiToServer(header, "GET", url);

    return responseData;
  } catch (error) {
    throw error;
  }
}

export async function getUserRolesData(nricFin) {
  try {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const url = `v1/get-user-role-detail/${nricFin}`;

    const responseData = await callGetApiToServer(header, "GET", url);

    return responseData;
  } catch (error) {
    throw error;
  }
}

export async function createUserSelectedRole(nricFin, role) {
  try {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const url = `v1/user/selected-role`;
    const body = JSON.stringify({
      nricFin,
      role,
      type: "corppass",
    });

    const responseData = await callApiToServer(body, header, "POST", url);

    return responseData;
  } catch (error) {
    throw error;
  }
}
