//CUSTOMIZER
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

//LOGIN AND COMMAN (auth)
export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_AUTHTOKEN = "SET_USER_AUTHTOKEN";
export const SET_ROUTE_NAME = "SET_ROUTE_NAME";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_LOADER = "SET_SEARCH_LOADER";

//CATEGORY TABLE (category)
export const SET_LIST_CATEGORY_DATA = "SET_LIST_CATEGORY_DATA";
export const SET_LIST_CATEGORY_DATA_TOTAL = "SET_LIST_CATEGORY_DATA_TOTAL";

//USER TABLE (vendor)
export const SET_LIST_VENDOR_DATA = "SET_LIST_VENDOR_DATA";
export const SET_LIST_VENDOR_DATA_TOTAL = "SET_LIST_VENDOR_DATA_TOTAL";

//CHARITY TABLE (charity)
export const SET_LIST_CHARITY_DATA = "SET_LIST_CHARITY_DATA";
export const SET_LIST_CHARITY_DATA_TOTAL = "SET_LIST_CHARITY_DATA_TOTAL";

//CHARITY PROGRAM TABLE (charity)
export const SET_LIST_CHARITY_PROGRAM_DATA = "SET_LIST_CHARITY_PROGRAM_DATA";
export const SET_LIST_CHARITY_PROGRAM_DATA_TOTAL =
  "SET_LIST_CHARITY_PROGRAM_DATA_TOTAL";

//USER TABLE (user)
export const SET_LIST_USER_DATA = "SET_LIST_USER_DATA";
export const SET_LIST_USER_DATA_TOTAL = "SET_LIST_USER_DATA_TOTAL";

//VOUCHER TABLE (vendor)
export const SET_LIST_VOUCHER_DATA = "SET_LIST_VOUCHER_DATA";
export const SET_LIST_VOUCHER_DATA_TOTAL = "SET_LIST_VOUCHER_DATA_TOTAL";

//VOUCHER_INVENTORY TABLE (vendor)
export const SET_LIST_VOUCHER_INVENTORY_DATA =
  "SET_LIST_VOUCHER_INVENTORY_DATA";
export const SET_LIST_VOUCHER_INVENTORY_DATA_TOTAL =
  "SET_LIST_VOUCHER_INVENTORY_DATA_TOTAL";

//STATIC PAGE TABLE (static page)
export const SET_LIST_STATIC_PAGE_DATA = "SET_LIST_STATIC_PAGE_DATA";
export const SET_LIST_STATIC_PAGE_DATA_TOTAL =
  "SET_LIST_STATIC_PAGE_DATA_TOTAL";

//LINKUP PAGE TABLE (Linkup)
export const SET_LIST_LINKUP_DATA = "SET_LIST_LINKUP_DATA";
export const SET_LIST_LINKUP_DATA_TOTAL = "SET_LIST_LINKUP_DATA_TOTAL";

//VENDOR LINKUP PAGE TABLE (Linkup)
export const SET_LIST_VENDOR_LINKUP_DATA = "SET_LIST_VENDOR_LINKUP_DATA";
export const SET_LIST_VENDOR_LINKUP_DATA_TOTAL =
  "SET_LIST_VENDOR_LINKUP_DATA_TOTAL";

//VENDOR_AMOUNT TABLE (vendor)
export const SET_LIST_VENDOR_AMOUNT_DATA = "SET_LIST_VENDOR_AMOUNT_DATA";
export const SET_LIST_VENDOR_AMOUNT_DATA_TOTAL =
  "SET_LIST_VENDOR_AMOUNT_DATA_TOTAL";

//STATIC PAGE TABLE (static page)
export const SET_LIST_SUB_USER_DATA = "SET_LIST_SUB_USER_DATA";
export const SET_LIST_SUB_USER_DATA_TOTAL = "SET_LIST_SUB_USER_DATA_TOTAL";
