import { Table, Button, Tooltip, notification, Spin } from "antd";
import * as React from "react";
import { getAllCharityProgram } from "../../Graphs/CharityProgram/listCharityProgram";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { DollarCircleOutlined } from "@ant-design/icons";
import { searchCharityProgram } from "../../Graphs/CharityProgram/searchCharityProgram";
import { AvailableCreditModal } from "../CharityProgram/AvailableCreditModal";

var moment = require("moment");

class CharityProgramRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCharityProgram = this.getLimitedCharityProgram.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      programAvailableData: null,
    });
  }

  modalAvailableCreditRef;

  async getLimitedCharityProgram(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchCharityProgramData(start, end);
        } else {
          //call normal user Data
          this.listCharityProgramData(start, end);
        }
      }
    );
  }

  listSearchCharityProgramData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCharityProgramData,
        setListCharityProgramDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let charityData = await searchCharityProgram(
        authToken,
        searchText,
        start,
        end
      );

      if (charityData.status === 200) {
        //set user Data
        await setListCharityProgramData(charityData.data);
        await setListCharityProgramDataTotal(charityData.total);

        this.setState({
          isLoading: false,
          data: charityData.data,
        });
      } else if (charityData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search charity Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCharityProgramData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCharityProgramData,
        setListCharityProgramDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let charityData = await getAllCharityProgram(authToken, start, end);

      if (charityData.status === 200) {
        //set user Data
        await setListCharityProgramData(charityData.data);
        await setListCharityProgramDataTotal(charityData.total);

        this.setState({
          isLoading: false,
          data: charityData.data,
        });
      } else if (charityData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list vendor Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showAvailableCreditModal(item) {
    this.setState(
      {
        programAvailableData: item,
        // isOpen: true
      },
      () => {
        this.modalAvailableCreditRef.show();
      }
    );
  }

  handleDetailsAvailableCreditModalclose() {
    this.setState({
      programAvailableData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Program Name",
      key: "Program Name",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Program Name">
                <div>{item.title ? item.title : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "short_description",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Short Description">
              <div>{item.short_description ? item.short_description : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Unassigned Credits",
      key: "Unassigned Credits",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Unassigned Credits">
              <div>
                {item.total_donation_left && item.total_donation_left > 0
                  ? `$ ${String(item.total_donation_left.toLocaleString())}`
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Assigned Credits",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div
              mr="5px"
              style={{ float: "left", marginRight: "5px", marginTop: "5px" }}
            >
              <Tooltip placement="bottom" title="View Assigned Credits">
                <Button
                  shape="circle"
                  icon={<DollarCircleOutlined />}
                  onClick={() => {
                    this.showAvailableCreditModal(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCharityProgram(pagination)
    );
  }

  render() {
    const { isLoading, data } = this.state;
    const {
      listCharityProgramData,
      listCharityProgramDataTotal,
      searchLoader,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listCharityProgramData}
          pagination={{
            total: listCharityProgramDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.programAvailableData ? (
          <AvailableCreditModal
            refx={(e) => (this.modalAvailableCreditRef = e)}
            onClose={this.handleDetailsAvailableCreditModalclose.bind(this)}
            programAvailableData={this.state.programAvailableData}
            authToken={this.props.authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCharityProgramData: state.charity_program.listCharityProgramData,
  listCharityProgramDataTotal:
    state.charity_program.listCharityProgramDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCharityProgramData: (listCharityProgramData) => {
      dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA",
        listCharityProgramData: listCharityProgramData,
      });
    },
    setListCharityProgramDataTotal: (listCharityProgramDataTotal) => {
      dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA_TOTAL",
        listCharityProgramDataTotal: listCharityProgramDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CharityProgramRecordsTable));
