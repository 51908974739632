import {
  Table,
  Button,
  Select,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
  DatePicker,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { listVoucherInventory } from "../../Graphs/Voucher/listVoucherInventory";
import { filterVoucherInventory } from "../../Graphs/Voucher/filterVoucherInvrntory";
import { getAllVoucherDropdown } from "../../Graphs/Voucher/listVoucherDropDown";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
var moment = require("moment");
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const Option = Select.Option;
class FilterVoucherRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      isFilterApply: false,
      startDate: null,
      endDate: null,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      voucherData: [],
      searchText: "",
      selectedVoucherId: undefined,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedVoucherInventory =
      this.getLimitedVoucherInventory.bind(this);
  }

  allVoucherType = [];

  financialYear = (value) => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    if (currentMonth < 3) {
      year -= 1;
    }

    const start = moment(`1/4/${year}`, "DD/MM/YYYY");
    const end = value ? moment() : moment(`31/03/${year + 1}`, "DD/MM/YYYY");
    return [start, end];
  };

  previousFinancialYear = () => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    const start = moment(`1/4/${year - 1}`, "DD/MM/YYYY");
    const end = moment(`31/3/${year}`, "DD/MM/YYYY");
    return [start, end];
  };

  onChangeDate = (date) => {
    if (!date.length) {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
    this.setState({
      startDate: date[0].format("YYYY-MM-DD HH:mm:ss"),
      endDate: date[1].format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  onFilter = async () => {
    try {
      const { startDate, endDate, pagination } = this.state;
      if (startDate && endDate) {
        this.setState(
          {
            isFilterApply: true,
          },
          async () => {
            await this.filterVoucherInventoryData(
              startDate,
              endDate,
              0,
              pagination.pageSize
            );
          }
        );
      } else {
        throw "Please select startDate and endDate";
      }
    } catch (e) {
      notification["error"]({
        message: "FILTER",
        description: typeof e === "string" ? e : "Error",
      });
    }
  };

  resetFilter = () => {
    const { pagination } = this.state;
    this.setState(
      {
        isFilterApply: false,
        startDate: null,
        endDate: null,
        selectedVoucherId: undefined,
        searchText: "",
      },
      async () => {
        await this.listVoucherInventoryData(0, pagination.pageSize);
      }
    );
  };

  async componentDidMount() {
    try {
      const { authToken } = this.props;
      this.setState({
        data: this.props.data,
        isLoading: false,
      });
      let voucherData = await getAllVoucherDropdown(authToken, 0, 100000);
      console.log("!!!!!!voucherData printed here", voucherData.data);

      voucherData.data &&
        voucherData.data.length > 0 &&
        voucherData.data.map((data, index) => {
          this.allVoucherType.push(
            <Option key={data.id} value={data.id}>
              {data.name}
            </Option>
          );
        });

      this.setState({
        voucherData: voucherData.data,
      });
    } catch (e) {
      console.log("!!!!Error in did mount", e);
    }
  }

  getLimitedVoucherInventory = async (pagination) => {
    const { isFilterApply, startDate, endDate } = this.state;

    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (isFilterApply) {
          this.filterVoucherInventoryData(startDate, endDate, start, end);
        } else {
          this.listVoucherInventoryData(start, end);
        }
      }
    );
  };

  generateCsv = async () => {
    try {
      const { authToken, type } = this.props;
      const {
        isFilterApply,
        searchText,
        selectedVoucherId,
        startDate,
        endDate,
      } = this.state;

      if (isFilterApply) {
        this.setState(
          {
            isLoading: true,
          },
          () => {
            filterVoucherInventory(
              authToken,
              type,
              0,
              100000000000000000,
              startDate,
              endDate,
              selectedVoucherId,
              searchText
            )
              .then((responseData) => {
                let csvArray = [];

                csvArray.push([
                  "Voucher Name",
                  "Voucher Code",
                  "Amount",
                  "Terms & Conditions",
                  "Associated Organization",
                  "Assigned Beneficiary",
                  "Redeemed Beneficiary",
                  "Redemption Date",
                  "Order Code",
                  "Expiry Date",
                  "Validity",
                ]);

                responseData.data.map((dataItem) => {
                  let termsString = "";
                  if (dataItem.voucher && dataItem.voucher.terms) {
                    // termsString = dataItem.voucher.terms.split("•").join("<br />•");
                    let termsArray = dataItem.voucher.terms.split("•");
                    termsArray.map((data, index) => {
                      if (data) {
                        if (index === termsArray.length - 1) {
                          termsString = termsString + data;
                        } else {
                          termsString = termsString + data + "<br />•";
                        }
                      } else {
                        termsString = termsString + "•";
                      }
                    });
                  }

                  csvArray.push([
                    dataItem.voucher
                      ? dataItem.voucher.name
                        ? dataItem.voucher.name
                        : "NA"
                      : "NA",
                    dataItem.redeem_code ? dataItem.redeem_code : "NA",
                    dataItem.voucher
                      ? dataItem.voucher.amount
                        ? `$ ${dataItem.voucher.amount}`
                        : "NA"
                      : "NA",
                    termsString ? `"${termsString}"` : "NA",
                    dataItem.charityUserData
                      ? dataItem.charityUserData.first_name
                        ? dataItem.charityUserData.first_name
                        : "NA"
                      : "NA",
                    dataItem.assignUserData
                      ? dataItem.assignUserData.first_name
                        ? dataItem.assignUserData.first_name
                        : "NA"
                      : "NA",
                    dataItem.usedUserData
                      ? dataItem.usedUserData.first_name
                        ? dataItem.usedUserData.first_name
                        : "NA"
                      : "NA",
                    dataItem.used_on
                      ? `"${this.dateFormat(dataItem.used_on)}"`
                      : "NA",
                    dataItem.is_paid
                      ? dataItem.voucher_transaction !== null
                        ? dataItem.voucher_transaction.order_code
                        : "-"
                      : "-",
                    dataItem.expiry_date
                      ? `"${this.dateFormat(dataItem.expiry_date)}"`
                      : "NA",
                    dataItem.voucher
                      ? `${dataItem.voucher.validity} Months`
                      : "NA",
                  ]);
                });

                const rows = csvArray;
                let csvName = "AllVouchers.csv";
                let csvContent = "data:text/csv;charset=utf-8,";

                rows.forEach(function (rowArray) {
                  let row = rowArray.join(",");
                  csvContent += row + "\r\n";
                });

                var encodedUri = encodeURI(csvContent);
                // // // // window.open(encodedUri);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", csvName);
                document.body.appendChild(link); // Required for FF

                link.click();

                this.setState({
                  isLoading: false,
                });
              })
              .catch((e) => {
                console.log("ERROR", e);
                this.setState({
                  isLoading: false,
                });
                notification["error"]({
                  message: "EXPORT DATA",
                  description:
                    typeof e === "string" ? e : "Something went wrong",
                });
                throw e;
              });
          }
        );

        return;
      }

      this.setState(
        {
          isLoading: true,
        },
        () => {
          listVoucherInventory(authToken, type, 0, 100000000000000000)
            .then((responseData) => {
              let csvArray = [];

              csvArray.push([
                "Voucher Name",
                "Voucher Code",
                "Amount",
                "Terms & Conditions",
                "Associated Organization",
                "Assigned Beneficiary",
                "Redeemed Beneficiary",
                "Redemption Date",
                "Order Code",
                "Expiry Date",
                "Validity",
              ]);

              responseData.data.map((dataItem) => {
                let termsString = "";
                if (dataItem.voucher && dataItem.voucher.terms) {
                  // termsString = dataItem.voucher.terms.split("•").join("<br />•");
                  let termsArray = dataItem.voucher.terms.split("•");
                  termsArray.map((data, index) => {
                    if (data) {
                      if (index === termsArray.length - 1) {
                        termsString = termsString + data;
                      } else {
                        termsString = termsString + data + "<br />•";
                      }
                    } else {
                      termsString = termsString + "•";
                    }
                  });
                }

                csvArray.push([
                  dataItem.voucher
                    ? dataItem.voucher.name
                      ? dataItem.voucher.name
                      : "NA"
                    : "NA",
                  dataItem.redeem_code ? dataItem.redeem_code : "NA",
                  dataItem.voucher
                    ? dataItem.voucher.amount
                      ? `$ ${dataItem.voucher.amount}`
                      : "NA"
                    : "NA",
                  termsString ? `"${termsString}"` : "NA",
                  dataItem.charityUserData
                    ? dataItem.charityUserData.first_name
                      ? dataItem.charityUserData.first_name
                      : "NA"
                    : "NA",
                  dataItem.assignUserData
                    ? dataItem.assignUserData.first_name
                      ? dataItem.assignUserData.first_name
                      : "NA"
                    : "NA",
                  dataItem.usedUserData
                    ? dataItem.usedUserData.first_name
                      ? dataItem.usedUserData.first_name
                      : "NA"
                    : "NA",
                  dataItem.used_on
                    ? `"${this.dateFormat(dataItem.used_on)}"`
                    : "NA",
                  dataItem.is_paid
                    ? dataItem.voucher_transaction !== null
                      ? dataItem.voucher_transaction.order_code
                      : "-"
                    : "-",
                  dataItem.expiry_date
                    ? `"${this.dateFormat(dataItem.expiry_date)}"`
                    : "NA",
                  dataItem.voucher
                    ? `${dataItem.voucher.validity} Months`
                    : "NA",
                ]);
              });

              const rows = csvArray;
              let csvName = "AllVouchers.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((e) => {
              console.log("ERROR", e);
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "EXPORT DATA",
                description: typeof e === "string" ? e : "Something went wrong",
              });
              throw e;
            });
        }
      );
    } catch (e) {
      notification["error"]({
        message: "EXPORT DATA",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      console.log("error in generateCsv function", e);
    }
  };

  filterVoucherInventoryData = async (startDate, endDate, start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVoucherInventoryData,
        setListVoucherInventoryDataTotal,
        loginUserData,
        type,
      } = this.props;
      const { searchText, selectedVoucherId } = this.state;

      this.setState({
        isLoading: true,
      });

      let voucherInventoryData = await filterVoucherInventory(
        authToken,
        type,
        start,
        end,
        startDate,
        endDate,
        selectedVoucherId,
        searchText
      );

      if (voucherInventoryData.status == 200) {
        //set user Data
        await setListVoucherInventoryData(voucherInventoryData.data);
        await setListVoucherInventoryDataTotal(voucherInventoryData.total);

        this.setState({
          isLoading: false,
          data: voucherInventoryData.data,
        });
      } else if (voucherInventoryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherInventoryData && voucherInventoryData.message
          ? voucherInventoryData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list FILTER VOUCHER INVENTORY Data printed here", e);
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listVoucherInventoryData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVoucherInventoryData,
        setListVoucherInventoryDataTotal,
        loginUserData,
        type,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let voucherInventoryData = await listVoucherInventory(
        authToken,
        type,
        start,
        end
      );

      if (voucherInventoryData.status == 200) {
        //set user Data
        await setListVoucherInventoryData(voucherInventoryData.data);
        await setListVoucherInventoryDataTotal(voucherInventoryData.total);

        this.setState({
          isLoading: false,
          data: voucherInventoryData.data,
        });
      } else if (voucherInventoryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherInventoryData && voucherInventoryData.message
          ? voucherInventoryData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list VOUCHER INVENTORY Data printed here", e);
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 60,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Voucher Name",
      key: "voucher_name",
      width: 180,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <Tooltip placement="bottom" title="Voucher Name">
                    <div>
                      {item.voucher
                        ? item.voucher.name
                          ? item.voucher.name
                          : "-"
                        : "-"}
                    </div>
                  </Tooltip>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Voucher Code",
      key: "redeem_code",
      width: 180,
      dataIndex: "redeem_code",
      render: (text, item, index) => {
        return (
          <div>
            {item.redeem_code ? (
              <Tooltip placement="bottom" title="Redeem Code">
                {item.redeem_code}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Amount",
      key: "voucher_amount",
      width: 180,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                {" "}
                <Tooltip placement="bottom" title="Voucher Amount">
                  <div>
                    {item.voucher
                      ? item.voucher.amount
                        ? `$ ${item.voucher.amount}`
                        : "-"
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Terms &  Conditions",
      key: "voucher_terms",
      render: (text, item, index) => {
        let termsString = "";
        if (item.voucher && item.voucher.terms) {
          // termsString = item.voucher.terms.split("•").join("<br />•");
          let termsArray = item.voucher.terms.split("•");
          termsArray.map((data, index) => {
            if (data) {
              if (index === termsArray.length - 1) {
                termsString = termsString + data;
              } else {
                termsString = termsString + data + "<br />•";
              }
            } else {
              termsString = termsString + "•";
            }
          });
        }
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                {" "}
                <Tooltip placement="bottom" title="Voucher Terms">
                  <div>{termsString ? renderHTML(termsString) : "-"}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Associated Organization",
      key: "charity_user_name",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                {" "}
                <Tooltip placement="bottom" title="Charity User Name">
                  <div>
                    {item.charityUserData
                      ? item.charityUserData.first_name
                        ? item.charityUserData.first_name
                        : "-"
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Assigned Beneficiary",
      key: "assigned_user_name",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                {" "}
                <Tooltip placement="bottom" title="Assigned User Name">
                  <div>
                    {item.assignUserData
                      ? item.assignUserData.first_name
                        ? item.assignUserData.first_name
                        : "-"
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Redeemed Beneficiary",
      key: "used_user_name",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                {" "}
                <Tooltip placement="bottom" title="Used User Name">
                  <div>
                    {item.usedUserData
                      ? item.usedUserData.first_name
                        ? item.usedUserData.first_name
                        : "-"
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Paid",
      key: "paid",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Paid">
                  <div>{item.is_paid ? "PAID" : "UNPAID"}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Redemption Date",
      key: "redeem_date",
      width: 180,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Redemption Date">
                  <div>
                    {item.used_on ? this.dateFormat(item.used_on) : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Order Code",
      key: "order_code",
      width: 180,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Order Code">
                  <div>
                    {item.is_paid
                      ? item.voucher_transaction !== null
                        ? item.voucher_transaction.order_code
                        : "-"
                      : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Expiry Date",
      width: 180,
      key: "expiry_date",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Expiry Date">
                  <div>
                    {item.expiry_date ? this.dateFormat(item.expiry_date) : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Validity",
      width: 180,
      key: "validity",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Validity">
                  <div>
                    {item.voucher ? `${item.voucher.validity} Months` : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedVoucherInventory(pagination)
    );
  }

  handleSelectVouchertype = (value, option) => {
    const { pagination } = this.state;
    this.setState(
      {
        selectedVoucherId: value,
        isFilterApply: true,
      },
      () => {
        this.getLimitedVoucherInventory(pagination);
      }
    );
  };

  onSearchChange = (searchText) => {
    this.setState({
      searchText: searchText,
    });
  };

  onSearch = () => {
    const { pagination } = this.state;
    this.setState(
      {
        isFilterApply: true,
      },
      () => {
        this.getLimitedVoucherInventory(pagination);
      }
    );
  };

  render() {
    const { isLoading, searchText } = this.state;
    const {
      listVoucherInventoryData,
      listVoucherInventoryDataTotal,
      searchLoader,
      authToken,
      type,
    } = this.props;

    const newColumn = this.columns.filter((data, index) => {
      if (type === "total_evoucher") {
        if (
          data.key === "charity_user_name" ||
          data.key === "assigned_user_name" ||
          data.key === "used_user_name" ||
          data.key === "paid" ||
          data.key === "order_code" ||
          data.key === "redeem_date" ||
          data.key === "expiry_date"
        ) {
          return false;
        }

        return true;
      } else if (type === "expiry_evoucher") {
        if (
          data.key === "used_user_name" ||
          data.key === "paid" ||
          data.key === "order_code" ||
          data.key === "redeem_date" ||
          data.key === "voucher_terms" ||
          data.key === "charity_user_name" ||
          data.key === "assigned_user_name"
        ) {
          return false;
        }

        return true;
      } else if (type === "unassigned_evoucher") {
        if (
          data.key === "charity_user_name" ||
          data.key === "assigned_user_name" ||
          data.key === "used_user_name" ||
          data.key === "paid" ||
          data.key === "order_code" ||
          data.key === "redeem_date" ||
          data.key === "expiry_date"
        ) {
          return false;
        }

        return true;
      } else if (type === "assigned_evoucher") {
        if (
          data.key === "used_user_name" ||
          data.key === "paid" ||
          data.key === "order_code" ||
          data.key === "redeem_date" ||
          data.key === "voucher_terms" ||
          data.key === "charity_user_name" ||
          data.key === "assigned_user_name"
        ) {
          return false;
        }

        return true;
      } else if (type === "used_evoucher") {
        if (
          data.key === "assigned_user_name" ||
          data.key === "paid" ||
          data.key === "expiry_date" ||
          data.key === "validity" ||
          data.key === "voucher_terms" ||
          data.key === "charity_user_name" ||
          data.key === "used_user_name" ||
          data.key === "order_code"
        ) {
          return false;
        }

        return true;
      }

      return true;
    });

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <div
          style={{
            float: "left",
            cursor: "pointer",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div
            onClick={() => this.generateCsv()}
            style={{
              width: "100px",
              backgroundColor: "#5C6AC4",
              color: "white",
              padding: "7px 0px",
              textAlign: "center",
              borderRadius: "0px",
              marginLeft: "0px",
              cursor: "pointer",
              marginBottom: "10px",
            }}
          >
            Export
          </div>
        </div>

        {type === "total_evoucher" ? null : (
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              width: "100%",
              display: "inline-block",
            }}
          >
            <div style={{ float: "left", width: "50%" }}>
              <RangePicker
                format="DD-MM-YYYY"
                onChange={this.onChangeDate}
                ranges={{
                  Today: [
                    moment().startOf("day"),
                    moment().add(1, "days").endOf("day"),
                  ],
                  Yesterday: [
                    moment().subtract(1, "days").startOf("day"),
                    moment().endOf("day"),
                    // moment()
                    //   .subtract(1, "days")
                    //   .endOf("day")
                  ],
                  "This Week": [
                    moment().startOf("week"),
                    moment().endOf("week"),
                  ],
                  "This Financial Year": this.financialYear(0),
                  "Previous Financial Year": this.previousFinancialYear(),
                }}
              />
            </div>
            <div style={{ float: "right" }}>
              <Button
                style={{
                  marginTop: "0px",
                  background: "#5C6AC4",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={this.onFilter}
              >
                Filter
              </Button>

              <Button
                style={{
                  marginTop: "0px",
                  background: "#5C6AC4",
                  color: "#fff",
                  cursor: "pointer",
                  marginLeft: "15px",
                }}
                onClick={() => {
                  this.resetFilter();
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        )}

        <div
          style={{
            marginBottom: "20px",
            width: "100%",
            display: "inline-block",
          }}
        >
          {this.allVoucherType && this.allVoucherType.length > 0 ? (
            <div style={{ float: "left", width: "15%" }}>
              <Select
                allowClear
                value={this.state.selectedVoucherId}
                style={{ width: "100%" }}
                onSelect={(value) => this.handleSelectVouchertype(value)}
                placeholder="Select Voucher"
                showSearch
                optionFilterProp="children"
                // onChange={(value) => {
                //   this.handleSelectVouchertype(value);
                // }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.allVoucherType}
              </Select>
            </div>
          ) : null}
          {type === "total_evoucher" ? (
            <div style={{ float: "right", marginLeft: "20px" }}>
              <Button
                style={{
                  marginTop: "0px",
                  background: "#5C6AC4",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.resetFilter();
                }}
              >
                Reset
              </Button>
            </div>
          ) : null}
          <div style={{ float: "right", marginLeft: "20px" }}>
            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={this.onSearch}
            >
              Search
            </Button>
          </div>
          <div style={{ float: "right", width: "15%", marginLeft: "20px" }}>
            <Input
              id="search"
              placeholder="Enter a keyword"
              value={searchText}
              onChange={(e) => {
                this.onSearchChange(e.target.value);
              }}
              autoFocus={false}
            />
          </div>
        </div>

        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={newColumn}
          // scroll={{
          // 	x:
          // 		type === 'total_evoucher' ||
          // 		type === 'unassigned_evoucher' ||
          // 		type === 'assigned_evoucher' ||
          // 		type === 'used_evoucher' ||
          // 		type === 'expiry_evoucher'
          // 			? 'none'
          // 			: 1300,
          // }}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listVoucherInventoryData}
          pagination={{
            total: listVoucherInventoryDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listVoucherInventoryData: state.voucher_inventory.listVoucherInventoryData,
  listVoucherInventoryDataTotal:
    state.voucher_inventory.listVoucherInventoryDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVoucherInventoryData: (listVoucherInventoryData) => {
      dispatch({
        type: "SET_LIST_VOUCHER_INVENTORY_DATA",
        listVoucherInventoryData: listVoucherInventoryData,
      });
    },
    setListVoucherInventoryDataTotal: (listVoucherInventoryDataTotal) => {
      dispatch({
        type: "SET_LIST_VOUCHER_INVENTORY_DATA_TOTAL",
        listVoucherInventoryDataTotal: listVoucherInventoryDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterVoucherRecordsTable));
