import { callApiToServer } from "../callApi";

export async function addCharityProgram(authtoken, charityProgramObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      title: charityProgramObject.title,
      short_description: charityProgramObject.short_description,
      long_description: charityProgramObject.long_description,
      start_date: charityProgramObject.start_date,
      end_date: charityProgramObject.end_date,
      venue: charityProgramObject.venue,
      contact_number: charityProgramObject.contact_number,
      contact_email: charityProgramObject.contact_email,
      contact_person_name: charityProgramObject.contact_person_name,
      categories: charityProgramObject.categories,
      cause_categories: charityProgramObject.cause_categories,
      icon_name: charityProgramObject.icon_name,
    });

    let endUrl = "v1/auth/charity-program";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
