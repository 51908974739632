import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllVoucher } from "../../Graphs/Voucher/listVoucher";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { deleteVoucher } from "../../Graphs/Voucher/deleteVoucher";
import { changeStatus } from "../../Graphs/Voucher/changeStatus";
import { changeFeatureStatus } from "../../Graphs/Voucher/changeFeatureStatus";
import {
  uploadRedeem,
  uploadSingleRedeem,
} from "../../Graphs/Voucher/uploadRedeem";
import { editVoucher } from "../../Graphs/Voucher/editVoucher";
import { searchVoucher } from "../../Graphs/Voucher/searchVoucher";
import { EditModal } from "./EditModal";
import { UploadModal } from "./UploadModal";
import { ViewRedeemModal } from "./ViewRedeemModal";
import { WEBURL } from "../../constant/comman";
import { AddRedeemCodeModal } from "./AddRedeemCodeModal";
import { DeleteRedeemModal } from "./DeleteRedeemModal";
import { ErrorCodeModal } from "./ErrorCodeModal";
var moment = require("moment");

class VoucherRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      uploadRedeemData: null,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      errorCodes: [],
      isErrorCodeShow: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedVouchers = this.getLimitedVouchers.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      cardSelectedData: null,
    });
  }

  modalRef;
  uploadModalRef;
  transaRef;
  deleteRedeemRef;
  modalErrorRef;

  async getLimitedVouchers(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchVoucherData(start, end);
        } else {
          //call normal user Data
          this.listVoucherData(start, end);
        }
      }
    );
  }

  listSearchVoucherData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVoucherData,
        setListVoucherDataTotal,
        searchText,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let voucherData = await searchVoucher(authToken, searchText, start, end);

      if (voucherData.status == 200) {
        //set user Data
        await setListVoucherData(voucherData.data);
        await setListVoucherDataTotal(voucherData.total);

        this.setState({
          isLoading: false,
          data: voucherData.data,
        });
      } else if (voucherData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherData && voucherData.message
          ? voucherData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list search voucher Data printed here", e);
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listVoucherData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListVoucherData,
        setListVoucherDataTotal,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let voucherData = await getAllVoucher(authToken, start, end);

      if (voucherData.status == 200) {
        //set user Data
        await setListVoucherData(voucherData.data);
        await setListVoucherDataTotal(voucherData.total);

        this.setState({
          isLoading: false,
          data: voucherData.data,
        });
      } else if (voucherData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherData && voucherData.message
          ? voucherData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list VOUCHER Data printed here", e);
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showUploadModal = (item) => {
    this.setState(
      {
        cardUploadSelectedData: item,
        // isOpen: true
      },
      () => {
        this.uploadModalRef.show();
      }
    );
  };

  handleDetailsUploadModalclose() {
    this.setState({
      cardUploadSelectedData: null,
    });
  }

  showRedeemCodeListModal = (item) => {
    this.setState(
      {
        uploadRedeemData: item,
      },
      () => {
        this.transaRef.show();
      }
    );
  };

  handleDeleteRedeemCodeModalclose() {
    this.setState({
      uploadRedeemData: null,
    });
  }

  showDeleteRedeemCodeListModal = (item) => {
    this.setState(
      {
        deleteRedeemCodeData: item,
      },
      () => {
        this.deleteRedeemRef.show();
      }
    );
  };

  handleDeleteRedeemCodeDeleteModalclose() {
    this.setState({
      deleteRedeemCodeData: null,
    });
  }

  handleSingleRedeemCodeModalclose() {
    this.setState({
      uploadSingleRedeemCode: null,
    });
  }

  showSingleRedeemCodeModal(item) {
    this.setState(
      {
        uploadSingleRedeemCode: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  showModal(item) {
    this.setState(
      {
        cardSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      cardSelectedData: null,
    });
  }

  showErrorCodesModal = (errorCodes) => {
    this.setState(
      {
        isErrorCodeShow: true,
        errorCodes: errorCodes,
      },
      () => {
        this.modalErrorRef.show();
      }
    );
  };

  handleDetailsErrorModalclose = () => {
    this.setState({
      isErrorCodeShow: false,
      errorCodes: [],
    });
  };

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 60,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      width: 180,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <Tooltip placement="bottom" title="Full Name">
                    <div>{item.name ? item.name : "-"}</div>
                  </Tooltip>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Vendor Name",
      key: "vendor_name",
      width: 150,
      fixed: true,
      dataIndex: "category_name",
      render: (text, item, index) => {
        return (
          <div>
            {item.category ? (
              <Tooltip placement="bottom" title="Vendor Name">
                {item.user && item.user.user_metadata
                  ? item.user.user_metadata.entity_name
                  : "-"}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Category Name",
      key: "category_name",
      width: 150,
      fixed: true,
      dataIndex: "category_name",
      render: (text, item, index) => {
        return (
          <div>
            {item.category ? (
              <Tooltip placement="bottom" title="Category Name">
                {item.category.name ? item.category.name : "-"}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      width: 190,
      dataIndex: "description",
      render: (text, item, index) => {
        return (
          <div>
            {item.description ? (
              <Tooltip placement="bottom" title="Description">
                {item.description}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (text, item, index) => {
        return (
          <div>
            {item.amount ? (
              <Tooltip placement="bottom" title="Amount">
                {`$ ${String(item.amount)}`}
              </Tooltip>
            ) : (
              "0"
            )}
          </div>
        );
      },
    },
    {
      title: "Validity",
      key: "validity",
      render: (text, item, index) => {
        return (
          <div>
            {item.validity ? (
              <Tooltip placement="bottom" title="Validity">
                {`${String(item.validity)} Months`}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Start Date",
      key: "start_date",
      render: (text, item, index) => {
        return (
          <div>
            {item.start_date ? (
              <Tooltip placement="bottom" title="Start Date">
                {this.dateFormat(item.start_date)}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "End Date",
      key: "end_date",
      render: (text, item, index) => {
        return (
          <div>
            {item.end_date ? (
              <Tooltip placement="bottom" title="End Date">
                {this.dateFormat(item.end_date)}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Is Paid",
    //   key: "is_paid",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         {item.is_paid ? (
    //           <Tooltip placement="bottom" title="Is Paid">
    //             {item.is_paid ? "PAID" : "UNPAID"}
    //           </Tooltip>
    //         ) : (
    //           "-"
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Available Count",
      key: "available_count",
      width: 120,
      dataIndex: "available_count",
      render: (text, item, index) => {
        return (
          <div>
            {item.available_count ? (
              <Tooltip placement="bottom" title="Available Count">
                {item.available_count}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Sold Count",
    //   key: "sold_count",
    //   dataIndex: "sold_count",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         {item.sold_count ? (
    //           <Tooltip placement="bottom" title="Sold Count">
    //             {item.sold_count}
    //           </Tooltip>
    //         ) : (
    //           "-"
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "isActive",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="Voucher Activation">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    // {
    //   title: "isFeature",
    //   key: "isFeature",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         {item.is_feature != null ? (
    //           <Tooltip placement="bottom" title="Card Featured">
    //             {
    //               <Switch
    //                 checked={item.is_feature}
    //                 style={{
    //                   backgroundColor: item.is_feature
    //                     ? this.props.primaryColor
    //                     : "#bababa",
    //                 }}
    //                 onChange={this.onFeatureChange.bind(this, item)}
    //               />
    //             }
    //           </Tooltip>
    //         ) : (
    //           "-"
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        const { loginUserData } = this.props;
        const { role } = loginUserData;

        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {role === "vendor" || role === "sub_vendor" ? (
              <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
                <Tooltip placement="bottom" title="Edit Voucher">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            {role === "vendor" || role === "sub_vendor" ? (
              <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
                <Tooltip placement="bottom" title="Add Single Voucher Code">
                  <Button
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showSingleRedeemCodeModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}

            {role === "vendor" || role === "sub_vendor" ? (
              <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
                <Tooltip placement="bottom" title="Bulk Upload">
                  <Button
                    shape="circle"
                    icon={<UploadOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showUploadModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            {role === "vendor" || role === "sub_vendor" || role === "admin" ? (
              <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
                <Tooltip placement="bottom" title="View Voucher Codes">
                  <Button
                    shape="circle"
                    icon={<EyeOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showRedeemCodeListModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            {role === "admin" ? (
              <div style={{ float: "left", marginRight: "5px" }}>
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteVoucher.bind(this, item)}
                    title="Are you sure you want to delete voucher?"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            ) : null}
            {role === "admin" ? (
              <div style={{ float: "left", marginRight: "5px" }}>
                <Tooltip placement="bottom" title="Delete Redeem Code">
                  <Button
                    shape="circle"
                    icon={<DeleteFilled />}
                    onClick={() => {
                      this.showDeleteRedeemCodeListModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  async deleteVoucher(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        setListVoucherData,
        setListVoucherDataTotal,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteVoucher(authToken, item.id);

      if (deleteData.status == 200) {
        notification["success"]({
          message: "VOUCHER",
          description: "Successfully deleted",
        });
        if (searchText != null && searchText != "") {
          this.listSearchVoucherData(startIndex, limitNumber);
          return;
        }
        this.listVoucherData(startIndex, limitNumber);
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "VOUCHER",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchVoucherData(startIndex, limitNumber);
          return;
        }
        this.listVoucherData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onFeatureChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeFeatureStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "VOUCHER",
          description: "Successfully changed featured status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchVoucherData(startIndex, limitNumber);
          return;
        }
        this.listVoucherData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (voucherObject, voucherId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;
      console.log("!!!!!!!!!voucherObject", voucherObject);

      this.setState({
        isLoading: true,
      });
      let statusData = await editVoucher(authToken, voucherObject, voucherId);
      if (statusData.status == 200) {
        notification["success"]({
          message: "VOUCHER",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchVoucherData(startIndex, limitNumber);
          return;
        }
        this.listVoucherData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedVouchers(pagination)
    );
  }

  uploadSubmit = async (voucherObject, voucherId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      console.log("!!!!!!!!voucherObject", voucherObject, voucherId);

      this.setState({
        isLoading: true,
      });
      let statusData = await uploadRedeem(authToken, voucherObject, voucherId);
      if (statusData.status == 200) {
        notification["success"]({
          message: "VOUCHER",
          description: "Successfully uploaded",
        });

        if (searchText != null && searchText != "") {
          await this.listSearchVoucherData(startIndex, limitNumber);
          if (statusData.errorCodes && statusData.errorCodes.length > 0) {
            this.showErrorCodesModal(statusData.errorCodes);
          }
          return;
        }
        await this.listVoucherData(startIndex, limitNumber);
        if (statusData.errorCodes && statusData.errorCodes.length > 0) {
          this.showErrorCodesModal(statusData.errorCodes);
        }
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  uploadSingleSubmit = async (value) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      console.log("!!!!!!!!value", value);

      this.setState({
        isLoading: true,
      });
      let statusData = await uploadSingleRedeem(authToken, value);
      if (statusData.status == 200) {
        notification["success"]({
          message: "VOUCHER",
          description: "Successfully Added",
        });
        if (searchText != null && searchText != "") {
          this.listSearchVoucherData(startIndex, limitNumber);
          return;
        }
        this.listVoucherData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!!!error in Upload Redeem function", e);
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  refreshData = async () => {
    try {
      const { searchText } = this.props;
      const { startIndex, limitNumber } = this.state;

      if (searchText != null && searchText != "") {
        this.listSearchVoucherData(startIndex, limitNumber);
        return;
      }
      this.listVoucherData(startIndex, limitNumber);
    } catch (e) {
      notification["error"]({
        message: "VOUCHER",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    }
  };

  render() {
    const {
      isLoading,
      uploadRedeemData,
      uploadSingleRedeemCode,
      deleteRedeemCodeData,
      isErrorCodeShow,
      errorCodes,
    } = this.state;
    const {
      listVoucherData,
      listVoucherDataTotal,
      searchLoader,
      authToken,
      loginUserData,
    } = this.props;
    const { role } = loginUserData;
    console.log("!!!!!isErrorCodeShow", isErrorCodeShow);
    let filterColumns = this.columns;

    if (role === "vendor" || role === "sub_vendor") {
      filterColumns = this.columns.filter((data, index) => {
        return !(data.key === "vendor_name");
      });
    }

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={filterColumns}
          scroll={{ x: 1500 }}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listVoucherData}
          pagination={{
            total: listVoucherDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.cardSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            cardSelectedData={this.state.cardSelectedData}
            onSubmit={this.editSubmit}
            authToken={authToken}
            loginUserData={this.props.loginUserData}
          />
        ) : null}
        {this.state.cardUploadSelectedData ? (
          <UploadModal
            admin={false}
            refx={(e) => (this.uploadModalRef = e)}
            onClose={this.handleDetailsUploadModalclose.bind(this)}
            cardUploadSelectedData={this.state.cardUploadSelectedData}
            onSubmit={this.uploadSubmit}
            authToken={authToken}
          />
        ) : null}
        {uploadRedeemData ? (
          <ViewRedeemModal
            admin={false}
            refx={(e) => (this.transaRef = e)}
            onClose={this.handleDeleteRedeemCodeModalclose.bind(this)}
            uploadRedeemData={uploadRedeemData}
            authToken={authToken}
          />
        ) : null}
        {deleteRedeemCodeData ? (
          <DeleteRedeemModal
            admin={false}
            refx={(e) => (this.deleteRedeemRef = e)}
            onClose={this.handleDeleteRedeemCodeDeleteModalclose.bind(this)}
            deleteRedeemCodeData={deleteRedeemCodeData}
            refreshData={this.refreshData}
            authToken={authToken}
          />
        ) : null}
        {uploadSingleRedeemCode ? (
          <AddRedeemCodeModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleSingleRedeemCodeModalclose.bind(this)}
            onSubmit={this.uploadSingleSubmit}
            data={uploadSingleRedeemCode}
            authToken={authToken}
          />
        ) : null}

        {errorCodes.length || isErrorCodeShow ? (
          <ErrorCodeModal
            admin={false}
            refx={(e) => (this.modalErrorRef = e)}
            onClose={this.handleDetailsErrorModalclose.bind(this)}
            errorCodes={errorCodes}
            authToken={authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listVoucherData: state.voucher.listVoucherData,
  listVoucherDataTotal: state.voucher.listVoucherDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVoucherData: (listVoucherData) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA",
        listVoucherData: listVoucherData,
      });
    },
    setListVoucherDataTotal: (listVoucherDataTotal) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA_TOTAL",
        listVoucherDataTotal: listVoucherDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VoucherRecordsTable));
