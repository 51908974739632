import {
  SET_LIST_VENDOR_LINKUP_DATA,
  SET_LIST_VENDOR_LINKUP_DATA_TOTAL,
} from "../constant/actionTypes";

const initial_state = {
  listVendorLinkupData: null,
  listVendorLinkupDataTotal: 0,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_LIST_VENDOR_LINKUP_DATA:
      return {
        ...state,
        loading: false,
        listVendorLinkupData: action.listVendorLinkupData,
      };

    case SET_LIST_VENDOR_LINKUP_DATA_TOTAL:
      return {
        ...state,
        loading: false,
        listVendorLinkupDataTotal: action.listVendorLinkupDataTotal,
      };

    default:
      return { ...state };
  }
};
