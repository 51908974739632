import { callApiToServer } from "../callApi";

export async function editUserMetadata(authtoken, adminData) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      email: adminData.user_metadata.email,
      mobile_number: adminData.user_metadata.mobile_number,
      about_us: adminData.user_metadata.about_us,
      display_address: adminData.user_metadata.display_address,
      website_link: adminData.user_metadata.website_link,
    });

    let endUrl = "v1/auth/update-metadata";

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
