import { Table, Tooltip, notification, Spin, Button } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllPaidTransactions } from "../../Graphs/Vendor/listPaidTransactions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
var moment = require("moment");

class TransactionRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.listVendorTransactionData(0, 5);
  }

  getLimitedVendorTransaction = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listVendorTransactionData(start, end);
      }
    );
  };

  listVendorTransactionData = async (start, end) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });

      let programData = await getAllPaidTransactions(
        authToken,
        this.props.data.id,
        start,
        end
      );

      if (programData.status == 200) {
        this.setState({
          isLoading: false,
          data: programData.data,
          dataTotal: programData.total,
        });
      } else if (programData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw programData && programData.message
          ? programData.message
          : "Error";
      }
    } catch (e) {
      notification["error"]({
        message: "VENDOR_TRANSACTION",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedVendorTransaction(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 80,
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Amount">
                <div>{item.amount ? `$ ${item.amount}` : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Type">
              <div>
                {item.type
                  ? item.type === "credit"
                    ? "Debit"
                    : "Credit"
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Transaction At",
      key: "Transaction At",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Transaction At">
              <div>
                {item.transaction_at
                  ? this.dateFormat(item.transaction_at)
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   title: "Order Code",
    //   key: "order_code",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         <Tooltip placement="bottom" title="Order Code">
    //           <div>{item.order_code ? item.order_code : "-"}</div>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Invoice Number",
      key: "invoice_number",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Invoice Number">
              <div>{item.invoice_number ? item.invoice_number : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "PO Number",
      key: "po_number",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="PO Number">
              <div>{item.po_number ? item.po_number : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Transaction Number",
      key: "transaction_number",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Transaction Number">
              <div>
                {item.transaction_number ? item.transaction_number : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  render() {
    const { isLoading, data, dataTotal } = this.state;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          dataSource={data}
          pagination={{
            total: dataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "5", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TransactionRecordsTable));
