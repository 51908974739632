import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DollarSign, Gift, Upload, CheckCircle, XCircle } from "react-feather";
import { callAllKpis } from "../../Graphs/Dashboard/callVendorKpis";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, notification, Spin } from "antd";
import { VoucherDetailModal } from "./VoucherDetailModal";

class SubVendorHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCount: 0,
      unAssignedVoucherCodeCount: 0,
      AssignedVoucherCodeCount: 0,
      RedeemedVoucherCodeCount: 0,
      expiryVoucherCodeCount: 0,
      redeemedAmount: 0,
      uploadedAmount: 0,
      totalNeedyFamilyAssignedVoucher: 0,
      totalVoucherCodeCount: 0,
      outStandingAmount: 0,
      isLoading: true,
      redeemCode: "",
      isVoucherDetail: false,
    };
  }

  async componentDidMount() {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setRouteName,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      if (loginUserData.role === "vendor" && !loginUserData.master_vendor_id) {
        history.push(`${process.env.PUBLIC_URL}/home`);
      }

      await setRouteName("DASHBOARD");
      let responseKpiData = await callAllKpis(authToken);

      if (responseKpiData.status === 200) {
        //set kpi data
        this.setState({
          voucherCount: responseKpiData.data.voucherCount,
          unAssignedVoucherCodeCount:
            responseKpiData.data.unAssignedVoucherCodeCount,
          AssignedVoucherCodeCount:
            responseKpiData.data.AssignedVoucherCodeCount,
          RedeemedVoucherCodeCount:
            responseKpiData.data.RedeemedVoucherCodeCount,
          expiryVoucherCodeCount: responseKpiData.data.expiryVoucherCodeCount,
          redeemedAmount: responseKpiData.data.redeemedAmount,
          uploadedAmount: responseKpiData.data.uploadedAmount,
          totalNeedyFamilyAssignedVoucher:
            responseKpiData.data.totalNeedyFamilyAssignedVoucher,
          totalVoucherCodeCount: responseKpiData.data.totalVoucherCodeCount,
          outStandingAmount: responseKpiData.data.outStandingAmount,
        });
      } else if (responseKpiData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!error in did mount", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  onChange = (text) => {
    this.setState({
      redeemCode: text.target.value,
    });
  };

  modalRef;
  openVoucherDetailModal = () => {
    try {
      const { redeemCode } = this.state;
      if (!redeemCode) {
        throw "Please enter Redeem code";
      }

      this.setState(
        {
          isVoucherDetail: true,
          // isOpen: true
        },
        () => {
          this.modalRef.show();
        }
      );
    } catch (e) {
      notification["error"]({
        message: "Home",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    }
  };

  handleDetailsModalclose() {
    this.setState({
      redeemCode: "",
      isVoucherDetail: false,
    });
  }

  render() {
    const {
      voucherCount,
      unAssignedVoucherCodeCount,
      AssignedVoucherCodeCount,
      RedeemedVoucherCodeCount,
      expiryVoucherCodeCount,
      redeemCode,
      isLoading,
      isVoucherDetail,
      redeemedAmount,
      uploadedAmount,
      totalNeedyFamilyAssignedVoucher,
      totalVoucherCodeCount,
      outStandingAmount,
    } = this.state;

    return (
      <Fragment>
        {/* <Breadcrumb parent="Dashboard" title="Dashboard" /> */}
        <div
          className="container-fluid"
          style={{ marginTop: "40px", display: "inline-block" }}
        >
          <div className="row">
            <div className="col-sm-6 col-xl-4 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-primary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <DollarSign />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Redeemed Amount</span>
                      <h4 className="mb-0 counter">
                        {/* <CountUp className="counter" end={`$ ${redeemedAmount}`} />
                         */}
                        {/* <CountUp className="counter" end={`$ 25,000`} /> */}
                        <span>{`$ ${redeemedAmount.toLocaleString()}`}</span>
                      </h4>
                      <DollarSign className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4 col-lg-6">
              <div className="card o-hidden">
                <Link to="/redeemed_evoucher">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <CheckCircle />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Redeemed e-Vouchers</span>
                        <h4 className="mb-0 counter">
                          {/* <CountUp
                            className="counter"
                            end={RedeemedVoucherCodeCount}
                          /> */}
                          <span>{`${RedeemedVoucherCodeCount}`}</span>
                        </h4>
                        <CheckCircle className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4 col-lg-6">
              <div className="card o-hidden">
                <Link to="/expiry_evoucher">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <XCircle />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Expired e-Vouchers</span>
                        <h4 className="mb-0 counter">
                          {/* <CountUp
                            className="counter"
                            end={expiryVoucherCodeCount}
                          /> */}
                          <span>{`${expiryVoucherCodeCount}`}</span>
                        </h4>
                        <XCircle className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {/* Redeem Voucher */}
          <div>
            {isLoading ? (
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            ) : (
              <div>
                {/* <div
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  <span>Redeem Voucher</span>
                </div>
                <div style={{ width: "20%", marginBottom: "10px" }}>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Redeem Code"
                    required
                    value={redeemCode}
                    onChange={(text) => this.onChange(text)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.openVoucherDetailModal();
                      }
                    }}
                  />
                </div>
                <div>
                  <Button
                    type="primary"
                    style={{
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                    onClick={() => {
                      this.openVoucherDetailModal();
                    }}
                  >
                    Redeem
                  </Button>
                </div> */}
              </div>
            )}
          </div>
          {isVoucherDetail ? (
            <VoucherDetailModal
              refx={(e) => (this.modalRef = e)}
              onClose={this.handleDetailsModalclose.bind(this)}
              redeemCode={redeemCode}
              authToken={this.props.authToken}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubVendorHome));
