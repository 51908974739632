import React, { useState, Fragment } from "react";
import logo from "../../../assets/images/endless-logo.png";
import Language from "./language";
import UserMenu from "./userMenu";
import Notification from "./notification";
import SearchHeader from "./searchHeader";
import { Link } from "react-router-dom";
import {
  AlignLeft,
  Maximize,
  Bell,
  MessageCircle,
  MoreHorizontal,
  Search,
} from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { searchCategory } from "../../../Graphs/Category/searchCategory";
import { searchStaticPage } from "../../../Graphs/StaticPage/searchStaticPage";
import { getAllSearchSubUser } from "../../../Graphs/SubUser/listSearchSubUser";
import { searchLinkup } from "../../../Graphs/Linkup/searchLinkup";
import { searchVendorLinkup } from "../../../Graphs/VendorLinkup/searchVendorLinkup";
import { searchVendor } from "../../../Graphs/Vendor/searchVendor";
import { searchCharity } from "../../../Graphs/Charity/searchCharity";
import { searchCharityProgram } from "../../../Graphs/CharityProgram/searchCharityProgram";
import { searchUser } from "../../../Graphs/User/searchUser";
import { searchVoucher } from "../../../Graphs/Voucher/searchVoucher";
import { searchNeedyFamily } from "../../../Graphs/NeedyFamily/searchNeedyFamily";
import { searchSocialWorker } from "../../../Graphs/SocialWorker/searchSocialWorker";
import Logo from "../../../assets/images/six_logo.png";
import { Tooltip } from "antd";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);

  const primaryColor = localStorage.getItem("primary_color");

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
    } else {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    }
  };

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.add("show");
    } else {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.remove("show");
    }
  }

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const dispatch = useDispatch();
  const searchText = useSelector((content) => content.auth.searchText);
  const routeName = useSelector((content) => content.auth.routeName);
  const authToken = useSelector((content) => content.auth.authToken);
  const adminRole = useSelector((content) =>
    content.auth.loginUserData ? content.auth.loginUserData.role : "SUPERADMIN"
  );

  async function handleSearchKeyword(textValue) {
    console.log("!!!!!!!!!!!onchange method", textValue, searchText);

    if (routeName === "CATEGORY") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCategoryData(textValue);
    } else if (routeName === "STATIC_PAGE") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchStaticPageData(textValue);
    } else if (routeName === "SUB_USER") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchSubUserData(textValue);
    } else if (routeName === "LINKUP") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchLinkupData(textValue);
    } else if (routeName === "VENDOR_LINKUP") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchVendorLinkupData(textValue);
    } else if (routeName === "VENDOR") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchVendorData(textValue);
    } else if (routeName === "CHARITY") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCharityData(textValue);
    } else if (routeName === "CHARITY_PROGRAM") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCharityProgramData(textValue);
    } else if (routeName === "NEEDY_FAMILY") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchUserData(textValue, "needy_family");
    } else if (routeName === "SOCIAL_WORKER") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchUserData(textValue, "social_worker");
    } else if (routeName === "CONTRIBUTOR") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchUserData(textValue, "contributor");
    } else if (routeName === "VOUCHER") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchVoucherData(textValue);
    } else if (routeName === "CHARITY_NEEDY_FAMILY") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchNeedyFamilyData(textValue);
    } else if (routeName === "CHARITY_SOCIAL_WORKER") {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: textValue,
      });
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchSocialWorkerData(textValue);
    }
  }

  async function listSearchSocialWorkerData(textValue) {
    let userData = await searchSocialWorker(authToken, textValue, 0, 10);

    if (userData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: userData.data,
      });
      await dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: userData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchNeedyFamilyData(textValue) {
    let userData = await searchNeedyFamily(authToken, textValue, 0, 10);

    if (userData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: userData.data,
      });
      await dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: userData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchVoucherData(textValue) {
    let voucherData = await searchVoucher(authToken, textValue, 0, 10);

    if (voucherData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_VOUCHER_DATA",
        listVoucherData: voucherData.data,
      });
      await dispatch({
        type: "SET_LIST_VOUCHER_DATA_TOTAL",
        listVoucherDataTotal: voucherData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchUserData(textValue, role) {
    let userData = await searchUser(authToken, textValue, 0, 10, role);

    if (userData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: userData.data,
      });
      await dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: userData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCharityProgramData(textValue) {
    let charityProgramData = await searchCharityProgram(
      authToken,
      textValue,
      0,
      10
    );

    if (charityProgramData.status === 200) {
      //set charity program data
      await dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA",
        listCharityProgramData: charityProgramData.data,
      });
      await dispatch({
        type: "SET_LIST_CHARITY_PROGRAM_DATA_TOTAL",
        listCharityProgramDataTotal: charityProgramData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCharityData(textValue) {
    let charityData = await searchCharity(authToken, textValue, 0, 10);

    if (charityData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_CHARITY_DATA",
        listCharityData: charityData.data,
      });
      await dispatch({
        type: "SET_LIST_CHARITY_DATA_TOTAL",
        listCharityDataTotal: charityData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchVendorData(textValue) {
    let vendorData = await searchVendor(authToken, textValue, 0, 10);

    if (vendorData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_VENDOR_DATA",
        listVendorData: vendorData.data,
      });
      await dispatch({
        type: "SET_LIST_VENDOR_DATA_TOTAL",
        listVendorDataTotal: vendorData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchLinkupData(textValue) {
    let linkupData = await searchLinkup(authToken, textValue, 0, 10);

    if (linkupData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_LINKUP_DATA",
        listLinkupData: linkupData.data,
      });
      await dispatch({
        type: "SET_LIST_LINKUP_DATA_TOTAL",
        listLinkupDataTotal: linkupData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchVendorLinkupData(textValue) {
    let linkupData = await searchVendorLinkup(authToken, textValue, 0, 10);

    if (linkupData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_VENDOR_LINKUP_DATA",
        listVendorLinkupData: linkupData.data,
      });
      await dispatch({
        type: "SET_LIST_VENDOR_LINKUP_DATA_TOTAL",
        listVendorLinkupDataTotal: linkupData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchStaticPageData(textValue) {
    let staticPageData = await searchStaticPage(authToken, textValue, 0, 10);

    if (staticPageData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_STATIC_PAGE_DATA",
        listStaticPageData: staticPageData.data,
      });
      await dispatch({
        type: "SET_LIST_STATIC_PAGE_DATA_TOTAL",
        listStaticPageDataTotal: staticPageData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchSubUserData(textValue) {
    let subUserData = await getAllSearchSubUser(authToken, textValue, 0, 10);

    if (subUserData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_SUB_USER_DATA",
        listSubUserData: subUserData.data,
      });
      await dispatch({
        type: "SET_LIST_SUB_USER_DATA_TOTAL",
        listSubUserDataTotal: subUserData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCategoryData(textValue) {
    let categoryData = await searchCategory(authToken, textValue, 0, 10);

    if (categoryData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_CATEGORY_DATA",
        listCategoryData: categoryData.data,
      });
      await dispatch({
        type: "SET_LIST_CATEGORY_DATA_TOTAL",
        listCategoryDataTotal: categoryData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  return (
    <Fragment>
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link
                to={
                  adminRole === "vendor" || adminRole === "sub_vendor"
                    ? "/home"
                    : adminRole === "charity" || adminRole === "sub_charity"
                    ? "/organization-home"
                    : "/dashboard"
                }
              >
                <img className="header-logo" src={Logo} alt="logo" />
              </Link>
            </div>
          </div>

          <div className="mobile-sidebar d-block">
            <Tooltip placement="bottom" title="Show/Hide Menu">
              <div className="media-body text-right switch-sm">
                <label className="switch">
                  <a href="#javascript" onClick={() => openCloseSidebar()}>
                    <AlignLeft />
                  </a>
                </label>
              </div>
            </Tooltip>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? "" : "open"}`}>
              <li>
                <Fragment>
                  <div>
                    {routeName === "DASHBOARD" ||
                    routeName === "VOUCHER_INVENTORY" ||
                    routeName === "VENDOR_AMOUNT" ||
                    routeName === "CHARITY_SETTING" ||
                    routeName === "VENDOR_SETTING" ||
                    routeName === "HOME" ? null : (
                      <div className="form-inline search-form">
                        <div className="form-group">
                          <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder={
                              routeName === "CATEGORY"
                                ? "Search Categories"
                                : routeName === "STATIC_PAGE"
                                ? "Search Static Pages"
                                : routeName === "SUB_USER"
                                ? "Search Sub User"
                                : routeName === "LINKUP"
                                ? "Search corppassId or singpassId"
                                : routeName === "VENDOR_LINKUP"
                                ? "Search corppassId or singpassId"
                                : routeName === "VENDOR"
                                ? "Search Vendors"
                                : routeName === "CHARITY"
                                ? "Search Organizations"
                                : routeName === "NEEDY_FAMILY"
                                ? "Search Beneficiaries"
                                : routeName === "SOCIAL_WORKER"
                                ? "Search Supervisors"
                                : routeName === "CONTRIBUTOR"
                                ? "Search Contributors"
                                : routeName === "VOUCHER"
                                ? "Search Vouchers"
                                : routeName === "CHARITY_NEEDY_FAMILY"
                                ? "Search Beneficiaries"
                                : routeName === "CHARITY_SOCIAL_WORKER"
                                ? "Search Supervisors"
                                : routeName === "CHARITY_PROGRAM"
                                ? "Search Programs"
                                : "Search"
                            }
                            onChange={(e) =>
                              handleSearchKeyword(e.target.value)
                            }
                          />
                          <span className="d-sm-none mobile-search">
                            <Search />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment>
              </li>
              {/* <li>
                <SearchHeader />
              </li> */}
              {/* <li>
                <a onClick={goFull} className="text-dark" href="#!">
                  <Maximize />
                </a>
              </li> */}
              {/* <li className="onhover-dropdown">
                <a className="txt-dark" href="#javascript">
                  <h6>EN</h6></a>
                <Language />
              </li>
              <li className="onhover-dropdown">
                <Notification />
                <Bell />
                <span className="dot"></span>
                <Notification />
              </li> */}
              <li style={{ marginRight: "0px" }}>
                <Tooltip placement="bottom" title="View Full Screen">
                  {/* <a href="#javascript" onClick={showRightSidebar}>
                  <MessageCircle />
                  <span className="dot"></span>
                </a> */}
                  <a onClick={goFull} className="text-dark" href="#!">
                    <Maximize />
                  </a>
                </Tooltip>
              </li>
              <UserMenu />
            </ul>
            <div
              className="d-lg-none mobile-toggle pull-right"
              onClick={() => setHeaderbar(!headerbar)}
            >
              <MoreHorizontal />
            </div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-airplay m-0"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">
              Your search turned up 0 results. This most likely means the
              backend is down, yikes!
            </div>
          </script>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
