import { callApiToServer } from "../callApi";

export async function editSubUser(authtoken, subUserObject, subUserId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      subUserId: subUserId,
      email: subUserObject.email,
      password: subUserObject.password,
    });

    let endUrl = "v1/auth/sub-user";

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
