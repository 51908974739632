import { callGetApiToServer } from "../callApi";

export async function listVoucherInventory(
  authtoken,
  type,
  skipNumber,
  limitNumber
) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl =
      "v1/auth/list-voucher-inventory-from-voucher-comman/" +
      type +
      "/" +
      skipNumber +
      "/" +
      limitNumber;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
