import {
  Home,
  Settings,
  Users,
  List,
  Info,
  MessageSquare,
  Tag,
  User,
} from "react-feather";

export const MENUADMINITEMS = [
  {
    title: "Dashboard",
    icon: List,
    type: "link",
    path: "/dashboard",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Categories",
    icon: Tag,
    type: "link",
    path: "/category",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Vendors",
    icon: Users,
    type: "link",
    path: "/vendor",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Organizations",
    icon: Users,
    type: "link",
    path: "/organization",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Beneficiaries",
    icon: User,
    type: "link",
    path: "/beneficiary",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Supervisors",
    icon: User,
    type: "link",
    path: "/social-worker",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Contributors",
    icon: User,
    type: "link",
    path: "/contributor",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Vouchers",
    icon: MessageSquare,
    type: "link",
    path: "/voucher",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Static Pages",
    icon: Tag,
    type: "link",
    path: "/static-pages",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Linkups",
    icon: Tag,
    type: "link",
    path: "/linkup",
    active: true,
    superadmin: true,
  },
  // {
  //     title: 'Dashboard', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [
  //         { path: '/dashboard/default', title: 'Default', type: 'link' },
  //         { path: '/dashboard/ecommerce', title: 'E-Commerce', type: 'link' },
  //         { path: '/dashboard/university', title: 'University', type: 'link' },
  //         { path: '/dashboard/crypto', title: 'Crypto', type: 'link' },
  //         { path: '/dashboard/project', title: 'Project', type: 'link' }
  //     ]
  // },
  // {
  //     title: 'Support Ticket', icon: Headphones, type: 'link', path: '/support-ticket/supportTicket', active: false
  // },
  // {
  //     path: '/sample/samplepage', title: 'Sample Page', icon: File, type: 'link', active: false
  // },
];

export const VENDORMENUITEMS = [
  {
    title: "Dashboard",
    icon: List,
    type: "link",
    path: "/home",
    active: true,
    superadmin: true,
  },
  {
    title: "Dashboard",
    icon: List,
    type: "link",
    path: "/home-vendor",
    active: true,
    superadmin: false,
  },
  {
    title: "Manage Vouchers",
    icon: MessageSquare,
    type: "link",
    path: "/voucher",
    active: true,
    superadmin: true,
  },
  {
    title: "My Business Info",
    icon: Settings,
    type: "link",
    path: "/vendor-setting",
    active: true,
    superadmin: true,
  },
  // {
  //   title: "Manage Sub Users",
  //   icon: Users,
  //   type: "link",
  //   path: "/sub-user",
  //   active: true,
  //   superadmin: true,
  // },
  {
    title: "Manage Linkups",
    icon: Settings,
    type: "link",
    path: "/vendor-linkup",
    active: true,
    superadmin: true,
  },
];

export const CHARITYMENUITEMS = [
  {
    title: "Dashboard",
    icon: List,
    type: "link",
    path: "/organization-home",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Beneficiaries",
    icon: Users,
    type: "link",
    path: "/organization-beneficiaries",
    active: true,
    superadmin: false,
  },
  {
    title: "Manage Supervisors",
    icon: Users,
    type: "link",
    path: "/organization-social-worker",
    active: true,
    superadmin: false,
  },
  {
    title: "Manage Programs",
    icon: Info,
    type: "link",
    path: "/organization-program",
    active: true,
    superadmin: false,
  },
  {
    title: "My Business Info",
    icon: Settings,
    type: "link",
    path: "/organization-setting",
    active: true,
    superadmin: true,
  },
];
