import * as React from "react";
import { Upload, message, Button, Modal } from "antd";
import styled from "styled-components";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { AddProfileImage } from "./AddProfileImage";
import { changeProfileImage } from "../../../Graphs/User/changeProfileImage";
import { connect } from "react-redux";

class ProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewFlag: false,
    };
  }

  viewModalRef;

  showModal = () => {
    this.setState(
      {
        isViewFlag: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  };

  uploadImage = async (name) => {
    try {
      const { authToken, setUserData } = this.props;

      if (name != null) {
        if (authToken) {
          console.log("!!!!!!!!!!!!!!after upload ", name);
          let updateData = await changeProfileImage(authToken, name);
          console.log("!!!!!!!!!!updateData", updateData);
          await setUserData(updateData.data);

          this.setState({
            isViewFlag: false,
          });
        }
      } else {
        this.setState({
          isViewFlag: false,
        });
      }
    } catch (e) {
      console.log("!!!!!!!error printed here", e);
    }
  };

  render() {
    const { isViewFlag } = this.state;

    return (
      <div className="profile-edit">
        <Link to="#" onClick={() => this.showModal()}>
          <Edit />
          {isViewFlag ? (
            <AddProfileImage
              refx={(e) => (this.viewModalRef = e)}
              onUpload={this.uploadImage}
            />
          ) : null}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginFlag: state.auth.loginFlag,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileComponent));
