import { callApiToServer } from "../callApi";

export async function assignCredit(authtoken, assignObject, programId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      amount: assignObject.amount,
      category_id: assignObject.category_id,
      worker_user_id: assignObject.worker_user_id,
      charity_program_id: programId,
    });

    let endUrl = "v1/auth/distribute-charity-amount";
    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
