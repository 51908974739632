import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import { AddCategoryIcon } from "./AddCategoryIcon";
import { PictureOutlined } from "@ant-design/icons";
import { WEBURL } from "../../constant/comman";
import { ColorModal } from "./ColorModal";

const { Content } = Layout;
const Option = Select.Option;

export class AddCategory extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      view: false,
      colorView: false,
      selectedType: "",
      background: "#ffffff",
      forground: "#ffffff",
      accent: "#ffffff",
      fieldName: "background",
      errors: {
        selectedType: "",
        uploadImage: "",
      },
    };
  }

  input;
  allTypes = [
    <Option value={"charity"}>Organization</Option>,
    <Option value={"vendor"}>Vendor</Option>,
  ];
  viewModalRef;
  colorModalRef;
  src = "";

  uploadImage = (name) => {
    console.log("!!!!!!!!!!!!!!after upload ", name);
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  showViewModal = (params) => {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  };

  changeFocus = (fieldName) => {
    console.log("!!!!!!changeFocus", fieldName);
    this.setState({ fieldName: fieldName });
    this.setState(
      {
        colorView: true,
      },
      () => {
        this.colorModalRef.show();
      }
    );
  };

  changeColor = (color) => {
    const { fieldName } = this.state;
    if (color) {
      if (fieldName === "background") {
        this.setState({ background: color });
      } else if (fieldName === "forground") {
        this.setState({ forground: color });
      } else {
        this.setState({ accent: color });
      }
    }

    this.setState({
      colorView: false,
    });
  };

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "name is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit = (values, action) => {
    const { selectedType, errors } = this.state;
    const dummyErrors = { ...errors };

    if (!selectedType) {
      dummyErrors.selectedType = "Please select Type";
      this.setState({
        errors: dummyErrors,
      });
      return;
    }

    dummyErrors.selectedType = "";
    if (this.src === "") {
      dummyErrors.uploadImage = "Please upload icon for category";
      this.setState({
        errors: dummyErrors,
      });
      return;
    }
    dummyErrors.uploadImage = "";

    if (!this.state.background) {
      dummyErrors.background = "background is required";
      this.setState({
        errors: dummyErrors,
      });
    }
    dummyErrors.background = "";

    if (!this.state.forground) {
      dummyErrors.forground = "forground is required";
      this.setState({
        errors: dummyErrors,
      });
    }
    dummyErrors.forground = "";

    if (!this.state.accent) {
      dummyErrors.accent = "accent is required";
      this.setState({
        errors: dummyErrors,
      });
    }
    dummyErrors.accent = "";

    values.icon_name = this.src;
    values.background = this.state.background;
    values.forground = this.state.forground;
    values.accent = this.state.accent;
    values.type = selectedType;
    console.log("!!!!!values", values);
    this.props.onSubmit(values);
    this.handleCancel();

    this.setState({
      visible: false,
    });
  };

  changeType = (value) => {
    this.setState({
      selectedType: value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
    this.src = "";
  };

  render() {
    const { primaryColor } = this.props;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          destroyOnClose={true}
          title="Add Category"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: "",
                type: undefined,
                background: "",
                forground: "",
                accent: "",
                icon_name: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Category Name
                        <Input
                          id="name"
                          placeholder="Category Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Type
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeType(value)}
                          placeholder="Select Type"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allTypes}
                        </Select>
                        {this.state.errors.selectedType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedType}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Background
                        <Input
                          id="background"
                          placeholder="Background"
                          value={values.background}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={this.state.background}
                          onClick={() => this.changeFocus("background")}
                        />
                        <div
                          style={{
                            background: this.state.background,
                            width: "20px",
                            height: "20px",
                            marginTop: "5px",
                            borderRadius: "5px",
                          }}
                        />
                        {this.state.errors.background ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.background}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Forground
                        <Input
                          id="forground"
                          placeholder="Forground"
                          value={values.forground}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={this.state.forground}
                          onClick={() => this.changeFocus("forground")}
                        />
                        <div
                          style={{
                            background: this.state.forground,
                            width: "20px",
                            height: "20px",
                            marginTop: "5px",
                            borderRadius: "5px",
                          }}
                        />
                        {this.state.errors.forground ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.forground}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Accent
                        <Input
                          id="accent"
                          placeholder="Accent"
                          value={values.accent}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={this.state.accent}
                          onClick={() => this.changeFocus("accent")}
                        />
                        <div
                          style={{
                            background: this.state.accent,
                            width: "20px",
                            height: "20px",
                            marginTop: "5px",
                            borderRadius: "5px",
                          }}
                        />
                        {this.state.errors.accent ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.accent}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      {this.state.colorView ? (
                        <ColorModal
                          refx={(e) => (this.colorModalRef = e)}
                          changeColor={this.changeColor}
                          selectedColor={this.state[this.state.fieldName]}
                        />
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ float: "left", width: "10%" }}>
                        <PictureOutlined
                          style={{ width: "100%", fontSize: "30px" }}
                        />
                      </div>
                      <Button
                        style={{ width: "90%" }}
                        onClick={this.showViewModal.bind(this)} //this.showViewModal.bind(this)
                      >
                        Choose Category Icon ( Upload svg format only )
                      </Button>
                      {this.src ? (
                        <p
                          style={{
                            fontSize: "small",
                            margin: "0",
                            marginLeft: "5px",
                          }}
                        >
                          {this.src}
                        </p>
                      ) : null}
                      {this.state.view ? (
                        <AddCategoryIcon
                          refx={(e) => (this.viewModalRef = e)}
                          onUpload={this.uploadImage}
                        />
                      ) : null}
                    </div>
                    <div width="100%" style={{ marginTop: "10px" }}>
                      {this.state.errors.uploadImage != "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.uploadImage}
                        </p>
                      ) : null}
                    </div>
                    <div
                      style={{
                        marhinTop: "20px",
                        width: "100px",
                        backgroundColor:
                          this.selectedColorType !== ""
                            ? this.selectedColorType
                            : "#ffffff",
                        borderRadius: "10px",
                      }}
                    >
                      {this.src && this.src !== "" ? (
                        <img
                          src={`${WEBURL}category/icon/${this.src}`}
                          alt="CategoryIcon"
                          style={{ width: "100px" }}
                        />
                      ) : null}
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
