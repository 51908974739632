import { Table, Tooltip, notification, Spin, Button } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllAssignedVoucherList } from "../../Graphs/NeedyFamily/listAssignedvoucherList";
import { withRouter } from "react-router";
import { connect } from "react-redux";
var moment = require("moment");

class VoucherListData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.listAssignedVoucherData(0, 5);
  }

  getLimitedAssignedVoucher = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listAssignedVoucherData(start, end);
      }
    );
  };

  listAssignedVoucherData = async (start, end) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });

      let voucherData = await getAllAssignedVoucherList(
        authToken,
        this.props.data.id,
        start,
        end
      );
      console.log("!!!!!!!!!!!!voucherData", voucherData);

      if (voucherData.status == 200) {
        this.setState({
          isLoading: false,
          data: voucherData.data,
          dataTotal: voucherData.total,
        });
      } else if (voucherData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw voucherData && voucherData.message
          ? voucherData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!list voucher Data printed here", e);
      notification["error"]({
        message: "CARD REDEEM",
        description: typeof e === "string" ? e : "Error",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedAssignedVoucher(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Voucher Name",
      key: "voucher_name",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                  <Tooltip placement="bottom" title="Voucher Name">
                    <div>{item.name ? item.name : "-"}</div>
                  </Tooltip>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Voucher Code",
      key: "redeem_code",
      dataIndex: "redeem_code",
      render: (text, item, index) => {
        return (
          <div>
            {item.redeem_code ? (
              <Tooltip placement="bottom" title="Redeem Code">
                {item.redeem_code}
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Redeemed Status",
      key: "paid",
      render: (text, item, index) => {
        console.log("user Redeemed record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Redeemed Status">
                  <div>
                    {item.is_redeemed
                      ? "Redeemed"
                      : item.is_assigned
                      ? "Assigned"
                      : item.is_paid
                      ? "UnAssigned"
                      : "Available"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    // {
    //   title: "Start Date",
    //   key: "start_date",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         <div
    //           style={{
    //             alignItems: "center",
    //             display: "inline-block",
    //             width: "100%",
    //           }}
    //         >
    //           <div flexDirection="column">
    //             <Tooltip placement="bottom" title="Start Date">
    //               <div>
    //                 {item.user_start_date
    //                   ? this.dateFormat(item.user_start_date)
    //                   : "-"}
    //               </div>
    //             </Tooltip>
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "End Date",
    //   key: "end_date",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         <div
    //           style={{
    //             alignItems: "center",
    //             display: "inline-block",
    //             width: "100%",
    //           }}
    //         >
    //           <div flexDirection="column">
    //             <Tooltip placement="bottom" title="End Date">
    //               <div>
    //                 {item.user_end_date
    //                   ? this.dateFormat(item.user_end_date)
    //                   : "-"}
    //               </div>
    //             </Tooltip>
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Assign Date",
      key: "assigned_on",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Assign Date">
                  <div>
                    {item.assigned_on ? this.dateFormat(item.assigned_on) : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Redeem Date",
      key: "redeem_date",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <Tooltip placement="bottom" title="Redeem Date">
                  <div>
                    {item.redeem_date ? this.dateFormat(item.redeem_date) : "-"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  generateCsv = async () => {
    try {
      const { authToken } = this.props;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getAllAssignedVoucherList(
            authToken,
            this.props.data.id,
            0,
            100000000000000000
          )
            .then((responseData) => {
              let csvArray = [];

              csvArray.push([
                "Voucher Name",
                "Voucher Code",
                "Redeemed Status",
                "Assigned Date",
                "Redeemed Date",
              ]);

              responseData.data.map((dataItem) => {
                csvArray.push([
                  dataItem.name ? dataItem.name : "NA",
                  dataItem.redeem_code ? dataItem.redeem_code : "NA",
                  dataItem.is_redeemed
                    ? "Redeemed"
                    : dataItem.is_assigned
                    ? "Assigned"
                    : dataItem.is_paid
                    ? "UnAssigned"
                    : "Available",
                  dataItem.assigned_on
                    ? `"${this.dateFormat(dataItem.assigned_on)}"`
                    : "-",
                  dataItem.redeem_date
                    ? `"${this.dateFormat(dataItem.redeem_date)}"`
                    : "-",
                ]);
              });

              const rows = csvArray;
              let csvName = "AllVouchersList.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((e) => {
              console.log("ERROR", e);
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "EXPORT DATA",
                description: typeof e === "string" ? e : "Something went wrong",
              });
              throw e;
            });
        }
      );
    } catch (e) {
      notification["error"]({
        message: "EXPORT DATA",
        description: typeof e === "string" ? e : "Something went wrong",
      });
      console.log("error in generateCsv function", e);
    }
  };

  render() {
    const { isLoading, data, dataTotal } = this.state;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
      </div>
    ) : (
      <>
        <div
          style={{
            marginTop: "0px",
            marginBottom: "20px",
            width: "100%",
            display: "inline-block",
          }}
        >
          <div style={{ float: "left", width: "50%" }}>
            <Button
              style={{
                marginTop: "0px",
                background: "#5C6AC4",
                color: "#fff",
                cursor: "pointer",
                marginLeft: "15px",
              }}
              onClick={() => {
                this.generateCsv();
              }}
            >
              Export
            </Button>
          </div>
        </div>
        <div style={{ "overflow-x": "auto" }}>
          <Table
            loading={this.state.isLoading}
            rowKey={(record) => record._id}
            columns={this.columns}
            // scroll={{ x: 1000 }}
            size={"small"}
            dataSource={data}
            pagination={{
              total: dataTotal,
              showSizeChanger: true,
              pageSize: this.state.pagination.pageSize,
              current: this.state.pagination.current,
              pageSizeOptions: ["1", "5", "10", "25", "50", "100"],
              onChange: (e) => {
                console.log("onChanges pagination");
                // e - 1
                //   ? (this.counter = this.tempCounter =
                //       (e - 1) * this.state.pagination.pageSize)
                //   : ((this.counter = 0), (this.tempCounter = 0));
                if (e - 1) {
                  this.counter = this.tempCounter =
                    (e - 1) * this.state.pagination.pageSize;
                  return;
                }

                this.counter = 0;
                this.tempCounter = 0;
              },
            }}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listVoucherData: state.voucher.listVoucherData,
  listVoucherDataTotal: state.voucher.listVoucherDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListVoucherData: (listVoucherData) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA",
        listVoucherData: listVoucherData,
      });
    },
    setListVoucherDataTotal: (listVoucherDataTotal) => {
      dispatch({
        type: "SET_LIST_VOUCHER_DATA_TOTAL",
        listVoucherDataTotal: listVoucherDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VoucherListData));
