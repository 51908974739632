import { callApiToServer } from "../callApi";

export async function paidAmount(authtoken, vendorObject, vendorId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      amount: vendorObject.amount,
      transaction_number: vendorObject.transaction_number,
      invoice_number: vendorObject.invoice_number,
      po_number: vendorObject.po_number,
      vendorId: vendorId,
    });

    let endUrl = "v1/auth/paid-vendor-amount";
    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
