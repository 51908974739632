import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCharity } from "../../Graphs/Charity/listCharity";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { deleteCharity } from "../../Graphs/Charity/deleteCharity";
import { changeStatus } from "../../Graphs/Charity/changeStatus";
import { editCharity } from "../../Graphs/Charity/editCharity";
import { searchCharity } from "../../Graphs/Charity/searchCharity";
import { EditModal } from "./EditModal";
import { ViewProgramModal } from "./ViewProgramModal";

var moment = require("moment");

class CharityRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCharity = this.getLimitedCharity.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      charitySelectedData: null,
    });
  }

  modalRef;
  programModalRef;

  async getLimitedCharity(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchCharityData(start, end);
        } else {
          //call normal user Data
          this.listCharityData(start, end);
        }
      }
    );
  }

  listSearchCharityData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCharityData,
        setListCharityDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let charityData = await searchCharity(authToken, searchText, start, end);

      if (charityData.status === 200) {
        //set user Data
        await setListCharityData(charityData.data);
        await setListCharityDataTotal(charityData.total);

        this.setState({
          isLoading: false,
          data: charityData.data,
        });
      } else if (charityData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search charity Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCharityData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCharityData,
        setListCharityDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let charityData = await getAllCharity(authToken, start, end);

      if (charityData.status === 200) {
        //set user Data
        await setListCharityData(charityData.data);
        await setListCharityDataTotal(charityData.total);

        this.setState({
          isLoading: false,
          data: charityData.data,
        });
      } else if (charityData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list vendor Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        charitySelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      charitySelectedData: null,
    });
  }

  showProgramModal = (item) => {
    this.setState(
      {
        userProgramData: item,
      },
      () => {
        this.programModalRef.show();
      }
    );
  };

  handleDeleteProgramModalclose() {
    this.setState({
      userProgramData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Organization Name",
      key: "OrganizationName",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Organization Name">
                <div>
                  {item.user_metadata ? item.user_metadata.entity_name : "-"}
                </div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "CorpPass ID",
      key: "CorpPass ID",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="CorpPass ID">
              <div>{item.corppass_id ? item.corppass_id : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Categories",
      key: "Categories",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Categories">
              <div>{item.categories ? item.categories : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "isActive",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="Charity Activation">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Edit">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Program">
                <Button
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showProgramModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{ float: "left", marginRight: "5px" }}
            >
              <Tooltip placement="bottom" title="Delete">
                <Popconfirm
                  onConfirm={this.deleteCharity.bind(this, item)}
                  title="Are you sure you want to delete organization?"
                >
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  async deleteCharity(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteCharity(authToken, item.id);

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete Charity",
          description: "Successfully deleted",
        });

        if (searchText != null && searchText !== "") {
          this.listSearchCharityData(startIndex, limitNumber);
          return;
        }
        this.listCharityData(startIndex, limitNumber);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Charity",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Change Charity Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchCharityData(startIndex, limitNumber);
          return;
        }
        this.listCharityData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Charity Status",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (charityObejct, charityId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editCharity(authToken, charityObejct, charityId);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Edit Charity Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchCharityData(startIndex, limitNumber);
          return;
        }
        this.listCharityData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Edit Charity",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCharity(pagination)
    );
  }

  render() {
    const { isLoading, data } = this.state;
    const { listCharityData, listCharityDataTotal, searchLoader } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listCharityData}
          pagination={{
            total: listCharityDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.charitySelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            charitySelectedData={this.state.charitySelectedData}
            onSubmit={this.editSubmit}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.userProgramData ? (
          <ViewProgramModal
            refx={(e) => (this.programModalRef = e)}
            onClose={this.handleDeleteProgramModalclose.bind(this)}
            userProgramData={this.state.userProgramData}
            authToken={this.props.authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCharityData: state.charity.listCharityData,
  listCharityDataTotal: state.charity.listCharityDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCharityData: (listCharityData) => {
      dispatch({
        type: "SET_LIST_CHARITY_DATA",
        listCharityData: listCharityData,
      });
    },
    setListCharityDataTotal: (listCharityDataTotal) => {
      dispatch({
        type: "SET_LIST_CHARITY_DATA_TOTAL",
        listCharityDataTotal: listCharityDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CharityRecordsTable));
