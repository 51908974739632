import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, notification } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";

const { Content } = Layout;
const Option = Select.Option;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      errors: {},
    };
  }

  input;

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.principal_name) {
      errors.principal_name = "Principal name is required";
    }

    if (!values.mobile_number) {
      errors.mobile_number = "Mobile number is required";
    }

    if (!values.email) {
      errors.email = "Email is required";
    }

    if (!values.race) {
      errors.race = "Race is required";
    }

    if (!values.sex) {
      errors.sex = "Sex is required";
    }

    if (!values.nationality) {
      errors.nationality = "Nationality is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit(values, action) {
    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                principal_name:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.principal_name,
                mobile_number:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.mobile_number,
                email:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.email,
                race:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.race,
                sex:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.sex,
                nationality:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.nationality,
                type_of_hdb:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.type_of_hdb,
                type_of_housing:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.type_of_housing,
                maritial_status:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.maritial_status,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Name
                        <Input
                          id="principal_name"
                          placeholder="Name"
                          value={values.principal_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.principal_name && touched.principal_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.principal_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Email
                        <Input
                          id="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Mobile Number
                        <Input
                          id="mobile_number"
                          placeholder="Mobile Number"
                          value={values.mobile_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.mobile_number && touched.mobile_number ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.mobile_number}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Nationality
                        <Input
                          id="nationality"
                          placeholder="Nationality"
                          value={values.nationality}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.nationality && touched.nationality ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.nationality}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Race
                        <Input
                          id="race"
                          placeholder="Race"
                          value={values.race}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.race && touched.race ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.race}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Sex
                        <Input
                          id="sex"
                          placeholder="Sex"
                          value={values.sex}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.sex && touched.sex ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.sex}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Type of HDB
                        <Input
                          id="type_of_hdb"
                          placeholder="Type of HDB"
                          value={values.type_of_hdb}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.type_of_hdb && touched.type_of_hdb ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.type_of_hdb}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Type of Housing
                        <Input
                          id="type_of_housing"
                          placeholder="Type of Housing"
                          value={values.type_of_housing}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.type_of_housing && touched.type_of_housing ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.type_of_housing}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Maritial Status
                        <Input
                          id="maritial_status"
                          placeholder="Maritial Status"
                          value={values.maritial_status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.maritial_status && touched.maritial_status ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.maritial_status}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
