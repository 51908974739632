import { callApiToServer } from "../callApi";

export async function removeAmount(authtoken, amountObject, programId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      amount: amountObject.amount,
      charity_program_id: programId,
    });

    let endUrl = "v1/auth/remove-assign-amount-program";
    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
