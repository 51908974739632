import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllSocialWorker } from "../../Graphs/SocialWorker/listSocialWorker";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  UserOutlined,
  EyeOutlined,
  DeleteOutlined,
  DollarCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { deleteSocialWorker } from "../../Graphs/SocialWorker/deleteSocialWorker";
import { searchSocialWorker } from "../../Graphs/SocialWorker/searchSocialWorker";
import { ViewNeedyFamilyModal } from "./ViewNeedyFamilyModal";
import { AvailableCreditModal } from "./AvailableCreditModal";
import { ViewVoucherModal } from "./ViewVoucherModal";
import { DeleteWorkerModal } from "./DeleteWorkerModal";
import { List } from "react-feather";
import { ViewVoucherTransactionModal } from "./ViewVoucherTransactionModal";
import { changeStatus } from "../../Graphs/User/ChangeStatus";
var moment = require("moment");

class UserRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      deleteSelectedData: null,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedSocialWorker = this.getLimitedSocialWorker.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      userSelectedData: null,
      deleteSelectedData: null,
    });
  }

  async getLimitedSocialWorker(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchSocialWorkerData(start, end);
        } else {
          //call normal user Data
          this.listSocialWorkerData(start, end);
        }
      }
    );
  }

  listSearchSocialWorkerData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListUserData,
        setListUserDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let userData = await searchSocialWorker(
        authToken,
        searchText,
        start,
        end
      );

      if (userData.status === 200) {
        //set user Data
        await setListUserData(userData.data);
        await setListUserDataTotal(userData.total);

        this.setState({
          isLoading: false,
          data: userData.data,
        });
      } else if (userData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listSocialWorkerData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListUserData,
        setListUserDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let userData = await getAllSocialWorker(authToken, start, end);

      if (userData.status === 200) {
        //set user Data
        await setListUserData(userData.data);
        await setListUserDataTotal(userData.total);

        this.setState({
          isLoading: false,
          data: userData.data,
        });
      } else if (userData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  modalRef;
  modalAvailableCreditRef;
  assignedModalRef;
  transactionModalRef;
  deleteModalRef;

  showModal = (item) => {
    this.setState(
      {
        userWorkerData: item,
      },
      () => {
        this.modalRef.show();
      }
    );
  };

  handleDeleteModalclose() {
    this.setState({
      userWorkerData: null,
    });
  }

  showAvailableCreditModal(item) {
    this.setState(
      {
        workerAvailableData: item,
        // isOpen: true
      },
      () => {
        this.modalAvailableCreditRef.show();
      }
    );
  }

  handleDetailsAvailableCreditModalclose() {
    this.setState({
      workerAvailableData: null,
    });
  }

  showAssignedModal(item) {
    this.setState(
      {
        userSelectedAssignedData: item,
        // isOpen: true
      },
      () => {
        this.assignedModalRef.show();
      }
    );
  }

  handleDetailsAssignedModalclose() {
    this.setState({
      userSelectedAssignedData: null,
    });
  }

  showTransactionModal(item) {
    this.setState(
      {
        userSelectedTransactionData: item,
        // isOpen: true
      },
      () => {
        this.transactionModalRef.show();
      }
    );
  }

  handleDetailsTransactionModalclose() {
    this.setState({
      userSelectedTransactionData: null,
    });
  }

  showDeleteModal(item) {
    this.setState(
      {
        deleteSelectedData: item,
      },
      () => {
        this.deleteModalRef.show();
      }
    );
  }

  handleDeleteModalDeleteclose() {
    this.setState({
      deleteSelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 80,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "NRIC/FIN",
      key: "SingpassId",
      width: 100,
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="NRIC/FIN">
                <div>{item.singpass_id ? item.singpass_id : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "Name",
      width: 140,
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Name">
                <div>
                  {item.user_metadata ? item.user_metadata.principal_name : "-"}
                </div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "Email",
      width: 160,
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Email">
              <div>
                {item.user_metadata
                  ? item.user_metadata.email
                    ? item.user_metadata.email
                    : "-"
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Mobile Number",
      key: "Mobile Number",
      width: 130,
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Mobile Number">
              <div>
                {item.user_metadata
                  ? item.user_metadata.mobile_number
                    ? item.user_metadata.mobile_number
                    : "-"
                  : "-"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Address",
      key: "Address",
      render: (text, item, index) => {
        let addressString = "";
        if (item.user_metadata && item.user_metadata.address) {
          let addressJson = JSON.parse(item.user_metadata.address);
          if (addressJson.floor && addressJson.floor.value) {
            addressString = addressString + addressJson.floor.value + ", ";
          }

          if (addressJson.block && addressJson.block.value) {
            addressString = addressString + addressJson.block.value + ", ";
          }

          if (addressJson.building && addressJson.building.value) {
            addressString = addressString + addressJson.building.value + ", ";
          }

          if (addressJson.street && addressJson.street.value) {
            addressString = addressString + addressJson.street.value + ", ";
          }

          if (addressJson.postal && addressJson.postal.value) {
            addressString = addressString + addressJson.postal.value + ", ";
          }

          if (addressJson.country && addressJson.country.value) {
            addressString = addressString + addressJson.country.desc;
          }
        }
        return (
          <div>
            <Tooltip placement="bottom" title="Address">
              <div>{addressString ? addressString : "-"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "isActive",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="Supervisor Activation">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="View Beneficiary">
                <Button
                  shape="circle"
                  icon={<UserOutlined />}
                  onClick={() => {
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Available Credits by Category">
                <Button
                  shape="circle"
                  icon={<DollarCircleOutlined />}
                  onClick={() => {
                    this.showAvailableCreditModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div mr="5px" style={{ float: "left", marginRight: "5px" }}>
              <Tooltip placement="bottom" title="Purchased e-Voucher Details">
                <Button
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showAssignedModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{ float: "left", marginRight: "5px", marginTop: "5px" }}
            >
              <Tooltip placement="bottom" title="Delete">
                <Popconfirm
                  onConfirm={() => this.showDeleteModal(item)}
                  title="Are you sure you want to delete this Supervisor?"
                >
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
            {/* <div
              mr="5px"
              style={{ float: "left", marginRight: "5px", marginTop: "5px" }}
            >
              <Tooltip placement="bottom" title="e-Voucher Transactions">
                <Button
                  shape="circle"
                  className="feather-svg"
                  icon={<List />}
                  onClick={() => {
                    this.showTransactionModal(item);
                  }}
                />
              </Tooltip>
            </div> */}
          </div>
        );
      },
    },
  ];

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Change Supervisor Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchSocialWorkerData(startIndex, limitNumber);
          return;
        }
        this.listSocialWorkerData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Supervisor Status",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  deleteSocialWorker = async (deleteWorkerObject, workerId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteSocialWorker(
        authToken,
        deleteWorkerObject,
        workerId
      );

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete User",
          description: "Successfully deleted",
        });

        if (searchText != null && searchText !== "") {
          this.listSearchSocialWorkerData(startIndex, limitNumber);
          return;
        }
        this.listSocialWorkerData(startIndex, limitNumber);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete User",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedSocialWorker(pagination)
    );
  }

  render() {
    const { isLoading, data, userWorkerData } = this.state;
    const { listUserData, listUserDataTotal, searchLoader, authToken } =
      this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listUserData}
          pagination={{
            total: listUserDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {userWorkerData ? (
          <ViewNeedyFamilyModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDeleteModalclose.bind(this)}
            userWorkerData={userWorkerData}
            authToken={authToken}
          />
        ) : null}
        {this.state.workerAvailableData ? (
          <AvailableCreditModal
            refx={(e) => (this.modalAvailableCreditRef = e)}
            onClose={this.handleDetailsAvailableCreditModalclose.bind(this)}
            workerAvailableData={this.state.workerAvailableData}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.userSelectedAssignedData ? (
          <ViewVoucherModal
            refx={(e) => (this.assignedModalRef = e)}
            onClose={this.handleDetailsAssignedModalclose.bind(this)}
            userSelectedAssignedData={this.state.userSelectedAssignedData}
            authToken={authToken}
          />
        ) : null}
        {this.state.deleteSelectedData ? (
          <DeleteWorkerModal
            refx={(e) => (this.deleteModalRef = e)}
            onClose={this.handleDeleteModalDeleteclose.bind(this)}
            deleteSelectedData={this.state.deleteSelectedData}
            authToken={authToken}
            onSubmit={this.deleteSocialWorker}
          />
        ) : null}
        {this.state.userSelectedTransactionData ? (
          <ViewVoucherTransactionModal
            refx={(e) => (this.transactionModalRef = e)}
            onClose={this.handleDetailsTransactionModalclose.bind(this)}
            userSelectedTransactionData={this.state.userSelectedTransactionData}
            authToken={authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listUserData: state.user.listUserData,
  listUserDataTotal: state.user.listUserDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserData: (listUserData) => {
      dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: listUserData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserRecordsTable));
