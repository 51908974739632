import { callApiToServer } from "../callApi";

export async function editLinkup(authtoken, linkupObject, linkupId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      linkupId: linkupId,
      singpass_id: linkupObject.singpass_id,
      corppass_id: linkupObject.corppass_id,
    });

    let endUrl = "v1/auth/linkup";

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
