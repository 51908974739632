import { callApiToServer } from "../callApi";

export async function deleteVendorLinkup(authtoken, linkupId) {
  try {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    const body = JSON.stringify({
      linkupId: linkupId,
    });
    const endUrl = "v1/auth/vendor-linkup/" + linkupId;
    const responseData = await callApiToServer(body, header, "DELETE", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
