import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, notification } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { getAllTypeActiveCategory } from "../../Graphs/Category/listAllTypeActiveCategory";

const { Content } = Layout;
const Option = Select.Option;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedCategory: [],
      errors: {
        selectedCategory: "",
      },
    };
  }

  input;
  allCategories = [];

  async componentDidMount() {
    try {
      let { categorie_ids } = this.props.data;
      let { authToken } = this.props;
      let categoryData = await getAllTypeActiveCategory(authToken, "charity");
      console.log("!!!!!!categoryData printed here", categoryData);

      categoryData &&
        categoryData.data &&
        categoryData.data.map((data, index) => {
          this.allCategories.push(<Option value={data.id}>{data.name}</Option>);
        });

      let selectedDummyType = [];
      categorie_ids &&
        categorie_ids.split(", ").map((data, index) => {
          selectedDummyType.push(data);
        });

      this.setState({
        selectedCategory: selectedDummyType,
        categoryData: categoryData.data,
      });
    } catch (e) {
      notification["error"]({
        message: "Error",
        description:
          typeof e === "string"
            ? e
            : typeof e.message
            ? e.message
            : "Please try again later",
      });
    }
  }

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.corppass_id) {
      errors.corppass_id = "Corppass Id is required";
    }

    if (!values.entity_name) {
      errors.entity_name = "Entity name is required";
    }

    if (!values.primary_activity) {
      errors.primary_activity = "Primary activity is required";
    }

    // if (!values.secondary_activity) {
    //   errors.secondary_activity = "Seconfary activity is required";
    // }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit(values, action) {
    const { selectedCategory, errors, categoryData } = this.state;
    const dummyErrors = { ...errors };

    if (!selectedCategory.length) {
      dummyErrors.selectedCategory = "Please select atleast one category";
      this.setState({
        errors: dummyErrors,
      });
      return;
    }

    let sendCategory = [];
    selectedCategory.map((data, index) => {
      let filterData = categoryData.filter((subData, subIndex) => {
        return data == subData.id;
      });
      if (filterData && filterData.length > 0) {
        sendCategory.push({
          id: data,
          name: filterData[0]["name"],
        });
      }
    });

    dummyErrors.selectedCategory = "";
    values.categories = sendCategory;

    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  }

  changeCategory = (value) => {
    this.setState({
      selectedCategory: value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                corppass_id: this.props.data.corppass_id,
                categories: this.props.data.categories.split(","),
                entity_name:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.entity_name,
                primary_activity:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.primary_activity,
                secondary_activity:
                  this.props.data.user_metadata &&
                  this.props.data.user_metadata.secondary_activity,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        CorpPass ID
                        <Input
                          id="corppass_id"
                          placeholder="CorpPass ID"
                          value={values.corppass_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.corppass_id && touched.corppass_id ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.corppass_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Category
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          value={this.state.selectedCategory}
                          onChange={(value) => this.changeCategory(value)}
                          placeholder="Select Categories"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allCategories}
                        </Select>
                        {this.state.errors.selectedCategory ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedCategory}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Entity Name
                        <Input
                          id="entity_name"
                          placeholder="Entity Name"
                          value={values.entity_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.entity_name && touched.entity_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.entity_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Primary Activity
                        <Input
                          id="primary_activity"
                          placeholder="Primary Activity"
                          value={values.primary_activity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.primary_activity && touched.primary_activity ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.primary_activity}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Secondary Activity
                        <Input
                          id="secondary_activity"
                          placeholder="Secondary Activity"
                          value={values.secondary_activity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.secondary_activity &&
                        touched.secondary_activity ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.secondary_activity}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
